import React from 'react';
import styles from './Academy.module.scss';

const Academy = () => {
  return (
    <div
      className={styles['academy-box']}
      style={{
        background: 'url(./assets/img/png/Retainer/background/squarebg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      data-testid="academy-section"
    >
      <div className={styles['academy-container']}>
        <div className={styles['academy-content__container']}>
          <p className={styles['academy-content__title']}>Academy</p>
          <div className={styles['academy-caption__container']}>
            <p className={styles['academy-caption__title']}>
              First step of many to come
            </p>
            <p className={styles['academy-caption__content']}>
              Constituents will be deployed into the controlled, prototype
              environment in order to hone their skills. Fight enemies and
              familiarize oneself with the prototype before plunging into the
              rift against mythic creatures.
            </p>
          </div>
        </div>
        <div className={styles['image-container']}>
          <img src="./assets/img/png/Retainer/monitor.png" alt="monitor-img" />
        </div>
      </div>
    </div>
  );
};

export default Academy;

import React from 'react';
import styles from './CardEventList.module.scss';
import { RepositoryIcon } from '@src/Components/Atoms/SVGComponents';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import dayjs from 'dayjs';
import moment from 'moment';
import { EventDumType } from '@src/Components/Atoms/Card/CardEventList/CardEventList.type';

interface CardEventListProps {
  data: EventDumType | null;
}

const CardEventList: React.FC<CardEventListProps> = ({ data }) => {
  const [eventYear, eventMonth] = dayjs(data?.dum_date)
    .format('YYYY MM')
    .split(' ');

  return (
    <div className={styles['container']} data-testid="card-event-list">
      <div className={styles['card-content']}>
        <div className={styles['header-content']}>
          <div className={styles['location-detail']}>
            {data?.location?.location_name}{' '}
            <span>
              / {data?.location?.longitude}, {data?.location?.latitude}
            </span>
          </div>
          <div className={styles['button-close']}></div>
        </div>
        <div className={styles['description-content']}>
          <div className={styles['mythic-logo']}>
            <RepositoryIcon
              color={data?.contain_mythic_event ? '#C4964D' : '#FFFFFF'}
            />
          </div>
          <div className={styles['text']}>{data?.event_name}</div>
        </div>
        <div className={styles['event-date']}>
          Event date: {moment(data?.dum_date).format('DD-MM-YYYY')}
        </div>
      </div>
      <div className={styles['card-footer']}>
        <div className={styles['button-wrapper']}>
          <ButtonDefault
            text="LEARN MORE"
            icon="./assets/img/svg/learn-more-icon.svg"
            onClick={() =>
              window.location.assign(
                `${process.env.MYTHIC_DUM_URL}?year=${eventYear}&month=${eventMonth}&dum_id=${data?.id}`
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CardEventList;

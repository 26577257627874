import React from 'react';
import styles from './FloatingMarkerDetail.module.scss';

type IFloatingMarkerDetail = {
  countryName?: string;
  eventCount?: number | string;
  onDetailClick?: () => void;
};

const FloatingMarkerDetail: React.FC<IFloatingMarkerDetail> = ({
  countryName = 'country',
  eventCount = 0,
  onDetailClick
}) => {
  const handleSumEvent = () => {
    if (Number(eventCount) > 1) return `(${eventCount} EVENTS DETECTED.)`;
    return `(${eventCount} EVENT DETECTED.)`;
  };

  return (
    <div className={styles['tooltip-country']}>
      <div className={styles['button_marker']}>
        <div className={styles['button']} onClick={onDetailClick}>
          See Details
        </div>
      </div>
      <div className={styles['marker_banner']}>{countryName}</div>
      <div className={styles['marker_detail']}>{handleSumEvent()}</div>
    </div>
  );
};

export default FloatingMarkerDetail;

export const metadata = {
  title: "Mythic Protocol Retainer Academy",
  urlSite: window.location.href + "retaineracademy",
  desc: "The Mythic Protocol have battled and contained threats from other worlds, now it needs brave souls to join the Retainer Academy.",
  ogType: "Website",
  ogTitle: "Mythic Protocol Retainer Academy",
  ogDesc:
    "The Mythic Protocol have battled and contained threats from other worlds, now it needs brave souls to join the Retainer Academy.",
  ogImg: window.location.href + "retaineracademy.png",
  ogUrl: window.location.href + "retaineracademy",
  ogSiteName: "Mythic Portal Retainer Academy",
  keywords: "retainer academy, mythic protocol, mythic retainer, mpra",
};

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip
} from 'chart.js';
import React from 'react';
import { Radar } from 'react-chartjs-2';
import styles from './RadarChart.module.scss';

const plugin = {
  id: 'background',
  beforeDraw: (chart: any) => {
    const { ctx } = chart;
    ctx.save();

    const numberOfSides = 5;
    const size = 96;
    const Xcenter = 140;
    const Ycenter = 158;
    const step = (2 * Math.PI) / numberOfSides;
    const shift = (Math.PI / 180.0) * -18;

    ctx.beginPath();

    for (let i = 0; i <= numberOfSides; i++) {
      const curStep = i * step + shift;
      ctx.lineTo(
        Xcenter + size * Math.cos(curStep),
        Ycenter + size * Math.sin(curStep)
      );
    }

    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle =
      window.innerWidth > 1279
        ? 'rgba(8, 35, 70, 0.5)'
        : 'rgba(8, 22, 42, 0.5)';
    ctx.fill();
    ctx.restore();
  }
};

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
ChartJS.register(plugin);

interface IRadarChart {
  width?: number;
  height?: number;
  radarChartData: {
    fieldOperations: number;
    collector: number;
    archivist: number;
    merchantry: number;
    seer: number;
  };
  isLocked?: boolean;
}

const RadarChart: React.FC<IRadarChart> = ({
  width,
  height,
  radarChartData,
  isLocked
}) => {
  const styleLocked = isLocked ? '-locked' : '';
  const roles = [
    'fieldOperations',
    'collector',
    'archivist',
    'merchantry',
    'seer'
  ];

  const dataRoles = roles.map((role) =>
    !isLocked ? radarChartData?.[role] : 1
  );

  const labels = roles.map((role) => {
    const value = !isLocked ? radarChartData?.[role] : 0;
    return `${capitalizeFirstLetter(role)} (${value})`;
  });

  function capitalizeFirstLetter(role: string) {
    return role.charAt(0).toUpperCase() + role.slice(1);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Point',
        data: dataRoles,
        fill: true,
        backgroundColor: !isLocked ? 'transparent' : '#0a1c35',
        boxShadow: '0px 0px 9px #24EAC9',
        borderColor: '#5ED1A8',
        pointBackgroundColor: '#5ED1A8',
        pointBorderColor: '#5ED1A8',
        pointStyle: !isLocked ? 'circle' : false,
        pointHoverBackgroundColor: 'rgb(255, 99, 132)',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
        borderWidth: 1
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    backgroundColor: 'rgb(8, 22, 42)',
    plugins: {
      tooltip: {
        enabled: false //label
      },
      legend: {
        display: false,
        position: 'bottom'
      }
    },
    chartArea: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    scales: {
      r: {
        min: 0,
        max: !isLocked ? Math.max(...Object.values(radarChartData || [])) : 10,
        angleLines: {
          color:
            window.innerWidth > 1279
              ? 'rgba(61, 79, 104, 1)'
              : 'rgba(8, 22, 42, 1)' // set the color of the angle lines
        },
        grid: {
          color:
            window.innerWidth > 1279
              ? 'rgba(61, 79, 104, 1)'
              : 'rgba(8, 22, 42, 1)'
        },
        ticks: {
          max: 5,
          stepSize: !isLocked ? 2 : 10,
          font: {
            size: 10
          },
          display: false
        }
      }
    }
  };

  return (
    <div
      style={{ width: width, height: height }}
      className={styles[`container${styleLocked}`]}
      data-testid="radar-chart-component"
    >
      <Radar data={data} options={options as any} />
    </div>
  );
};

export default RadarChart;

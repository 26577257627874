// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gFwO0wzxLhJRMcvPnNOY{min-height:768px;width:920px}.gFwO0wzxLhJRMcvPnNOY img{width:100%}@media screen and (min-width: 768px)and (max-width: 1279px){.gFwO0wzxLhJRMcvPnNOY{width:768px;min-height:628px}}@media screen and (max-width: 767px)and (min-width: 360px){.gFwO0wzxLhJRMcvPnNOY{min-height:350px;width:auto}}@media screen and (max-width: 359px){.gFwO0wzxLhJRMcvPnNOY{min-height:340px;width:auto}}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Doggy/DoggyComicCarousel/DoggyComicCarousel.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,WAAA,CACA,0BACE,UAAA,CAIJ,4DACE,sBACE,WAAA,CACA,gBAAA,CAAA,CAIJ,2DACE,sBACE,gBAAA,CACA,UAAA,CAAA,CAIJ,qCACE,sBACE,gBAAA,CACA,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Content": `gFwO0wzxLhJRMcvPnNOY`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W7r_7KbFdohpNUmT99sg{position:absolute;left:-30px;top:0px;border-radius:4px 0px 0px 4px;background:#181818;display:flex;width:26px;height:108px;justify-content:center;align-items:center;gap:10px;flex-shrink:0;writing-mode:vertical-rl;text-orientation:upright;white-space:nowrap;line-height:18px;color:var(--accent-gold-300);text-align:center;font-family:"Barlow",sans-serif;font-size:14px;font-weight:600;font-style:normal;line-height:18px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Media/TagMedia/TagMedia.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,UAAA,CACA,OAAA,CAEA,6BAAA,CACA,kBAAA,CACA,YAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,aAAA,CAEA,wBAAA,CACA,wBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,4BAAA,CACA,iBAAA,CACA,+BAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"media__tag": `W7r_7KbFdohpNUmT99sg`
};
export default ___CSS_LOADER_EXPORT___;

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import styles from './ClassUsage.module.scss';

import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type IClassUsage = {
  data: any;
};

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: any, _args: any, _options: any) => {
    const { ctx } = chart;
    ctx.save();

    const numberOfSides = 5;
    const size = 96;
    const Xcenter = 140;
    const Ycenter = 158;
    const step = (2 * Math.PI) / numberOfSides; //Precalculate step value
    const shift = (Math.PI / 180.0) * -18; //Quick fix ;)

    ctx.beginPath();

    for (let i = 0; i <= numberOfSides; i++) {
      const curStep = i * step + shift;
      ctx.lineTo(
        Xcenter + size * Math.cos(curStep),
        Ycenter + size * Math.sin(curStep)
      );
    }

    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.closePath();
    ctx.fillStyle = '#0c2342';
    ctx.fill();
    ctx.restore();
  }
};

const ClassUsage: React.FC<IClassUsage> = ({ data: chartData }) => {
  const chartLabel = chartData?.data.map((label: any) => label.name);
  const chartValue = chartData?.data.map((val: any) => val.value);
  const data = {
    labels: chartLabel,
    background: 'red',
    datasets: [
      {
        data: chartData ? chartValue : [0, 0, 0],
        backgroundColor: [
          'rgba(54, 198, 146, 0.1)',
          'rgba(24, 181, 189, 0.1)',
          'rgba(229, 92, 183, 0.1)'
        ],
        hoverBackgroundColor: [
          'rgba(54, 198, 146, 1)',
          'rgba(24, 181, 189, 1)',
          'rgba(229, 92, 183, 1)'
        ],
        borderWidth: 3,
        borderColor: [
          'rgba(54, 198, 146, 1)',
          'rgba(24, 181, 189, 1)',
          'rgba(229, 92, 183, 1)'
        ]
      }
    ]
  };
  // Calculate the total sum of data points
  const total = data.datasets[0].data.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  // Convert data points to percentages with one decimal place
  data.datasets[0].data = data.datasets[0].data.map((value: any) =>
    Number(((value / total) * 100).toFixed(1))
  );

  const options = {
    plugins: {
      legend: {
        display: false // Set display to false to hide the labels
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            const value =
              data.datasets[tooltipItem.datasetIndex].data[
                tooltipItem.dataIndex
              ];
            return `  ` + value + '%';
          }
        }
      },
      customCanvasBackgroundColor: {
        backgroundColor: 'lightGreen'
      }
    },
    responsive: true
  };

  const statisticColorText = (itemName: string, opacity?: number) => {
    if (itemName === 'Sniper') {
      return `rgba(54, 198, 146, ${opacity ?? 1})`;
    } else if (itemName === 'Assault') {
      return `rgba(24, 181, 189, ${opacity ?? 1})`;
    } else {
      return `rgba(229, 92, 183, ${opacity ?? 1})`;
    }
  };

  if (!chartData) {
    return (
      <div
        className={styles['class-usage-empty-container']}
        data-testid="class-usage-chart"
      >
        <p>No Data</p>
      </div>
    );
  }

  return total !== 0 ? (
    <div className={styles['wrapper']} data-testid="class-usage-chart">
      <div className={styles['class-usage-list']}>
        {data.labels.map((item: any, index: number) => (
          <div className={styles['container-legend-title']} key={Number(index)}>
            <div
              className={styles['box-title']}
              style={{
                border: `1px solid ${statisticColorText(item)}`,
                boxShadow: `0px 0px 8px ${statisticColorText(item, 0.25)}`
              }}
            />
            <div>
              <div className={styles['class-usage-title']}>{item}</div>
              <div style={{ color: statisticColorText(item) }}>
                {data.datasets[0].data[index]}%
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles['chart-box']}>
        <Pie data={data} options={options} plugins={[plugin]} />
      </div>
    </div>
  ) : (
    <div
      className={styles['class-usage-empty-container']}
      data-testid="class-usage-container"
    >
      <p>No Data</p>
    </div>
  );
};

export default ClassUsage;

import React, { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './OtherLayout.module.scss';

interface OtherLayoutProps {
  children?: ReactNode;
}

const OtherLayout: React.FC<OtherLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  const handleHeading = () => {
    if (
      pathname === '/terms-of-use' ||
      pathname === '/privacy-policy' ||
      pathname === '/terms-and-condition'
    )
      return null;

    return <h2>MYTHIC PORTAL</h2>;
  };

  return (
    <div className={styles['layout-container']} data-testid="other-layout">
      <div
        style={{
          backgroundImage: `url(./assets/img/png/background-home.webp)`,
          backgroundSize: 'cover'
        }}
        className={styles['bg-box']}
      />
      <img
        className={styles['bg-mp-logo']}
        src="./assets/img/svg/mp_logo.svg"
        alt="mp-logo"
      />
      <div className={styles['content']}>
        {handleHeading()}
        {children ?? <Outlet />}
      </div>
    </div>
  );
};

export default OtherLayout;

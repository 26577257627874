import React from 'react';

type IGeneralFunctionIcon = {
  color?: string;
  width?: string;
  height?: string;
};

export const GeneralFunctionIcon: React.FC<IGeneralFunctionIcon> = ({
  color = '#18B5BD',
  width = '10',
  height = '11'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00065 3.41602C5.11871 3.41602 5.21767 3.45595 5.29753 3.53581C5.37739 3.61567 5.41732 3.71463 5.41732 3.83268C5.41732 3.95074 5.37739 4.0497 5.29753 4.12956C5.21767 4.20942 5.11871 4.24935 5.00065 4.24935C4.8826 4.24935 4.78364 4.20942 4.70378 4.12956C4.62392 4.0497 4.58398 3.95074 4.58398 3.83268C4.58398 3.71463 4.62392 3.61567 4.70378 3.53581C4.78364 3.45595 4.8826 3.41602 5.00065 3.41602ZM4.58398 5.08268H5.41732V7.58268H4.58398V5.08268ZM5.00065 1.33268C4.42426 1.33268 3.8826 1.44206 3.37565 1.66081C2.86871 1.87956 2.42773 2.17643 2.05273 2.55143C1.67773 2.92643 1.38086 3.3674 1.16211 3.87435C0.943359 4.38129 0.833984 4.92296 0.833984 5.49935C0.833984 6.07574 0.943359 6.6174 1.16211 7.12435C1.38086 7.63129 1.67773 8.07227 2.05273 8.44727C2.42773 8.82227 2.86871 9.11914 3.37565 9.33789C3.8826 9.55664 4.42426 9.66602 5.00065 9.66602C5.57704 9.66602 6.11871 9.55664 6.62565 9.33789C7.1326 9.11914 7.57357 8.82227 7.94857 8.44727C8.32357 8.07227 8.62044 7.63129 8.83919 7.12435C9.05794 6.6174 9.16732 6.07574 9.16732 5.49935C9.16732 4.92296 9.05794 4.38129 8.83919 3.87435C8.62044 3.3674 8.32357 2.92643 7.94857 2.55143C7.57357 2.17643 7.1326 1.87956 6.62565 1.66081C6.11871 1.44206 5.57704 1.33268 5.00065 1.33268Z"
        fill={color}
      />
    </svg>
  );
};

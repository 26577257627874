import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './DoggyComicPagination.module.scss';
import DoggyComicArrowLeft from '@src/Components/Atoms/Doggy/DoggyComicArrowLeft/DoggyComicArrowLeft';
import DoggyComicArrowRight from '@src/Components/Atoms/Doggy/DoggyComicArrowRight/DoggyComicArrowRight';

const DoggyComicPagination: React.FC<{
  totalPage: number;
  activeIndex: number;
  onChangePage: (page: number) => void;
  isBottom?: boolean;
}> = ({ totalPage, activeIndex, onChangePage, isBottom }) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const comicRef = useRef<HTMLElement | null>(null);
  const dropdownRef = React.useRef<HTMLUListElement | null>(null);

  const containerPagination = Array.from({ length: totalPage }, (_v, k) => k);

  useEffect(() => {
    comicRef.current = document.getElementById('comic');
  }, []);

  useEffect(() => {
    if (dropdownRef.current && openDropdown && isBottom) {
      dropdownRef.current.style.top = 'auto';
      dropdownRef.current.style.bottom = '18px';
    }
  }, [openDropdown]);

  const getVisiblePagesOnDekstop = () => {
    // If total pages are less than or equal to 6, show all pages
    if (totalPage <= 6) {
      return containerPagination;
    }

    // If activeIndex is greater than 3, center around the active page
    if (activeIndex > 3 && activeIndex <= totalPage - 3) {
      return containerPagination.slice(activeIndex - 2, activeIndex + 3); // Center around activeIndex
    }

    // If activeIndex is at the start, show the first 6 pages
    if (activeIndex <= 3) {
      return containerPagination.slice(0, 6); // Show the first 6 pages
    }

    // If activeIndex is near the end, show the last 6 pages
    return containerPagination.slice(totalPage - 5, totalPage); // Show the last 6 pages
  };

  const visiblePagesOnDekstop = getVisiblePagesOnDekstop();

  const handleToTop = useCallback(() => {
    if (isBottom && comicRef.current) {
      comicRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comicRef, isBottom]);

  return (
    <div className={styles['Container']} data-testid="doggy-comic-pagination">
      <button
        className={styles['Arrow']}
        onClick={() => {
          if (activeIndex > 0) {
            handleToTop();
            onChangePage(activeIndex - 1);
          }
        }}
      >
        <DoggyComicArrowLeft />
      </button>

      <div className={styles['PagingContainer']}>
        {totalPage > 6 && activeIndex > 3 && (
          <button
            onClick={() => {
              handleToTop();
              onChangePage(0);
            }}
          >
            Cover
          </button>
        )}
        {visiblePagesOnDekstop.map((k) => (
          <button
            onClick={() => {
              handleToTop();
              onChangePage(k);
            }}
            className={activeIndex === k ? styles['active'] : ''}
            key={k}
          >
            {k === 0 ? 'Cover' : k}
          </button>
        ))}
      </div>

      {/* Only active on mobile */}
      <div className={styles['PagingDropdown']}>
        <button onClick={() => setOpenDropdown(!openDropdown)}>
          <span>{activeIndex === 0 ? 'Cover' : activeIndex}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <mask
              id="mask0_203_770"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="19"
              height="18"
            >
              <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_203_770)">
              <path
                d="M9.5 11.5312L5 7.0312L6.05 5.9812L9.5 9.4312L12.95 5.9812L14 7.0312L9.5 11.5312Z"
                fill="#E4E4E4"
              />
            </g>
          </svg>
        </button>
        <ul
          ref={dropdownRef}
          className={
            styles['Dropdown'] + ' ' + (openDropdown ? styles['active'] : '')
          }
        >
          {isBottom
            ? containerPagination
                .slice()
                .reverse()
                .map((k) => (
                  <li key={k}>
                    <button
                      className={activeIndex === k ? styles['active'] : ''}
                      onClick={() => {
                        handleToTop();
                        onChangePage(k);
                        setOpenDropdown(!openDropdown);
                      }}
                    >
                      <span>{k === 0 ? 'Cover' : k}</span>
                    </button>
                  </li>
                ))
            : containerPagination.map((k) => (
                <li key={k}>
                  <button
                    className={activeIndex === k ? styles['active'] : ''}
                    onClick={() => {
                      onChangePage(k);
                      setOpenDropdown(!openDropdown);
                    }}
                  >
                    <span>{k === 0 ? 'Cover' : k}</span>
                  </button>
                </li>
              ))}
        </ul>
      </div>

      <button
        className={styles['Arrow']}
        onClick={() => {
          if (activeIndex < totalPage - 1) {
            handleToTop();
            onChangePage(activeIndex + 1);
          }
        }}
      >
        <DoggyComicArrowRight />
      </button>
    </div>
  );
};

export default DoggyComicPagination;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xiTwypPwSyYG69OiwY_X{position:absolute;bottom:0;left:25px}.xiTwypPwSyYG69OiwY_X .Rfzq4pHhPUnGAA4fJktj{display:inline-block;width:7.5px;height:7.5px;border-radius:50%;background:#36c692}.xiTwypPwSyYG69OiwY_X .jrlbPNbP4BHaDDBbM_00{display:inline-block;width:7.5px;height:7.5px;border-radius:50%;background:var(--primary-white-100)}.GISSw6bySRSbIhek08uQ{position:absolute;bottom:0;left:37px}.GISSw6bySRSbIhek08uQ .Rfzq4pHhPUnGAA4fJktj{display:inline-block;width:11px;height:11px;border-radius:50%;background:#36c692}.GISSw6bySRSbIhek08uQ .jrlbPNbP4BHaDDBbM_00{display:inline-block;width:11px;height:11px;border-radius:50%;background:var(--primary-white-100)}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/AvatarItem/ActivityIndicator/ActivityIndicator.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,QAAA,CACA,SAAA,CAEA,4CACE,oBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CAGF,4CACE,oBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,mCAAA,CAIJ,sBACE,iBAAA,CACA,QAAA,CACA,SAAA,CAEA,4CACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAGF,4CACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-activity-indicator-not-user": `xiTwypPwSyYG69OiwY_X`,
	"online-status": `Rfzq4pHhPUnGAA4fJktj`,
	"offline-status": `jrlbPNbP4BHaDDBbM_00`,
	"container-activity-indicator": `GISSw6bySRSbIhek08uQ`
};
export default ___CSS_LOADER_EXPORT___;

import moment from 'moment';
import React, { useEffect } from 'react';

import NotFoundLayout from '../404Layout/NotFoundLayout';
import styles from './MaintenancePage.module.scss';
import useMaintenance from './useMaintenance';

const MaintenancePage = () => {
  const { estimatedDateActive, getMaintainStatus } = useMaintenance();

  useEffect(() => {
    getMaintainStatus();
  }, []);

  return (
    <NotFoundLayout>
      <section
        className={styles['notfound__container']}
        data-testid="maintenance-page"
      >
        <p className={styles['title']}>Maintenance underway</p>
        <div className={styles['desc-not-found']}>
          The website is undergoing a scheduled downtime for essential
          improvements. (Estimated downtime:{' '}
          {moment(estimatedDateActive).format('DD MMM YYYY HH:mm')})
          <span>Thank you for your understanding.</span>
        </div>
        <p className={styles['text-detail']}>More from us:</p>
        <div className={styles['button-wrapper']}>
          <button
            className={styles['button-back']}
            onClick={() =>
              window.location.replace(`${process.env.MYTHIC_PROTOCOL_URL}`)
            }
          >
            <p className={styles['button-text']}>Visit our Homepage</p>
          </button>
          <button
            className={styles['button-back']}
            onClick={() =>
              window.location.replace(`${process.env.MYTHIC_RIFTSTORM_URL}`)
            }
          >
            <p className={styles['button-text']}>Visit playriftstorm.com</p>
          </button>
        </div>
      </section>
    </NotFoundLayout>
  );
};

export default MaintenancePage;

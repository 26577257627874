import React from 'react';
import styles from './TaC.module.scss';

const TaC = () => {
  return (
    <div
      className={styles['main-wrapper']}
      style={{ backgroundImage: `url(assets/img/png/grid-background.png)` }}
    >
      <div className={styles['header']}></div>
      <div className={styles['container']}>
        <div className={styles['main-title']}>terms and conditions</div>
        <div className={styles['content-wrapper']}>
          <div className={styles['text']}>
            Terms and Conditions for Confiction Labs Portal
            <br />
            Effective Date: 6 August 2024
            <br />
          </div>
          <div className={styles['text']}>
            <strong>1. Introduction</strong>
            <br />
            Welcome to Confiction Labs (&quot;we, &quot; &quot;our, &quot;
            &quot;us &quot;). These Terms and Conditions (&quot;Terms &quot;)
            govern your access to and the associated activities on our portal
            located at{' '}
            <a href={`${process.env.REACT_APP_PORTAL_URL}`}>
              {' '}
              https://one.confiction.com/portal{' '}
            </a>{' '}
            (&quot;Portal &quot;). By creating an account, accessing or using
            the Portal, you agree to these Terms.
          </div>
          <div className={styles['text']}>
            PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT
            INFORMATION AND AFFECT YOUR LEGAL RIGHTS. BY USING THE PORTAL, YOU
            ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS AND OUR
            PRIVACY POLICY AT
            [https://one.confiction.com/portal/privacy-policy], INCORPORATED
            HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THE TERMS, YOU MUST NOT
            ACCESS OR USE THE PORTAL.
          </div>
          <div className={styles['text']}>
            <strong>2. Access and Eligibility</strong> <br />
            2.1 <strong>Access Requirements</strong>: Access to the Portal`s
            features, including the Assignment Dashboard, Operational Reviews,
            and the Threat Index, requires the completion of specific steps,
            including:
            <br />
            <ul>
              <li>Signing in to your Portal account</li>
              <li>Completing the Protocol Induction</li>
            </ul>
            2.2 <strong>Protocol Induction</strong>: The Protocol Induction is a
            mandatory assessment to ensure you understand the Portal’s protocols
            and guidelines and to help identify an organization suitable for
            your profile.
            <br />
            2.3 <strong>Account Information</strong>: You must provide accurate
            and complete information during the registration and verification
            processes.
          </div>
          <div className={styles['text']}>
            <strong>3. Features and Functions</strong> <br />
            3.1 <strong>Assignment Dashboard</strong>: Allows you to view,
            manage, and complete assignments to earn rewards and increase your
            Clearance Level.
            <br />
            3.2 <strong>Operational Review</strong>: Contains reviews and
            details of various operations you are involved in.
            <br />
            3.3 <strong>Threat Index</strong>: Provides information on the
            latest threats and updates relevant to your activities.
            <br />
            3.4 <strong>Gateway Channels</strong>: Provides access to specific
            features such as Riftstorm and XPSR-24.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>4. Proof of Humanity, Exposure, and Network</strong> <br />
            4.1 <strong>Proof of Humanity</strong>: Involves verifying your
            identity through quests to ensure you are a natural person and not
            an automated bot. This process also contributes to your Clearance
            Score.
            <br />
            4.2 <strong>Proof of Exposure</strong>: Allows you to share genuine
            experiences and achievements, contributing to your Clearance Score
            and recognition within the community.
            <br />
            4.3 <strong>Proof of Network</strong>: Demonstrates your connections
            and network influence. This includes inviting others to join the
            Portal and participating in referral programs.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>5. Scoring System</strong> <br />
            5.1 <strong>Clearance Score (CS)</strong>: Determines your Clearance
            Level and eligibility for exclusive opportunities. Earn CS by
            completing assignments and referring new users.
            <br />
            5.2 <strong>Experience Points (EXP)</strong>: Measures your progress
            and engagement within the Portal. Earn EXP by completing assignments
            and participating in activities.
            <br />
            5.3 <strong>Protocol Points (PP)</strong>: Used for protocol-related
            activities and initiatives. Earn PP through assignments and specific
            Portal activities.
            <br />
            5.4 <strong>Revocation of Scores</strong>: Confiction Labs reserves
            the right to revoke Clearance Scores, Experience Points, and
            Protocol Points if actions are deemed fallacious or in violation of
            our policies.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>6. Account Security and Usage</strong> <br />
            6.1 <strong>Account Responsibility</strong>: You are responsible for
            maintaining the confidentiality of your account credentials and for
            all activities conducted under your account.
            <br />
            6.2 <strong>Security Protocols</strong>: Follow all security
            protocols to protect your account and personal information. Report
            any unauthorized access immediately.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>7. Prohibited Use</strong> <br />
            You may not use, or cause or encourage others to use, the Portal for
            any illegal, harmful, fraudulent, infringing, or objectionable
            activities. Below is a non-exhaustive list of prohibited activities
            which you must not engage in:
            <ul>
              <li>
                in any way that violates any applicable national or
                international law or regulation;
              </li>
              <li>
                trick, defraud, mislead or provide any false, inaccurate or
                misleading information to us, any of our users or any other
                person;
              </li>
              <li>
                defame, abuse, extort, harass, stalk, threaten or otherwise
                violate or infringe the legal or other rights (such as, but not
                limited to, rights of privacy, publicity and intellectual
                property) of others;
              </li>
              <li>
                harvest or otherwise collect information from the Portal about
                others, including without limitation email addresses, without
                proper consent;
              </li>
              <li>
                use any &quot;deep-link&quot;, &quot;page-scrape&quot;,
                &quot;robot&quot;, &quot;spider&quot; or other automatic device,
                program, algorithm or methodology, or any similar or equivalent
                manual process, to access, acquire, copy or monitor any portion
                of the Portal or any content;
              </li>
              <li>
                attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Portal, the server on which the
                Portal are stored, or any server, computer, or database
                connected to the Portal;
              </li>
              <li>
                probe, scan or test the vulnerability of the Portal or any
                network connected to the Portal, or breach the security or
                authentication measures on the Portal or any network connected
                to the Portal;
              </li>
              <li>
                reverse look-up, trace or seek to trace any information on any
                other user of or visitor to the Portal, or its source, or
                exploit the Portal or any service or information made available
                or offered by or through the Portal;
              </li>
              <li>
                impose an unreasonable or disproportionately large load on our
                infrastructure, or detrimentally interfere with, intercept, or
                expropriate any system, data, or information;
              </li>
              <li>
                engage in any other conduct that restricts or inhibits anyone`s
                use or enjoyment of the Portal, or which, as determined by us,
                may harm or offend us or our users or expose them to liability;
              </li>
              <li>
                use the Portal in any manner that could disable, overburden,
                damage, or impair the Portal or interfere with any other party`s
                use of the Portal, including their ability to engage in real
                time activities through the Portal;
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any message or transmittal you send to us
                on or through the Portal or any service offered on or through
                the Portal;
              </li>
              <li>
                impersonate or attempt to impersonate us, our employee, another
                user, or any other person or entity;
              </li>
              <li>
                use the Portal for any purpose that is unlawful or prohibited by
                these Terms;
              </li>
              <li>
                solicit the performance of any illegal activity or other
                activity which infringes the rights of us or others;
              </li>
              <li>
                introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful;
              </li>
              <li>
                accessing or using the Portal to build a similar service or
                application, identify or solicit our users, or publish any
                performance or any benchmark test or analysis relating to the
                Portal;
              </li>
              <li>
                transmit, or procure the sending of, any advertising or
                promotional material, including any &quot;junk mail&quot;,
                &quot;chain letter,&quot; &quot;spam,&quot; or any other similar
                solicitation; or
              </li>
              <li>
                disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Portal.
              </li>
            </ul>
          </div>
          <div className={styles['text']}>
            <strong>8. Intellectual Property</strong> <br />
            8.1 <strong>Ownership</strong>: The Portal, including their “look
            and feel”, proprietary content, information and other materials, and
            all content and other materials contained therein, including,
            without limitation, our logo and all designs, text, graphics,
            pictures, data, software, sound files, other files, and the
            selection and arrangement thereof are the property of us or our
            affiliates, licensors, third party content providers or users, as
            applicable, and are protected by intellectual property laws. We and
            our affiliates, licensors, third party content providers and users,
            as applicable, reserve all rights in connection with the Portal and
            their content. All other third-party trademarks, registered
            trademarks, and product names mention on the Portal or contained in
            the content linked to or associated with the Portal are the property
            of their respective owners and may not be copied, limited or used,
            in whole or in part, without the permission of the applicable
            intellectual property rights holder.
            <br />
            8.2 <strong>Use Restrictions</strong>: You may not use, reproduce,
            or distribute any content from the Portal without prior written
            permission from us.
            <br />
            8.3 <strong>Intellectual Property Infringement</strong>: We respect
            the intellectual property of others. If you believe that your
            intellectual property rights are being infringed, please submit your
            claim via email to legal@confiction.com with the subject line:
            “Copyright infringement” and include in your claim a detailed
            description of the alleged infringement.
            <br />
            8.4 <strong>Licenses</strong>: When you post or submit any data,
            feedback, content, text, photographs, images, or other information
            to any part of the Portal or provide to us (referred to as “User
            Content”), you represent and warrant that you have the right, power,
            and authority to post that User Content and grant the licenses
            specified below. You further represent and warrant that by posting
            or providing such User Content you will not violate third-party
            rights of any kind, including without limitation, any intellectual
            property rights, rights of publicity, or privacy rights. including,
            without limitation, any intellectual property rights, rights of
            publicity, or privacy rights. To the extent your User Content may be
            copyrightable, you represent, warrant, and covenant that you are the
            owner of all the copyright rights to such User Content and that we
            may exercise the rights to your User Content granted under the Terms
            without any liability or obligation for any payment.
            <br />
            You retain all ownership rights in any User Content you post on the
            Portal. To the extent permitted by applicable law, you grant to us
            and our successors and affiliates a royalty-free, sub-licensable,
            transferable, perpetual, irrevocable, non-exclusive, worldwide
            license to use, reproduce, modify, publish, list information
            regarding, edit, translate, distribute, publicly perform, publicly
            display, and make derivative works of all such User Content and your
            name, voice, and/or likeness as contained in your User Content, in
            whole or in part, and in any form, media, or technology, whether now
            known or hereafter developed, for use in connection with the Portal
            and our and our successors`
            <br />
          </div>
          <div className={styles['text']}>
            <strong>9. Limitation of Liability</strong> <br />
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER CONFICTION LABS NOR
            ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING
            THE SITE WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
            CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES,
            LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL,
            SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST
            OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION
            WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
            PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
            CONFICTION LABS OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE
            POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
            HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL CONFICTION LABS’
            TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR
            FROM THE USE OF OR INABILITY TO USE THE SITE EXCEED ONE HUNDRED U.S.
            DOLLARS ($100).
            <br />
            THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
            FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN CONFICTION
            LABS AND YOU.
          </div>
          <div className={styles['text']}>
            <strong>10. Termination and Suspension</strong> <br />
            10.1 <strong>Termination Rights</strong>: We may terminate or
            suspend your access to the Portal at our discretion, including for
            violations of these Terms or applicable laws.
            <br />
            10.2 <strong>Effect of Termination</strong>: Upon termination, your
            access to the Portal and its features will cease immediately.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>11. Changes to Terms</strong> <br />
            11.1 <strong>Modifications</strong>: We may update these Terms at
            any time. Changes will be posted on the Portal, and your continued
            use constitutes acceptance of the revised Terms.
            <br />
          </div>
          <div className={styles['text']}>
            <strong>12. Governing Law</strong> <br />
            12.1 <strong>Jurisdiction</strong>: These Terms are governed by and
            construed in accordance with the laws of Republic of Singapore,
            without regard to conflict of laws principles.
            <br />
            12.2 <strong>Dispute Resolution</strong>: Any disputes arising from
            these Terms or your use of the Portal shall be subject to the
            exclusive jurisdiction of the courts located in Singapore
            International Arbitration Centre (SIAC).
            <br />
          </div>
          <div className={styles['text']}>
            <strong>13. Contact Information</strong> <br />
            For any questions or concerns regarding these Terms, please contact
            us at <a href="mailto:legal@confiction.com">legal@confiction.com</a>
            .
            <br />
          </div>
          <div className={styles['text']}>
            <strong>14. Miscellaneous</strong> <br />
            14.1 <strong>Severability</strong>: These Terms are governed by and
            construed in accordance with the laws of Republic of Singapore,
            without regard to conflict of laws principles.
            <br />
            14.2 <strong>Entire Agreement</strong>:These Terms constitute the
            entire agreement between you and Confiction Labs regarding your use
            of the Portal.
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaC;

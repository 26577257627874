import React from 'react';

import FrameModalDaily from '../FrameModalDaily';
import styles from './SuccessfulClaimed.module.scss';

interface ISuccessfulClaimed {
  isShow: boolean;
  streak: string;
  rewards: Array<{
    pointProviderId: number;
    point: number;
    pointName: string;
    pointTicker: string;
    totalReward?: number;
  }>;
  onClose: () => void;
}

const SuccessfulClaimed: React.FC<ISuccessfulClaimed> = ({
  isShow,
  streak,
  rewards = [],
  onClose
}) => {
  return (
    <FrameModalDaily
      isShow={isShow}
      onClose={onClose}
      paddingBody="32px"
      widthBody="600px"
    >
      <div className={styles['contents']}>
        <img
          src={`assets/icons/check_white_content.svg`}
          alt="check_white_content"
        />
        <p className={styles['title']}>Check-in successful</p>
        <section className={styles['streak']}>
          You obtain{' '}
          {rewards.map((item, index) => (
            <p key={Number(index)} className={styles[item.pointTicker]}>
              +{item.point} {item.pointTicker}
            </p>
          ))}{' '}
          bonus streak <p className={styles['streak-font']}>{streak}x</p>
        </section>
        <section className={styles['rewards']}>
          <div>Total Reward</div>
          <div>
            {rewards.map((item, index) => (
              <p key={Number(index)} className={styles[item.pointTicker]}>
                {item.pointTicker} {item.totalReward}
              </p>
            ))}
          </div>
        </section>
        <p>Check-in tomorrow to get more exciting rewards.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </FrameModalDaily>
  );
};

export default SuccessfulClaimed;

import React from 'react';

import styles from './FaqBox.module.scss';

interface IFaqBox {
  title: string;
  children: JSX.Element;
}

const FaqBox: React.FC<IFaqBox> = ({ title, children }) => {
  return (
    <div className={styles['faqbox-container']}>
      {/* <div className={styles['faqbox-clip__border']}></div> */}
      <div className={styles['faqbox-title__container']}>
        <p>{title}</p>
      </div>
      <div className={styles['faqbox-content__container']}>{children}</div>
    </div>
  );
};

export default FaqBox;

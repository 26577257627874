import BackIcon from '@src/Components/Atoms/Shape/BackIcon';
import AchievementSection from '@src/Components/Organisms/AchievementSection/AchievementSection';
import LevelResponsive from '@src/Components/Organisms/ResponsiveAchivementTab/LevelResponsive/LevelResponsive';
import ProfileResponsive from '@src/Components/Organisms/ResponsiveAchivementTab/ProfileResponsive/ProfileResponsive';
import RiftstormStatistics from '@src/Components/Organisms/RiftstormStatistics/RiftstormStatistics';
import {
  ActionProfileDataType,
  LevelProfileDataType
} from '@src/Domain/Profile/Profile.type';
import React, { useState } from 'react';
import styles from './ResponsiveProfile.module.scss';

interface IResponsiveProfile {
  levelProfileData: LevelProfileDataType;
  actionProfileData: ActionProfileDataType[];
  radarChartData: {
    fieldOperations: number;
    collector: number;
    archivist: number;
    merchantry: number;
    seer: number;
  };
  imgProfile: string;
  isLocked: boolean;
  playerData: any;
  accountId: string;
}

const ResponsiveProfile: React.FC<IResponsiveProfile> = ({
  levelProfileData,
  radarChartData,
  actionProfileData,
  imgProfile,
  isLocked,
  playerData,
  accountId
}) => {
  const [activedTab, setActivedTab] = useState<string>('profile');

  const handleChangeTab = (tabName: string) => {
    setActivedTab(tabName);
  };

  const handleActiveTab = (tabName: string) => {
    return {
      background: activedTab === tabName ? '#0C2342' : '#0A1C35',
      borderTop: activedTab === tabName ? '2px solid #CD1C6A' : 'none'
    };
  };

  return (
    <div className={styles['container-responsive-profile']}>
      <div className={styles['box-responsive-profile']}>
        <div className={styles['header-responsive-profile']}>
          <a href="/">
            <BackIcon />
          </a>
          <div className={styles['title-user-profile']}>User Profile</div>
          {/* <ShareIcon /> */}
        </div>
        <div className={styles['tab-responsive-profile']}>
          <div
            style={handleActiveTab('profile')}
            onClick={() => handleChangeTab('profile')}
            onKeyDown={() => {
              return;
            }}
          >
            Profile
          </div>
          <div
            style={handleActiveTab('level')}
            onClick={() => handleChangeTab('level')}
            onKeyDown={() => {
              return;
            }}
          >
            Level
          </div>
          <div
            style={handleActiveTab('achievement')}
            onClick={() => handleChangeTab('achievement')}
            onKeyDown={() => {
              return;
            }}
          >
            Achievement
          </div>
          <div
            style={handleActiveTab('statistic')}
            onClick={() => handleChangeTab('statistic')}
            onKeyDown={() => {
              return;
            }}
          >
            Statistic
          </div>
        </div>
        <div className={styles['content-tab-responsive-profile']}>
          {activedTab === 'profile' && (
            <ProfileResponsive
              imgProfile={imgProfile}
              playerData={playerData}
              actionProfileData={actionProfileData}
              isLocked={isLocked}
            />
          )}
          {activedTab === 'level' && (
            <LevelResponsive
              levelProfileData={levelProfileData}
              radarChartData={radarChartData}
              isLocked={isLocked}
            />
          )}
          {activedTab === 'achievement' && (
            <AchievementSection accountId={accountId} isProfile={true} />
          )}
          {activedTab === 'statistic' && (
            <RiftstormStatistics isLocked={isLocked} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveProfile;

import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import ReactLoading from 'react-loading';

import styles from './VideoMedia.module.scss';
import TagMedia from '../TagMedia/TagMedia';

interface IVideoMedia {
  isTag?: boolean;
  autoPlay?: boolean;
  onEnded?: () => void;
  mute?: boolean;
  volume?: number;
  url?: string;
  control?: boolean;
}

const VideoMedia: React.FC<IVideoMedia> = ({
  isTag = false,
  autoPlay = false,
  onEnded,
  mute,
  volume = 0.1,
  url = 'https://www.youtube.com/watch?v=q4gtkcOz0Jc',
  control = true
}) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isBuffering, setIsBuffering] = useState(false);

  const handleReady = () => {
    setIsReady(true);
  };

  return (
    <section
      className={styles['contents']}
      data-testid="video-media"
      style={{ opacity: isReady ? 1 : 0 }}
    >
      {isTag && <TagMedia />}
      {isBuffering && (
        <div className={styles['body-loading']}>
          <ReactLoading
            className="loading-center"
            type={'spin'}
            color={'#36aeae'}
            height={'20%'}
            width={'20%'}
          />
        </div>
      )}

      <ReactPlayer
        id="video"
        url={url}
        controls={control}
        className="react-player"
        width="100%"
        height="100%"
        onEnded={onEnded}
        playing={true}
        volume={volume}
        muted={mute}
        onReady={handleReady}
        onBuffer={() => setIsBuffering(true)}
        onBufferEnd={() => setIsBuffering(false)}
        playsinline={true}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              autoplay: autoPlay ? 1 : 0,
              controls: 0,
              modestbranding: 1,
              rel: 0,
              frameborder: 0,
              mute: mute ? 1 : 0
            }
          },
          file: {
            attributes: {
              crossOrigin: 'anonymous'
            }
          }
        }}
      />
    </section>
  );
};

export default VideoMedia;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oJaucDaeUpxqVANiuqrQ{display:flex;flex-direction:column;gap:24px;max-width:920px}.oJaucDaeUpxqVANiuqrQ .aeoXdTJtHbmI9Egda32t{width:920px;display:flex;height:1371px;justify-content:center;align-items:center;background-repeat:no-repeat;background-size:cover;background-position:center}.oJaucDaeUpxqVANiuqrQ .aeoXdTJtHbmI9Egda32t img{width:602.04px;height:51px}@media screen and (min-width: 768px)and (max-width: 1279px){.oJaucDaeUpxqVANiuqrQ{max-width:100%}.oJaucDaeUpxqVANiuqrQ .aeoXdTJtHbmI9Egda32t{width:768px;height:1108px;background-size:contain}}@media screen and (max-width: 767px){.oJaucDaeUpxqVANiuqrQ{max-width:100%}.oJaucDaeUpxqVANiuqrQ .aeoXdTJtHbmI9Egda32t{width:375px;height:572px}.oJaucDaeUpxqVANiuqrQ .aeoXdTJtHbmI9Egda32t img{width:243px;height:23px}}`, "",{"version":3,"sources":["webpack://./src/Components/Organisms/ComicReader/ComicReader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAEA,4CACE,WAAA,CACA,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,2BAAA,CACA,qBAAA,CACA,0BAAA,CAEA,gDACE,cAAA,CACA,WAAA,CAKN,4DACE,sBACE,cAAA,CACA,4CACE,WAAA,CACA,aAAA,CACA,uBAAA,CAAA,CAKN,qCACE,sBACE,cAAA,CAEA,4CACE,WAAA,CACA,YAAA,CACA,gDACE,WAAA,CACA,WAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `oJaucDaeUpxqVANiuqrQ`,
	"placeholder-container": `aeoXdTJtHbmI9Egda32t`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from './ActivityIndicator.module.scss';

const ActivityIndicator: React.FC<{ status: string; isNotUser: boolean }> = ({
  status,
  isNotUser
}) => {
  const handleActivityIndicator = (type: string) => {
    switch (type) {
      case 'online':
        return 'online';
      case 'away':
        return 'away';
      default:
        return 'offline';
    }
  };

  return (
    <div
      className={
        styles[
          !isNotUser
            ? 'container-activity-indicator'
            : 'container-activity-indicator-not-user'
        ]
      }
    >
      <span
        className={styles[`${handleActivityIndicator(status)}-status`]}
      ></span>
    </div>
  );
};

export default ActivityIndicator;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bvWtM5IB6evnhKIcfEPO{height:16px;margin-left:56px}.iDHhzAyFI9X895IzOjOP>nav>section:nth-child(3){padding-right:26px}.iDHhzAyFI9X895IzOjOP>nav>section:nth-child(3) img{cursor:pointer}.yvkjwQHRv_82CAp4sP4M{width:100%;box-sizing:border-box;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;row-gap:10px}.yvkjwQHRv_82CAp4sP4M .zRt_AOCeR4nscqpWtrr8{width:100%;height:40px}.iDHhzAyFI9X895IzOjOP nav{background-color:#0e0e1a !important}@media screen and (max-width: 1024px){.iDHhzAyFI9X895IzOjOP div>div>span{height:16px !important}}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CAGF,+CACE,kBAAA,CAEA,mDACE,cAAA,CAIJ,sBACE,UAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,kBAAA,CACA,YAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAKF,0BACE,mCAAA,CAGA,sCADF,mCAEI,sBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_img-back": `bvWtM5IB6evnhKIcfEPO`,
	"header_containers": `iDHhzAyFI9X895IzOjOP`,
	"bottom-content": `yvkjwQHRv_82CAp4sP4M`,
	"button-wrapper": `zRt_AOCeR4nscqpWtrr8`
};
export default ___CSS_LOADER_EXPORT___;

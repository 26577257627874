// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._k3JHDCp96lrVmsX9And{position:relative}._k3JHDCp96lrVmsX9And .q58RtijvlYVxpwJ86RpU{position:absolute;font-weight:400;font-size:27px;width:65px;height:58px;display:flex;justify-content:center;align-items:center;font-family:"MythicType"}._k3JHDCp96lrVmsX9And img{width:65px;height:auto}@media screen and (max-width: 767px){._k3JHDCp96lrVmsX9And{display:flex;justify-content:center;align-items:center}._k3JHDCp96lrVmsX9And .q58RtijvlYVxpwJ86RpU{font-size:16px}}@media screen and (min-width: 768px)and (max-width: 1659px){._k3JHDCp96lrVmsX9And{display:flex;justify-content:center;align-items:center}}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/LevelBadge/LevelBadge.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,iBAAA,CACA,eAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,0BACE,UAAA,CACA,WAAA,CAIJ,qCACE,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,4CACE,cAAA,CAAA,CAcN,4DACE,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-level-badge": `_k3JHDCp96lrVmsX9And`,
	"level-number": `q58RtijvlYVxpwJ86RpU`
};
export default ___CSS_LOADER_EXPORT___;

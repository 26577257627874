export const metadata = {
  title: 'Mythic Protocol - Compass Medallion',
  urlSite: window.location.href + 'compass-medallion',
  desc: 'The Mythic Protocol have battled and contained threats from other worlds, now it needs brave souls to join the Compass Medallion.',
  ogType: 'Website',
  ogTitle: 'Mythic Protocol Compass Medallion',
  ogDesc:
    'The Mythic Protocol have battled and contained threats from other worlds, now it needs brave souls to join the Compass Medallion.',
  ogImg: window.location.href + 'compass-medallion.png',
  ogUrl: window.location.href + 'compass-medallion',
  ogSiteName: 'Mythic Portal Compass Medallion ',
  keywords: 'Compass Medallion, mythic protocol, mythic retainer, mpra'
};

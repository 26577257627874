/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { makeAutoObservable, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { IdempotencyObjectProps } from './IdempotencyKey.type';

export class IdempotencyKeyStore {
  _idempotencyObject: Array<IdempotencyObjectProps> = [];
  _idempotencyKey: string | undefined = '';
  public errMsg = '';

  constructor() {
    makeAutoObservable(this);
  }

  /**
   *
   * @param id is the Page Name
   */
  public async generateIdempotencyObject(id: string) {
    const hasParentId = toJS(this._idempotencyObject).find(
      (idem) => idem.id === id
    );

    try {
      if (!hasParentId) {
        const idemObject = { id: id, idempotencyKey: uuidv4() };
        this._idempotencyObject.push(idemObject);
        return idemObject;
      }
    } catch (error: unknown) {
      const errorRes = error as { [x: string]: string };
      this.errMsg = errorRes.toString();
    }
  }

  /**
   *
   * @param id is the Page Name
   */
  public async getIdempotencyKeyById(id: string) {
    this._idempotencyKey = toJS(this._idempotencyObject)?.find(
      (idem: IdempotencyObjectProps) => idem.id === id
    )?.idempotencyKey;

    return this._idempotencyKey;
  }
}

export const IdempotencyKeyStoreDefault: IdempotencyKeyStore = {
  _idempotencyObject: [],
  _idempotencyKey: undefined,
  errMsg: '',
  generateIdempotencyObject: function (
    id: string
  ): Promise<{ id: string; idempotencyKey: string } | undefined> {
    throw new Error(`Function not implemented. (code: ${id})`);
  },

  getIdempotencyKeyById: function (id: string): Promise<string | undefined> {
    throw new Error(`Function not implemented. (code: ${id})`);
  }
};

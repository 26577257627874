export const imageAsset = [
  {
    src: './assets/img/png/Retainer/enroll/banner3.png',
    alt: 'weapons-img-3',
    class: 'mythic-truth__asset-combine',
    x: 0.05,
    y: 0.06
  },
  {
    src: './assets/img/png/Retainer/enroll/banner2.png',
    alt: 'weapons-img-2',
    class: 'mythic-truth__asset-combine',
    x: 0.06,
    y: 0.07
  },
  {
    src: './assets/img/png/Retainer/enroll/banner1.png',
    alt: 'weapons-img-1',
    class: '',
    x: 0.07,
    y: 0.08
  }
];

import React from 'react';
import styles from './PopUpClaimed.module.scss';

interface IPopUpClaimed {
  isShow: boolean;
  message: string;
}

const PopUpClaimed: React.FC<IPopUpClaimed> = ({ isShow, message }) => {
  return (
    <div className={`${styles['popup']} ${styles[isShow ? 'show' : 'hide']}`}>
      <div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default PopUpClaimed;

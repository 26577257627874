import React, { useState } from 'react';
import styles from './BarKeystone.module.scss';
import { motion } from 'framer-motion';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

type IBarKeystone = {
  onClick?: () => void;
  totalKillQuard: number;
  totalNotKillQuard: number;
};

const BarKeystone: React.FC<IBarKeystone> = ({
  onClick,
  totalKillQuard = 0,
  totalNotKillQuard = 0
}) => {
  const { width } = useWindowDimensions();
  const [isHover, setIsHover] = useState<boolean>(false);

  const totalPercentage =
    (totalNotKillQuard / (totalKillQuard + totalNotKillQuard)) * 100;

  const progressBarBigDesktop =
    -614 + (0 - -614) * parseFloat((totalPercentage / 100).toFixed(1));
  const progressBarDesktop =
    -384 + (0 - -384) * parseFloat((totalPercentage / 100).toFixed(1));
  const progressBarSmallDekstop =
    -386 + (0 - -386) * parseFloat((totalPercentage / 100).toFixed(1));
  const progressBarTablet =
    -192 + (0 - -192) * parseFloat((totalPercentage / 100).toFixed(1));

  return (
    <motion.div
      className={styles['container']}
      onMouseEnter={() => {
        if (width >= 1280) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <div className={styles['top-content']}>
        <p className={styles['title']}>
          Keystone Outcome: <br /> The Path of Mercy
        </p>
        <div className={styles['icon-wrapper']}>
          <img src="./assets/img/svg/expand-arrow.svg" alt="icon" />
        </div>
      </div>
      <div className={styles['bottom-content']}>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: isHover ? '38px' : '18px' }}
          transition={{ duration: 0.5 }}
          className={styles['bar-wrapper']}
          style={{ transformOrigin: 'bottom' }}
        >
          <div className={styles['progress-bars']}>
            <div
              style={{
                backgroundPosition: `${
                  width >= 1920
                    ? progressBarBigDesktop
                    : width >= 1679 && width < 1920
                    ? progressBarDesktop
                    : width >= 768
                    ? progressBarSmallDekstop
                    : progressBarTablet
                }px 0`
              }}
            >
              {isHover && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isHover ? 1 : 0 }}
                  transition={{ duration: 0.5, delay: isHover ? 0.3 : 0 }}
                  className={`${isHover && styles['show']}`}
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div
                    className={styles['not-save-grey6-lable']}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: 'auto',
                      paddingLeft: '5px'
                    }}
                  >
                    <p className={styles['title']}>
                      {(
                        (totalNotKillQuard /
                          (totalKillQuard + totalNotKillQuard)) *
                        100
                      ).toFixed(1)}
                      %
                    </p>
                    <p className={styles['description']}>Did not save Grey 6</p>
                  </div>
                  <div
                    className={styles['save-grey6-lable']}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      width: 'auto',
                      paddingRight: '5px'
                    }}
                  >
                    <p className={styles['title']}>
                      {(
                        (totalKillQuard /
                          (totalKillQuard + totalNotKillQuard)) *
                        100
                      ).toFixed(1)}
                      %
                    </p>
                    <p className={styles['description']}>Saved Grey 6</p>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default BarKeystone;

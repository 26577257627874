import React from 'react';

import { logEvent } from '@src/Domain/analytic';
import styles from './SocialButtons.module.scss';

interface ISocialButtons {
  icon: JSX.Element;
  caption: string;
  link: string;
}

const SocialButtons: React.FC<ISocialButtons> = ({ icon, caption, link }) => {
  const onHandleClick = (url: any) => {
    logEvent('user', `CTA-${caption}`);
    window.open(url);
  };

  return (
    <div className={styles['social-buttons__container']}>
      <div
        className={styles['social-buttons__content-container']}
        onClick={() => onHandleClick(link)}
        onKeyDown={() => {
          return;
        }}
      >
        <div>{icon}</div>
        <div className={styles['caption-container']}>{caption}</div>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2502 5.35693L15.7818 7.8885L10.4004 13.2699L11.4874 14.3569L16.8688 8.97554L19.4004 11.5071V5.35693H13.2502Z"
              fill="#F0A737"
            />
            <path
              d="M17.8448 17.8014H6.95595V6.91249H12.4004L10.8448 5.35693H6.95595C6.09806 5.35693 5.40039 6.0546 5.40039 6.91249V17.8014C5.40039 18.6593 6.09806 19.3569 6.95595 19.3569H17.8448C18.7027 19.3569 19.4004 18.6593 19.4004 17.8014V13.9125L17.8448 12.3569V17.8014Z"
              fill="#F0A737"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SocialButtons;

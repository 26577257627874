import React from 'react';
import styles from './FrameModalDaily.module.scss';

interface IFrameModalDaily {
  isShow: boolean;
  widthBody?: string;
  paddingBody?: string;
  backgroundBody?: string;
  onClose: () => void;
  children: React.ReactNode;
}
const FrameModalDaily: React.FC<IFrameModalDaily> = ({
  isShow,
  widthBody,
  paddingBody,
  backgroundBody,
  onClose,
  children
}) => {
  return (
    <div
      className={`${styles['container']} ${styles[isShow ? 'open' : 'close']}`}
    >
      <button className={styles['backdrop']} onClick={onClose} />
      <section
        className={`${styles['wrapper']} ${styles[isShow ? 'open' : 'close']}`}
      >
        <div
          className={styles['body']}
          style={
            {
              '--width-body': widthBody,
              '--padding-body': paddingBody,
              '--bg-body': backgroundBody
            } as React.CSSProperties
          }
        >
          {children}
        </div>
        <button className={styles['btn-close']} onClick={onClose}>
          <img src={`assets/icons/close.svg`} alt="close" />
        </button>
      </section>
    </div>
  );
};

export default FrameModalDaily;

import React from 'react';

const DoggyComicArrowRight = () => {
  return (
    <svg
      width="60"
      height="58"
      viewBox="0 0 60 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Square">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.1913 29.0104L35.5996 6.17752L30.7707 10.8511L49.5336 29.0105L30.7708 47.1698L35.5996 51.8433L59.1913 29.0104Z"
          fill="#C4964D"
        />
        <rect
          id="Rectangle 8076"
          width="13.44"
          height="13.44"
          transform="matrix(0.718568 0.695456 -0.718568 0.695456 30.2183 19.6636)"
          fill="#C4964D"
        />
      </g>
    </svg>
  );
};

export default DoggyComicArrowRight;

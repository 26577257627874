import TrashIcon from '@src/Components/Atoms/Shape/TrashIcon';
import SidebarItemNotif from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotif';
import { ArrowIcon } from '@src/Components/Atoms/SidebarItemNotif/SidebarItemNotifIcon';
import { withStore } from '@src/Store/Store.context';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactLoading from 'react-loading';
import { handleIconOpenNotification } from './Notification.constant';
import styles from './Notification.module.scss';
import { useWindowDimensions } from './useNotificationRedesign';
import { checkHtml } from '@src/Common/Constants/checkHtmlData';

type INotificationRedesign = {
  show: boolean;
  onClose: () => void;
  modalTitle?: string;
  typeIcon: 'notification' | 'mail';
  data: any[];
  unreadLength: number;
  readLength: number;
  updateStatus: () => void;
  getStatusNotice: (id: number, type: number) => boolean;
  markAsRead: (id: number) => void;
  updateArrayRemovedIdNotification: (id: number) => void;
};

const NotificationRedesign: React.FC<INotificationRedesign> = observer(
  ({
    show = false,
    onClose,
    modalTitle = 'Notice',
    typeIcon,
    data,
    updateStatus,
    getStatusNotice,
    markAsRead,
    updateArrayRemovedIdNotification
  }) => {
    const {
      MessageStore,
      showDetail,
      setShowDetail,
      setDetailData,
      detailData,
      sidebarActive,
      handleItemNotif,
      handleDeleteNotice,
      unreadDataNotice,
      filterOpenedDataNotice,
      HandleEmptyData,
      orderedData
    } = useWindowDimensions({
      markAsRead,
      updateStatus,
      updateArrayRemovedIdNotification,
      data
    });

    const ComponentDetailData = () => {
      if (detailData && data?.length > 0)
        return (
          <>
            <div className={styles['header-right-content']}>
              <div
                className={styles['header-title-wrapper']}
                onClick={() => setShowDetail(false)}
              >
                <div className={styles['arrow-wrapper']}>
                  <ArrowIcon />
                </div>
                <p className={styles['header-text']}>{detailData.title}</p>
              </div>
              <div
                className={styles['delete-icon-wrapper']}
                onClick={() => {
                  updateArrayRemovedIdNotification(detailData.id);
                  setDetailData(undefined);
                  setShowDetail(false);
                }}
              >
                <TrashIcon />
              </div>
            </div>
            {detailData.bannerUrl && (
              <div className={styles['image-wrapper']}>
                <img src={detailData.bannerUrl} alt="banner" />
              </div>
            )}

            <div className={styles['paragraph-right-content']}>
              <div
                className={styles['notice-content']}
                dangerouslySetInnerHTML={{
                  __html: checkHtml(detailData.content)
                }}
              />
            </div>
          </>
        );

      return (
        <div className={styles['no-notice']}>
          <div className={styles['icon-no-notice']}>
            {handleIconOpenNotification(typeIcon)}
          </div>
          {HandleEmptyData}
        </div>
      );
    };

    const componentNotif = () => {
      if (unreadDataNotice.length !== 0 || filterOpenedDataNotice.length !== 0)
        return (
          <>
            <div className={styles['unread-wrapper']}>
              <div className={styles['unread-header']}>
                <SidebarItemNotif
                  type="subsidebar"
                  text="Unread"
                  numberOfItem={unreadDataNotice.length}
                  typeIcon={typeIcon}
                />
              </div>
              <div className={styles['unread-contents']}>
                {unreadDataNotice.map((el: { id: number; title: string }) => {
                  if (getStatusNotice(el.id, 1)) {
                    return (
                      <SidebarItemNotif
                        typeIcon={typeIcon}
                        text={el.title}
                        key={el.id}
                        alert={true}
                        onClick={() => {
                          handleItemNotif(el);
                        }}
                        isActive={sidebarActive === el.id}
                        withBtnDelete={true}
                        onDelete={() => handleDeleteNotice(el.id)}
                      />
                    );
                  }
                })}
              </div>
            </div>
            <div className={styles['read-wrapper']}>
              <div className={styles['read-header']}>
                <SidebarItemNotif
                  type="subsidebar"
                  text="Read"
                  numberOfItem={filterOpenedDataNotice?.length}
                  typeIcon={typeIcon}
                />
              </div>
              <div className={styles['read-contents']}>
                {orderedData.map((el: { id: number; title: string }) => {
                  return (
                    <SidebarItemNotif
                      typeIcon={typeIcon}
                      text={el.title}
                      key={el.id}
                      onClick={() => handleItemNotif(el)}
                      isActive={sidebarActive === el.id}
                      withBtnDelete={true}
                      onDelete={() => handleDeleteNotice(el.id)}
                    />
                  );
                })}
              </div>
            </div>
          </>
        );

      return (
        <div className={styles['no-notice']}>There are no announcement.</div>
      );
    };

    const componentView = () => {
      if (MessageStore.isLoading === false)
        return (
          <div className={styles['content-wrapper']}>
            <div
              className={`${styles['left-content-wrapper']} ${
                showDetail && styles['hide-in-mobile']
              }`}
            >
              <div className={`${styles['left-content']} `}>
                {componentNotif()}
              </div>
            </div>

            <div
              className={`${styles['right-content']} ${
                showDetail && styles['show-in-mobile']
              } ${data.length < 1 && styles['no-data']}`}
            >
              <ComponentDetailData />
            </div>
          </div>
        );

      return (
        <div className={styles['loading-indicator']}>
          <ReactLoading
            type={'spin'}
            color={'#c4964d'}
            height={50}
            width={50}
          />
        </div>
      );
    };

    return (
      <>
        {show && (
          <div className={styles['container']} data-testid="notification">
            <div className={styles['dropshadow']} />
            <div className={styles['wrapper']}>
              <div className={styles['header']}>
                <p className={styles['header-text']}>{modalTitle}</p>
                <div className={styles['close-icon']} onClick={onClose}>
                  &#x2715;
                </div>
              </div>
              {componentView()}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default withStore(NotificationRedesign);

import React from 'react';
import styles from './ButtonDefault.module.scss';

type IButtonDefault = {
  text?: string | React.ReactNode;
  size?: 'large' | 'medium';
  disabled?: boolean;
  onClick?: () => void;
  noPadding?: boolean;
  wrapperStyle?: React.CSSProperties;
  captionStyle?: React.CSSProperties;
};

const ButtonDefaultOld: React.FC<IButtonDefault> = ({
  text,
  size = 'large',
  disabled = false,
  onClick,
  noPadding = false,
  wrapperStyle,
  captionStyle
}) => {
  return (
    <div
      className={
        styles['button-wrapper'] +
        ' ' +
        styles[size] +
        ' ' +
        (disabled ? styles['disabled'] : '')
      }
      onClick={onClick}
    >
      <div
        style={wrapperStyle}
        className={`${styles['text-wrapper']} ${
          noPadding ? styles['text-wrapper-nopadding'] : ''
        }`}
      >
        <div style={captionStyle} className={styles['button-text']}>
          {text ? text : 'SUBMIT ENTRIES'}
        </div>
      </div>
    </div>
  );
};

export default ButtonDefaultOld;

import React from 'react';
import styles from './ModalGrantedInduction.module.scss';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';

type IModalGrantedInduction = {
  onClose: () => void;
  show: boolean;
};

const ModalGrantedInduction: React.FC<IModalGrantedInduction> = ({
  show = false,
  onClose
}) => {
  return (
    <>
      {show && (
        <div className={styles['container']}>
          <div className={styles['back-drop']} onClick={onClose} />
          <div className={styles['content-wrapper']}>
            <div className={styles['image-wrapper']}>
              <img src="assets/img/svg/folder-icon.svg" alt="folder-icon" />
            </div>
            <div className={styles['title']}>ACCESS GRANTED</div>
            <div className={styles['description']}>
              Thank you for completing the induction hub.
            </div>
            <div className={styles['button-wrapper']}>
              <div className={styles['button']}>
                <ButtonDefault onClick={onClose} text="Close" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalGrantedInduction;

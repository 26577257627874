import MetaHelmet from '@src/Components/Atoms/Helmet/MetaHelmet';
import { initGA, logPageView } from '@src/Domain/analytic';
import React, { Fragment, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { metadata } from './Retainer.constant';
import styles from './Retainer.module.scss';
import {
  Academy,
  Enroll,
  Faq,
  MainBanner,
  SystemRequirement
} from './contents';

const Retainer = () => {
  const faqRef = useRef<HTMLDivElement>(null);
  const enrollRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    localStorage.removeItem('sign');
    localStorage.removeItem('code');
    if (!(window as unknown as { [x: string]: string }).GA_INITIALIZED) {
      initGA();
      (window as unknown as { [x: string]: string | boolean }).GA_INITIALIZED =
        true;
    }
    logPageView();

    if (
      window.location.href.slice(window.location.href.indexOf('#') + 1) ===
        'faq' &&
      faqRef.current
    ) {
      faqRef.current.scrollIntoView();
    }
    if (searchParams.get('code')) {
      scrollToEnroll();
    }
  }, [searchParams]);

  const scrollToEnroll = () => {
    if (enrollRef.current) {
      enrollRef.current.scrollIntoView();
    }
  };

  const scrollToFaq = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView();
    }
  };

  return (
    <Fragment>
      <MetaHelmet
        title={metadata.title}
        urlSite={metadata.urlSite}
        desc={metadata.desc}
        ogType={metadata.ogType}
        ogDesc={metadata.ogDesc}
        ogImg={metadata.ogImg}
        ogSiteName={metadata.ogSiteName}
        ogTitle={metadata.ogTitle}
        ogUrl={metadata.ogUrl}
        keywords={metadata.keywords}
      />
      <div data-testid="retainer-page">
        <div>
          <MainBanner scrollTo={() => scrollToEnroll()} />
        </div>
        <div>
          <Academy />
        </div>
        <div ref={enrollRef}>
          <Enroll scrollTo={() => scrollToFaq()} />
        </div>
        <div>
          <SystemRequirement />
        </div>
        <div ref={faqRef}>
          <Faq />
        </div>
        <div
          className={styles.worldBg}
          style={{
            background: 'url(assets/img/png/Retainer/bgcontent.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
        >
          <div></div>
        </div>
      </div>
    </Fragment>
  );
};

Retainer.template = 'dashboard';

export default Retainer;

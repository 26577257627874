import React from 'react';

import { ChecklistIcon, EmptyCircleIcon, LockIcon } from '../SVGComponents';
import styles from './launcherContainer.module.scss';

interface ILauncherContainer {
  status: string;
  children: JSX.Element;
}

const LauncherContainer: React.FC<ILauncherContainer> = ({
  status,
  children
}) => {
  const getIcon = () => {
    switch (status) {
      case 'active':
        return <EmptyCircleIcon />;
      case 'locked':
        return <LockIcon />;
      case 'completed':
        return <ChecklistIcon />;

      default:
        return <EmptyCircleIcon />;
    }
  };

  return (
    <div
      className={`${styles['launcher-container']} ${
        styles[`launcher-container__${status}`]
      }`}
      data-testid="launcher-container"
    >
      <div className={styles['launcher-icon']}>{getIcon()}</div>
      {children}
    </div>
  );
};

export default LauncherContainer;

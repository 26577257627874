import React from 'react';
import ReactLoading from 'react-loading';

import styles from './styles.module.scss';

interface IFullPageLoading {
  title?: string;
}

const FullPageLoading: React.FC<IFullPageLoading> = ({ title }) => {
  return (
    <div
      style={{
        background: 'linear-gradient(180deg, #03182c 70.51%, #023940 92.58%)',
        height: '100vh'
      }}
      data-testid="fullpage-loading"
    >
      {title === 'none' ? (
        <ReactLoading
          className="global-smloader-center"
          type={'spin'}
          color={'#36aeae'}
          height={'20%'}
          width={'20%'}
        />
      ) : (
        <div className={styles['body']}>
          <ReactLoading
            className="loading-center"
            type={'spin'}
            color={'#36aeae'}
            height={'20%'}
            width={'20%'}
          />
        </div>
      )}
    </div>
  );
};

export default FullPageLoading;

import React from 'react';

const LockIcon = () => {
  return (
    <div>
      <svg
        width="19"
        height="25"
        viewBox="0 0 19 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="lock-icon"
      >
        <path
          d="M3.93738 9.7998V5.09891V2.20605H14.9061V9.7998"
          stroke="#2298AC"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.56238 9.79977C1.01009 9.79977 0.562378 10.2475 0.562378 10.7998V23.9873C0.562378 24.5396 1.01009 24.9873 1.56238 24.9873H17.2811C17.8334 24.9873 18.2811 24.5396 18.2811 23.9873V10.7998C18.2811 10.2475 17.8334 9.79977 17.2811 9.79977H1.56238ZM11.031 17.3935H10.6609V16.2064C10.6609 15.9303 10.437 15.7064 10.1609 15.7064H9.49976C9.22361 15.7064 8.99976 15.9303 8.99976 16.2064V17.3935H8.65601C8.37986 17.3935 8.15601 17.6174 8.15601 17.8935V19.4248C8.15601 19.7009 8.37986 19.9248 8.65601 19.9248H11.031C11.3071 19.9248 11.531 19.7009 11.531 19.4248V17.8935C11.531 17.6174 11.3071 17.3935 11.031 17.3935Z"
          fill="#2298AC"
        />
      </svg>
    </div>
  );
};

export default LockIcon;

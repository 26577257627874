import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import { ListCountryType } from './Globe.type';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { EventDumType } from '@src/Components/Atoms/Card/CardEventList/CardEventList.type';
import { logEvent } from '@src/Domain/analytic';
import { AnalyticsEvent } from '@src/Domain/analytic/analytic.type';
import { StoreContext } from '@src/Store/Store.context';
import ReactLoading from 'react-loading';
import styles from './Globe.module.scss';
import { handleGlobeBehaviorScreen, markerSvgCountry } from './Globe.utils';
import './tooltip.scss';
import FloatingMarkerDetail from '@src/Components/Molecules/FloatingMarkerDetail/FloatingMarkerDetail';
import CarouselEventList from '@src/Components/Molecules/CarouselEventList/CarouselEventList';

interface IGlobeMythic {
  listCountry: ListCountryType[];
  setIndexCountry: React.Dispatch<React.SetStateAction<number>>;
  listEvent: EventDumType[];
  scrollToHomepageTop: () => void;
}

const GlobeMythic: React.FC<IGlobeMythic> = observer(
  ({ listCountry, setIndexCountry, listEvent, scrollToHomepageTop }) => {
    const { GlobeStore } = useContext(StoreContext);
    const globeEl = useRef<any>();
    const globe: any = globeEl.current;
    const { width } = useWindowDimensions();

    const [isHoveringCountry, setIsHoveringCountry] = useState<boolean>(false);
    const [hoveringCountryName, setHoveringCountryName] = useState<string>('');
    const [showFloatingEventList, setShowFloatingEventList] =
      useState<boolean>(false);
    const [showFloatingCountry, setShowFloatinCountry] =
      useState<boolean>(false);
    const [currentCountry, setCurrentCountry] = useState<string>('');
    const [isFirstOpen, setIsFirstOpen] = useState<boolean>(true);

    const GlobeProps = handleGlobeBehaviorScreen(width, showFloatingEventList);

    useEffect(() => {
      if (globe) {
        globe.pointOfView(
          {
            lat:
              GlobeStore.selectedCountry?.markerPositionY ??
              GlobeStore.selectedCountry?.lat,

            lng:
              GlobeStore.selectedCountry?.markerPositionX ??
              GlobeStore.selectedCountry?.lng,
            altitude: GlobeProps?.globeSize //GLOBE SIZE
          },
          1000 //TIMEOUT DURATION WHEN GLOBE GONNA ROTATING
        );
      }
    }, [GlobeStore?.selectedCountry?.name, showFloatingEventList]);

    // CLOUDS ANIMATION
    useEffect(() => {
      const globe = globeEl.current;
      let clouds: THREE.Mesh | null = null;

      const CLOUDS_IMG_URL = `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/globe/fair_clouds_4k.png`;
      const CLOUDS_ALT = 0.007; // Altitude of clouds layer
      const CLOUDS_ROTATION_SPEED = -0.007; // deg/frame

      if (globe) {
        new THREE.TextureLoader().load(
          CLOUDS_IMG_URL,
          (cloudsTexture: THREE.Texture) => {
            clouds = new THREE.Mesh(
              new THREE.SphereGeometry(
                globe.getGlobeRadius() * (1 + CLOUDS_ALT),
                75,
                75
              ),
              new THREE.MeshPhongMaterial({
                map: cloudsTexture,
                transparent: true,
                opacity: 0.9
              })
            );

            globe.scene().add(clouds);

            // Rotate clouds in an animation loop
            function rotateClouds() {
              if (clouds) {
                clouds.rotation.y += (CLOUDS_ROTATION_SPEED * Math.PI) / 180;
                requestAnimationFrame(rotateClouds);
              }
            }
            rotateClouds();
          }
        );
      }

      // Cleanup function to dispose of textures and remove clouds
      return () => {
        if (clouds) {
          clouds.geometry.dispose();
          (clouds.material as THREE.Material).dispose();
          (clouds.material as THREE.MeshPhongMaterial).map?.dispose();
          globe.scene().remove(clouds);
          clouds = null;
        }
      };
    }, [globeEl]);

    // CONTROL SCROLL FOR ZOOM IN AND ZOOM OUT FOR GLOBE
    useEffect(() => {
      const controls: any = globeEl?.current?.controls();

      if (controls) {
        controls.minDistance = GlobeProps?.zoomOut;
        controls.maxDistance = GlobeProps?.zoomIn;
      }
    }, []);

    //CONDITION WHEN CAN'T ZOOM THE GLOBE
    useEffect(() => {
      const controls: any = globeEl?.current?.controls();

      if (controls) {
        controls.enableZoom = width <= 1279 ? false : true;
      }
    }, [width]);

    const globeDataCountry = listCountry?.map((item: any) => ({
      lat: item.markerPositionY,
      lng: item.markerPositionX,
      name: item.name,
      totalEvent: item.totalEvent,
      type: item.type
    }));

    return (
      <>
        {!GlobeStore.isGlobeVisible && (
          <div className={styles['container-loading-globe']}>
            <ReactLoading
              className="loading-center"
              type={'spin'}
              color={'#878787'}
              height={96}
              width={96}
            />
          </div>
        )}

        <div
          id="canvas-globe"
          style={{
            // ! COMMENTED OUT TO FIX MARGIN TOP ISSUE
            // marginTop: GlobeProps?.marginTop,
            // marginLeft: GlobeProps?.marginLeft,
            // display: width <= 1279 ? 'flex' : 'inline',
            // justifyContent: width <= 1279 ? 'center' : 'flex-start',
            // transition: '1.1s all ease-in-out',
            opacity: GlobeStore.isGlobeVisible ? 1 : 0
          }}
        >
          <Globe
            width={GlobeProps?.canvasWidth}
            height={GlobeProps?.canvasHeight}
            ref={globeEl}
            globeImageUrl={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/globe/world_map_4.png`}
            backgroundImageUrl={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/globe/galaxy_starfield.png`}
            // bumpImageUrl={'./assets/globe/earth-topology.webp'}
            backgroundColor={'#000011'}
            onGlobeReady={() =>
              setTimeout(() => {
                GlobeStore.isGlobeVisible = true;
              }, 800)
            }
            htmlLat={'lat'}
            htmlLng={'lng'}
            htmlElementsData={globeDataCountry}
            htmlElement={(d: any) => {
              const el = document.createElement('div');
              el.innerHTML = markerSvgCountry(
                d,
                isHoveringCountry,
                hoveringCountryName,
                GlobeStore,
                isFirstOpen
              );
              el.style.visibility = GlobeStore.isGlobeVisible
                ? 'visible'
                : 'hidden';
              el.style.cursor = 'pointer';

              const button = document.querySelector('.button_component_marker');

              if (button) {
                button.addEventListener('click', function () {
                  alert('You clicked the span!');
                });
              }

              el.onclick = () => {
                setIsFirstOpen(false);
                setShowFloatingEventList(false);
                setCurrentCountry(d.name);
                setShowFloatinCountry(true);
                GlobeStore.isLoading = true;
                GlobeStore.handleSelectCountry({
                  lat: d.lat,
                  lng: d.lng,
                  name: d.name,
                  totalEvent: d.totalEvent,
                  type: d.type
                });
                setIndexCountry(
                  globeDataCountry.findIndex((item) => item.name === d.name)
                );
                logEvent(AnalyticsEvent.LINK_VISITED, 'Dum Event Detail Click');
              };

              return el;
            }}
            showAtmosphere={true} //glow effect outside globe
            atmosphereColor="#f3f3f3" //globe outline
          />
        </div>
        {showFloatingEventList && (
          <CarouselEventList
            eventDatas={listEvent}
            CountryName={currentCountry}
            onClose={() => setShowFloatingEventList(false)}
          />
        )}
        {!showFloatingEventList && showFloatingCountry && (
          <div className={styles['floating-marker-detail-wrapper']}>
            <FloatingMarkerDetail
              countryName={GlobeStore.selectedCountry?.name}
              eventCount={GlobeStore.selectedCountry?.totalEvent}
              onDetailClick={() => {
                setShowFloatinCountry(false);
                setShowFloatingEventList(true);
              }}
            />
          </div>
        )}
      </>
    );
  }
);

export default GlobeMythic;

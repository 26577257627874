import BackIcon from '@src/Components/Atoms/Shape/BackIcon';
import AchievementSection from '@src/Components/Organisms/AchievementSection/AchievementSection';
import ProfileStatistic from '@src/Components/Organisms/ProfileStatistic/ProfileStatistic';
import RiftstormStatistics from '@src/Components/Organisms/RiftstormStatistics/RiftstormStatistics';
import { withStore } from '@src/Store/Store.context';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './Profile.module.scss';

import ResponsiveProfile from './ResponsiveProfile/ResponsiveProfile';
import { useProfile } from './useProfile';

const Profile = observer(() => {
  const {
    width,
    accountId,
    ProfileStore,
    radarChartData,
    profileName,
    profileUsername,
    profileImg,
    profileIsLocked,
    profileData
  } = useProfile();

  if (width > 1279)
    return (
      <div
        className={styles['container-achievement']}
        data-testid="profile-page"
      >
        <div className={styles['box-achievement']}>
          <div className={styles['header-achievement']}>
            <a href={`${process.env.HOST_URL}`}>
              <BackIcon />
            </a>
            <div className={styles['title-user-profile']}>User Profile</div>
            {/* <ShareIcon /> */}
          </div>
          <div className={styles['container-statistic']}>
            <div id="profile-statistic" className={styles['profile-statistic']}>
              <ProfileStatistic
                name={profileName}
                username={profileUsername}
                radarChartData={radarChartData}
                levelProfileData={ProfileStore?.levelProfileData}
                actionProfileData={ProfileStore?.actionProfileData}
                imgProfile={profileImg}
                isLocked={profileIsLocked}
              />
            </div>
            <div className={styles['achievement-list']}>
              <AchievementSection
                accountId={accountId ?? ProfileStore?.adminAccountData?.sub}
                isProfile={true}
              />
            </div>
            <div
              id="riftstorm-statistic"
              className={styles['riftstorm-statistic']}
            >
              <RiftstormStatistics isLocked={profileIsLocked} />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <ResponsiveProfile
      imgProfile={profileImg}
      radarChartData={radarChartData}
      actionProfileData={ProfileStore?.actionProfileData}
      levelProfileData={ProfileStore?.levelProfileData}
      isLocked={process.env.RIFTSTORM_STATISTIC_LOCKED === 'true'}
      playerData={profileData}
      accountId={accountId ?? ProfileStore?.adminAccountData?.sub}
    />
  );
});

export default withStore(Profile);

import { useLocation } from 'react-router-dom';

const useURLHash = (hashName: string) => {
  const { hash } = useLocation();

  // Parse the hash string into a URLSearchParams object
  const hashParams = new URLSearchParams(hash.substring(1));

  // Get the value of the specified hash parameter
  const value = hash.substring(1);

  const removeHash = () => {
    if (hashParams.has(hashName)) {
      // Remove only the specific hash parameter
      hashParams.delete(hashName);

      // Update the URL with the remaining hash parameters (if any)
      const newHash = hashParams.toString() ? `#${hashParams.toString()}` : '';

      // Replace the current state without reloading the page
      window.history.replaceState(
        null,
        '',
        window.location.pathname + window.location.search + newHash
      );
    }
  };

  return { hashName, value, removeHash };
};

export default useURLHash;

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '@src/Store/Store.context';
import { CountryType } from '@src/Pages/FullScreenGlobe/Globe/Globe.type';

interface IUseEventCountry {
  indexCountry: number;
  country: CountryType[];
}

export const useEventCountry = ({
  indexCountry,
  country
}: IUseEventCountry) => {
  const { GlobeStore } = useContext(StoreContext);

  const [events, setEvents] = useState<any[]>([]);

  const getListEventCountry = async (countryId: number) => {
    setEvents([]);
    if (countryId !== undefined) {
      await axios
        .get(
          `${process.env.MYTHIC_GLOBAL_EVENT_URL}/event/country/${countryId}
              `
        )
        .then((result) => {
          setEvents(
            result.data.data && result.data?.data?.length > 0
              ? result.data?.data
              : []
          );
        })
        .catch((e) => {
          GlobeStore.errorResponse = e.response.data;
        })
        .finally(() => {
          GlobeStore.isLoading = false;
        });
    }
  };

  useEffect(() => {
    if (GlobeStore.isLoading) getListEventCountry(country[indexCountry]?.id);
  }, [indexCountry, GlobeStore.isLoading]);

  useEffect(() => {
    GlobeStore.handleSelectCountry(country[indexCountry]);
  }, [indexCountry]);

  return { events, setEvents, getListEventCountry };
};

const listCollection = [
  {
    id: 1,
    name: 'FOUNDER’S MASK',
    path: '',
    key: 'foundersmask',
    desc: 'Given to the few, the Founder’s Mask is the exclusive pass to the Mythic Protocol universe.',
    btn_desc: 'GRAB IT SOON',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/collection/newMask.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      +'/landing-page/medallion/collection/maskTiny.png'
  },
  {
    id: 2,
    name: 'MEDALLION',
    path: '',
    key: 'medallion',
    desc: 'The Compass Medallions are awarded to the community members. As an appreciation of effort solves the casefiles we`ve built to ease the members into our world.',
    btn_desc: 'GRAB YOURS',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/collection/Medallion.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/collection/medallionTiny.png'
  },
  {
    id: 3,
    name: 'Meta Mythic Object',
    path: '',
    key: 'nftx',
    desc: 'A collection of 8,888 experimental products, the Meta Mythic Objects are imbued with paranatural powers from the rift, these objects are unstable.',
    btn_desc: 'GRAB YOURS',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/collection/objectX.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/collection/objectXtiny.png'
  }
];

const listCollectionMedallion = [
  {
    id: 1,
    name: 'SILVER MEDALLION',
    path: '',
    key: 'silvermedallion',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/silver-medallion-large.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/silver-medallion-small.png'
  },
  {
    id: 2,
    name: 'GOLD MEDALLION',
    path: '',
    key: 'goldmedallion',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/gold-medallion-large.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/gold-medallion-small.png'
  },
  {
    id: 3,
    name: 'BRONZE MEDALLION',
    path: '',
    key: 'bronzemedallion',
    src:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/bronze-medallion-large.png',
    tinySrc:
      (process.env.REACT_APP_GCM_BUCKET_URL || '') +
      '/landing-page/medallion/medallion-collection/bronze-medallion-small.png'
  }
];

const collectionColor = {
  colorOne: '#08727F',
  colorTwo: '#14243A',
  isGradient: true
};

const animationVariants = {
  center: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1500 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 1]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, 500] : [0, -500],
        y: [0, -120],
        scale: [1, 0.8, 0],
        opacity: [1, 1, 0, 0]
      };
    }
  },
  left: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1500 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 0.66]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, 500] : [0, 1000],
        y: direction < 0 ? [0, 120] : 0,
        scale: [1, 0.8, 0],
        opacity: [0.66, 0.66, 0, 0]
      };
    }
  },
  right: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1500 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 0.66]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, -1000] : [0, -500],
        y: direction < 0 ? 0 : [0, 120],
        scale: [1, 0.8, 0],
        opacity: [0.66, 0.66, 0, 0]
      };
    }
  }
};

const medallionAnimationVariants = {
  center: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1500 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 1]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, 500] : [0, -500],
        y: [0, -120],
        scale: [1, 0.8, 0],
        opacity: [1, 1, 0, 0]
      };
    }
  },
  left: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 0 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 0.66]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, 500] : [0, 1000],
        // x: direction < 0 ? [500, 500] : [450, 1000],
        y: direction < 0 ? [0, 150] : 0,
        scale: [1, 0.8, 0],
        opacity: [0.66, 0.66, 0, 0]
      };
    }
  },
  right: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1500 : -1500,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: [0, 0, 0, 0.66]
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? [0, -1000] : [0, -500],
        y: direction < 0 ? 0 : [0, 120],
        scale: [1, 0.8, 0],
        opacity: [0.66, 0.66, 0, 0]
      };
    }
  }
};

const animationTransitions = {
  fast: {
    duration: 0.2,
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 0.2 }
  },
  medium: {
    duration: 0.8,
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 0.8 }
  },
  slow: {
    duration: 1.2,
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 1.2 }
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export {
  listCollection,
  listCollectionMedallion,
  medallionAnimationVariants,
  collectionColor,
  animationVariants,
  animationTransitions,
  swipeConfidenceThreshold,
  swipePower
};

import React, { useEffect, useState } from 'react';
import styles from './ModalKeystone.module.scss';
import { motion } from 'framer-motion';
import { keystoneDataAfterPlaytes } from './ModalKeystoneData';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

type IModalKeystone = {
  show?: boolean;
  onClose?: () => void;
  state?: 'kill-quard' | 'not-kill-quard' | 'netral';
  totalKillQuard: number;
  totalNotKillQuard: number;
};

const ModalKeystone: React.FC<IModalKeystone> = ({
  show = false,
  onClose,
  state = 'netral',
  totalKillQuard = 0,
  totalNotKillQuard = 0
}) => {
  const [keystoneState, setKeystoneState] = useState<
    { name: string; title: string; description: string } | undefined
  >(undefined);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setKeystoneState({
      title: keystoneDataAfterPlaytes.title,
      name: keystoneDataAfterPlaytes.name,
      description: keystoneDataAfterPlaytes.description
    });
  }, []);

  const totalPercentage =
    (totalNotKillQuard / (totalKillQuard + totalNotKillQuard)) * 100;

  const progressBarDekstop =
    -500 + (0 - -500) * parseFloat((totalPercentage / 100).toFixed(1));
  const progressBarTablet =
    -294 + (0 - -294) * parseFloat((totalPercentage / 100).toFixed(1));

  return (
    <>
      {show && (
        <div className={styles['container']}>
          <div className={styles['drop-shadow']} onClick={onClose} />
          <motion.div
            className={styles['content-container']}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div className={styles['close-button']} onClick={onClose}>
              <img src="./assets/img/svg/close-icon-modal.svg" alt="icon" />
            </div>
            <div className={styles['content-wrapper']}>
              <div className={styles['title']}>{keystoneState?.name}</div>
              <div className={styles['image-wrapper']}>
                <img
                  src="./assets/img/png/keystone-chat-static.webp"
                  alt="keystone-image"
                />
              </div>

              <div className={styles['bar-wrapper']}>
                <div className={styles['progress-bars']}>
                  <div
                    style={{
                      backgroundPosition: `${
                        width >= 768 ? progressBarDekstop : progressBarTablet
                      }px 0`
                    }}
                  >
                    <div className={styles['left-lable']}>
                      <p className={styles['lable-title']}>
                        {(
                          (totalKillQuard /
                            (totalKillQuard + totalNotKillQuard)) *
                          100
                        ).toFixed(1)}
                        %
                      </p>
                      <p className={styles['lable-description']}>
                        Saved Grey 6
                      </p>
                    </div>
                    <div className={styles['right-lable']}>
                      <p className={styles['lable-title']}>
                        {(
                          (totalNotKillQuard /
                            (totalKillQuard + totalNotKillQuard)) *
                          100
                        ).toFixed(1)}
                        %
                      </p>
                      <p className={styles['lable-description']}>
                        Did not save Grey 6
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['sub-title']}>{keystoneState?.title}</div>
              <div
                className={styles['description']}
                dangerouslySetInnerHTML={{
                  __html: keystoneState?.description ?? ''
                }}
              ></div>
              <div className={styles['button-wrapper']}>
                <div className={styles['button']}>
                  <div className={styles['button-icon']}>
                    <img src="assets/img/svg/steam-icon.svg" alt="steam-icon" />
                  </div>
                  <div
                    className={styles['button-text']}
                    onClick={() => {
                      window.open(process.env.URL_RIFTSTORM_STEAM, '_blank');
                    }}
                  >
                    WISHLIST NOW
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default ModalKeystone;

import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { StoreContext } from '@src/Store/Store.context';

import ReCAPTCHA from 'react-google-recaptcha';
import {
  DefaultFormUserfeedback,
  DefaultStatusUserfeedback,
  TFormUserfeedback,
  TStatusUserfeedback
} from './Navbar.type';

export const useNavbar = () => {
  const imgMythicRepo = `./assets/img/png/title.png`;
  const imgMythicProtocol =
    'https://staticassets.mythicprotocol.com/web/common3/build/assets/img/png/mp-logo-new.png';

  const auth = useAuth();
  const { UserStore, HelperStore } = useContext(StoreContext);
  const { width } = useWindowDimensions();

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);

  const [showUserfeedback, setShowUserfeedback] = useState<boolean>(false);
  const [formUserfeedback, setFormUserfeedback] = useState<TFormUserfeedback>(
    DefaultFormUserfeedback
  );
  const [statusUserfeedback, setStatusUserfeedback] =
    useState<TStatusUserfeedback>(DefaultStatusUserfeedback);
  const [showModalFaq, setShowModalFaq] = useState<boolean>(false);
  const [isDailyCheckin, setIsDailyCheckin] = useState<boolean>(false);

  const isAuthenticated = auth?.isAuthenticated;
  const user = auth?.user;
  const removeUser = auth?.removeUser;
  const profileImgUrl =
    user?.profile.picture ??
    `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/img_default.png`;

  useEffect(() => {
    const oidcStorage = localStorage.getItem(
      `oidc.user:${process.env.BASE_URL_OIDC_AUTHORITY}:${process.env.OIDC_CLIENT_ID}`
    );
    if (oidcStorage && isAuthenticated) {
      if (
        JSON.parse(oidcStorage).expires_at <
        Math.round(new Date().getTime() / 1000)
      ) {
        removeUser();
      }
    }
  }, [auth]);

  const handleAccountBtn = () => {
    if (process.env.REACT_APP_ENV !== 'release') {
      window.location.assign(
        `${process.env.SSO_DOMAIN}?back_url=https://${window.location.host}${process.env.HOST_URL}`
      );
    } else {
      window.location.assign(`${process.env.SSO_DOMAIN}`);
    }
  };

  const handleSignOut = () => {
    if (process.env.REACT_APP_ENV !== 'release') {
      localStorage.clear();
      auth.signoutRedirect({
        id_token_hint: user?.id_token,
        extraQueryParams: { back_url: `${process.env.PORTAL_URL}` }
      });
    } else {
      localStorage.clear();
      auth.signoutRedirect({ id_token_hint: user?.id_token });
    }
  };

  const handleSignIn = () => {
    auth.signinRedirect();
  };

  const handleActionShow = () => {
    setShowContent(!showContent);
  };

  const handleActionShowUserfeedback = (type: 'form' | 'status') => {
    if (type === 'form') {
      setShowUserfeedback(!showUserfeedback);
    } else {
      setShowUserfeedback(false);
      setStatusUserfeedback(DefaultStatusUserfeedback);
    }
  };

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onUserFeedbackSubmit = async (value: TFormUserfeedback) => {
    setStatusUserfeedback({ ...DefaultStatusUserfeedback, isLoading: true });
    setFormUserfeedback(value);

    let captchaToken: string | null = null;
    if (recaptchaRef.current) {
      captchaToken = await recaptchaRef?.current.executeAsync();
      if (!captchaToken) {
        setStatusUserfeedback({
          isLoading: false,
          isError: false,
          isSuccess: false,
          message: 'Unable to complete the CAPTCHA! Please reload the page'
        });
        return;
      }
    }

    try {
      const url = `${process.env.API_USER_FEEDBACK_URL}/feedback`;
      const reqBody = {
        product: value.platform.replace(/ /g, ''),
        topic: value.topic.replace(/ /g, ''),
        description: value.message,
        captchaToken
      };

      const res = await axios.post(url, reqBody, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`
        }
      });

      if (res.status === 200) {
        setStatusUserfeedback({
          isSuccess: true,
          isLoading: false,
          isError: false,
          message: ''
        });
        setShowUserfeedback(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        const checkErr = `${err}`.includes('undefined')
          ? 'Error, Something went wrong. Please refresh or try again later'
          : err;

        setStatusUserfeedback({
          isSuccess: false,
          isLoading: false,
          isError: true,
          message: `${checkErr}`
        });
        setShowUserfeedback(false);
      }
    }
  };

  useEffect(() => {
    if (user?.access_token) {
      UserStore.getAdminAccountData(user?.access_token);
    }
  }, [UserStore, user?.access_token]);

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        try {
          await auth.signinSilent();
          UserStore.loadUserManager();
        } catch (error) {
          await removeUser();
          localStorage.clear();
          UserStore.loadUserManager();
        }
      }
    })();
  }, [auth.events, auth.signinSilent]);

  return {
    imgMythicRepo,
    imgMythicProtocol,
    width,
    isAuthenticated,
    auth,
    UserStore,
    showSidebar,
    setShowSidebar,
    handleSignOut,
    handleSignIn,
    showContent,
    handleActionShow,
    showUserfeedback,
    handleActionShowUserfeedback,
    onUserFeedbackSubmit,
    statusUserfeedback,
    formUserfeedback,
    profileImgUrl,
    handleAccountBtn,
    recaptchaRef,
    showModalFaq,
    setShowModalFaq,
    isDailyCheckin,
    setIsDailyCheckin,
    HelperStore
  };
};

import React from 'react';
import ReactLoading from 'react-loading';

import styles from './ItemDaily.module.scss';

interface IItemDaily {
  index: number;
  currentDay: number;
  day: number;
  rewards: Array<{
    pointProviderId: number;
    point: number;
    pointName: string;
    pointTicker: string;
    totalReward?: number;
  }>;
  status: string;
  tooltipId: string;
  isLoading: boolean;
  onClick: () => void;
}

const ItemDaily: React.FC<IItemDaily> = ({
  currentDay,
  day,
  index,
  rewards,
  status,
  tooltipId,
  isLoading,
  onClick
}) => {
  const handleRewerdDays = (thisData: any) => {
    if (thisData.length === 1) {
      return (
        <div className={styles['type-1']}>
          <p className={styles[thisData[0].pointTicker]}>
            {thisData[0].pointTicker}
          </p>
          <p className={styles[thisData[0].pointTicker]}>
            +{thisData[0].point}
          </p>
        </div>
      );
    } else if (thisData.length === 2) {
      return (
        <div className={styles['type-2']}>
          <div>
            <p className={styles[thisData[0].pointTicker]}>
              {thisData[0].pointTicker}
            </p>
            <p className={styles[thisData[1].pointTicker]}>
              {thisData[1].pointTicker}
            </p>
          </div>
          <div>
            <p className={styles[thisData[0].pointTicker]}>
              +{thisData[0].point}
            </p>
            <p className={styles[thisData[1].pointTicker]}>
              +{thisData[1].point}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles['type-3']}>
          {thisData.map((item, index) => (
            <p key={Number(index)} className={styles[item.pointTicker]}>
              +{item.point} {item.pointTicker}
            </p>
          ))}
        </div>
      );
    }
  };

  return (
    <button
      key={Number(index)}
      data-tooltip-id={tooltipId}
      className={`${styles['item-daily']} ${styles[status]} ${
        styles[
          index + 1 === currentDay && status === 'Unclaimed' ? 'active' : ''
        ]
      }`}
      onClick={() => !isLoading && onClick()}
    >
      <div>
        {handleRewerdDays(rewards)}
        {isLoading ? (
          <ReactLoading
            data-testid="loading-spinner"
            className="loading-center"
            type="spin"
            color="#F2F2F2"
            height="19px"
            width="19px"
          />
        ) : (
          <p>DAY {day}</p>
        )}
      </div>
      {rewards.length >= 3 &&
        index + 1 !== currentDay &&
        status === 'Unclaimed' && <span className={styles['glow']} />}
    </button>
  );
};
export default ItemDaily;

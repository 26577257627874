import React from 'react';

const EmptyCircleIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="empty-circle-icon"
      >
        <circle cx="12" cy="12.6749" r="10.5" stroke="#50EAEA" />
      </svg>
    </div>
  );
};

export default EmptyCircleIcon;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QegrPMAnrKI0eyBCJ0rW{min-height:63px;position:relative}.QegrPMAnrKI0eyBCJ0rW .eGvsVPtUOOR9ieTJLDCK{position:absolute;top:21px;left:39px;z-index:0}@media screen and (max-width: 520px){.QegrPMAnrKI0eyBCJ0rW .eGvsVPtUOOR9ieTJLDCK{left:15px}}@media screen and (max-width: 350px){.QegrPMAnrKI0eyBCJ0rW .eGvsVPtUOOR9ieTJLDCK{left:15px;top:23px}.QegrPMAnrKI0eyBCJ0rW .Y4t9ubef5HhNm0eIUlGi{width:15px;height:15px}}.UElvji9wBU9EgbnFj7Yq{background:linear-gradient(270deg, #042930 4.17%, #161f35 92.71%);border:1px solid var(--primary-tosca-300);z-index:1}.UtTr9qitBqJqf1Yf1MSp{background:var(--bg-tosca);border:1px solid var(--primary-tosca-300);z-index:1}.dwatSawKZ8l55vq5QzDH{background:rgba(3,25,51,.85);border:1px dashed var(--primary-tosca-300);opacity:.7;z-index:1}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/LauncherContainer/launcherContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,QAAA,CACA,SAAA,CACA,SAAA,CAEA,qCANF,4CAOI,SAAA,CAAA,CAGF,qCAVF,4CAWI,SAAA,CACA,QAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAAA,CAOR,sBACE,iEAAA,CACA,yCAAA,CACA,SAAA,CAGF,sBACE,0BAAA,CACA,yCAAA,CACA,SAAA,CAGF,sBACE,4BAAA,CACA,0CAAA,CACA,UAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"launcher-container": `QegrPMAnrKI0eyBCJ0rW`,
	"launcher-icon": `eGvsVPtUOOR9ieTJLDCK`,
	"launcher-iconsvg": `Y4t9ubef5HhNm0eIUlGi`,
	"launcher-container__active": `UElvji9wBU9EgbnFj7Yq`,
	"launcher-container__completed": `UtTr9qitBqJqf1Yf1MSp`,
	"launcher-container__locked": `dwatSawKZ8l55vq5QzDH`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pWK8zeOuweLHYmi7dWii{display:flex;flex-direction:column;justify-content:flex-end;align-items:center;row-gap:16px;box-sizing:border-box;position:relative}.pWK8zeOuweLHYmi7dWii .R2oh6mTkgw6W8p562Ssw{flex-direction:column;justify-content:flex-end;align-items:center;row-gap:8px;position:absolute;bottom:80px}@media screen and (min-width: 1280px){.pWK8zeOuweLHYmi7dWii .R2oh6mTkgw6W8p562Ssw{right:0px;bottom:72px}}.pWK8zeOuweLHYmi7dWii .R2oh6mTkgw6W8p562Ssw .dEgPta6eeVfHo1qysdce{width:48px !important;height:48px !important;box-sizing:border-box;display:flex;justify-content:center;align-items:center}@media screen and (min-width: 1280px){.pWK8zeOuweLHYmi7dWii .R2oh6mTkgw6W8p562Ssw .dEgPta6eeVfHo1qysdce{width:172px !important;height:42px !important}}.pWK8zeOuweLHYmi7dWii .OG6Z46zkMRsJhswex923{width:64px;height:64px;box-sizing:border-box;display:flex;justify-content:center;align-items:center;border-radius:4px;border:.5px solid #203475;background:#1a2a5e;cursor:pointer;transition:.2s ease-in}.pWK8zeOuweLHYmi7dWii .OG6Z46zkMRsJhswex923:hover{border:1px solid #cd1c6a;background:#a41655}.pWK8zeOuweLHYmi7dWii .OG6Z46zkMRsJhswex923.MewVMWV6XYIR092XxSzT:hover{background:#1a2a5e;border:.5px solid #203475}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/FabGroupButton/FabGroupButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,kBAAA,CACA,YAAA,CAEA,qBAAA,CAEA,iBAAA,CAEA,4CACE,qBAAA,CACA,wBAAA,CACA,kBAAA,CACA,WAAA,CAEA,iBAAA,CACA,WAAA,CAEA,sCATF,4CAUI,SAAA,CACA,WAAA,CAAA,CAGF,kEACE,qBAAA,CACA,sBAAA,CAEA,qBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,sCAVF,kEAWI,sBAAA,CACA,sBAAA,CAAA,CAKN,4CACE,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,iBAAA,CACA,yBAAA,CACA,kBAAA,CACA,cAAA,CAEA,sBAAA,CAEA,kDACE,wBAAA,CACA,kBAAA,CAIA,uEACE,kBAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pWK8zeOuweLHYmi7dWii`,
	"button-childs-wrapper": `R2oh6mTkgw6W8p562Ssw`,
	"button": `dEgPta6eeVfHo1qysdce`,
	"main-button": `OG6Z46zkMRsJhswex923`,
	"locked": `MewVMWV6XYIR092XxSzT`
};
export default ___CSS_LOADER_EXPORT___;

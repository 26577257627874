import React from 'react';

const RepositoryIcon: React.FC<{ color?: string }> = ({
  color = '#C4964D'
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="repository-icon"
    >
      <path
        d="M10.4686 4.93042L9.34744 6.05607C9.96226 6.48102 10.3646 7.19529 10.3646 7.99545C10.3646 9.29742 9.30676 10.3598 8.00027 10.3598C6.69379 10.3598 5.64047 9.29742 5.64047 7.99545C5.64047 7.19077 6.04281 6.48102 6.65763 6.05607L5.53197 4.93042C4.61427 5.67181 4.06274 6.79747 4.06274 7.99545C4.06274 10.1654 5.83034 11.933 8.00027 11.933C10.1702 11.933 11.9378 10.1654 11.9378 7.99545C11.9378 6.79747 11.3863 5.67181 10.4686 4.93042Z"
        fill={color}
      />
      <path
        d="M10.0255 2.03271L8.00021 4.05799L5.97494 2.03271C3.49308 2.87809 1.70288 5.23337 1.70288 7.99552C1.70288 11.3363 4.32037 14.0804 7.61143 14.2838V12.7016C5.18834 12.5027 3.2806 10.4683 3.2806 7.99552C3.2806 6.29121 4.19831 4.74513 5.63137 3.91333L8.00473 6.28669L10.3781 3.91333C11.8157 4.74966 12.7289 6.29121 12.7289 7.99552C12.7289 10.4683 10.8211 12.5027 8.39804 12.7016V14.2838C11.6891 14.0804 14.3066 11.3408 14.3066 7.99552C14.3021 5.22885 12.5119 2.87809 10.0255 2.03271Z"
        fill={color}
      />
      <path
        d="M1.67584 11.1917C1.18761 10.2288 0.911846 9.14379 0.911846 7.99553C0.911846 4.22075 3.88195 1.12407 7.607 0.920639V0.129517C3.43892 0.332948 0.125244 3.77772 0.125244 7.99553C0.125244 9.28845 0.437172 10.509 0.988698 11.5895L1.67584 11.1917Z"
        fill={color}
      />
      <path
        d="M8.39307 0.916122C12.1181 1.11955 15.0882 4.21623 15.0882 7.99102C15.0882 9.13927 14.8125 10.2288 14.3242 11.1872L15.0114 11.585C15.5629 10.509 15.8748 9.28846 15.8748 7.99102C15.8748 3.7732 12.5612 0.328431 8.39307 0.125V0.916122Z"
        fill={color}
      />
      <path
        d="M13.9313 11.8743C12.6655 13.8046 10.4775 15.084 8.00014 15.084C5.5228 15.084 3.3393 13.8046 2.06898 11.8743L1.38184 12.2721C2.78325 14.4375 5.22443 15.8751 7.99562 15.8751C10.7713 15.8751 13.208 14.442 14.6094 12.2721L13.9313 11.8743Z"
        fill={color}
      />
      <path
        d="M8.00046 9.17536C8.6521 9.17536 9.18036 8.6471 9.18036 7.99545C9.18036 7.34381 8.6521 6.81555 8.00046 6.81555C7.34882 6.81555 6.82056 7.34381 6.82056 7.99545C6.82056 8.6471 7.34882 9.17536 8.00046 9.17536Z"
        fill={color}
      />
    </svg>
  );
};

export default RepositoryIcon;

import React from 'react';
import styles from './FooterDesktop.module.scss';
import dayjs from 'dayjs';

const FooterDesktop = () => {
  const currentYear = dayjs().year();
  return (
    <div className={styles['container']}>
      <p className={styles['first-text']}>
        © {currentYear} Confiction Labs. All rights reserved.
      </p>
      <p
        className={styles['second-text']}
        onClick={() => {
          window.open(`${process.env.HOST_URL}/privacy-policy`, '_blank');
        }}
      >
        Privacy Policy
      </p>
    </div>
  );
};

export default FooterDesktop;

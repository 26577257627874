import NotFoundLayout from '@src/Components/Layouts/404Layout/NotFoundLayout';
import DashboardLayout from '@src/Components/Layouts/Dashboard/Dashboard';
import OtherLayout from '@src/Components/Layouts/OtherLayout/OtherLayout';

import { RouteRootInterface } from '.';
import Callback from './Callback/Callback';
import CommunityAgreement from './CommunityAgreement/CommunityAgreement';
import FullScreenGlobe from './FullScreenGlobe/FullScreenGlobe';
import NotFound from './NotFound/NotFound';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Profile from './Profile/Profile';
import Retainer from './Retainer/Retainer';
import Doggy from './Doggy/Doggy';
import MedallionPromo from './MedallionPromo/MedallionPromo';
import PlaytestResult from './PlaytestResult/PlaytestResult';
import DataDeletetionInstruction from './DataDeletionInstuction/DataDeletetionInstruction';
import TaC from './TaC/TaC';
import OPTombstone from './OPTombstone/OPTombstone';
import TheWard from './TheWard/TheWard';

const routes: RouteRootInterface[] = [
  {
    name: 'main',
    path: '/',
    component: DashboardLayout,
    key: 'main',
    routes: [
      {
        name: 'home',
        path: '/',
        index: true,
        component: FullScreenGlobe
      },
      {
        name: 'callback',
        path: '/callback',
        component: Callback
      },
      {
        name: 'Profile',
        path: '/profile',
        component: Profile
      },
      {
        name: 'Retainer Academy',
        path: '/retaineracademy',
        component: Retainer
      },
      {
        name: 'Retainer Academy',
        path: '/retaineracademy',
        component: Retainer
      },
      {
        name: 'Doggy Day Care',
        path: '/doggy-daycare',
        component: Doggy
      },
      {
        name: 'Operation Tombstone',
        path: '/op-tombstone',
        component: OPTombstone
      },
      {
        name: 'The Ward',
        path: '/the-ward',
        component: TheWard
      },
      {
        name: 'Playtest Result',
        path: '/playtest-result',
        component: PlaytestResult
      },
      {
        name: 'Data Deletion Instuction',
        path: '/data-deletion-instruction',
        component: DataDeletetionInstruction
      }
    ]
  },
  {
    name: 'other',
    path: '/',
    component: OtherLayout,
    routes: [
      {
        name: 'Terms of Use',
        path: '/terms-of-use',
        component: TaC
      },
      {
        name: 'Community Agreement',
        path: '/community-agreement',
        component: CommunityAgreement
      },
      {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy
      },
      {
        name: 'Compass-Medallion',
        path: 'compass-medallion',
        component: MedallionPromo
      }
    ]
  },
  {
    name: '404',
    path: '*',
    component: NotFoundLayout,
    routes: [
      {
        name: 'not-found',
        path: '*',
        component: NotFound
      }
    ]
  }
];

export default routes;

import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div
      className={styles['main-wrapper']}
      style={{ backgroundImage: `url(assets/img/png/grid-background.png)` }}
      data-testid="privacy-policy"
    >
      <div className={styles['header']}></div>
      <div className={styles['container']}>
        <div className={styles['main-title']}>Privacy policy</div>
        <div className={styles['title']}>Privacy Policy</div>
        <div className={styles['text']}>Last Updated On 28-July-2024 </div>
        <div className={styles['text']}>Effective Date 28-July-2024</div>

        <div className={styles['content-wrapper']}>
          <div className={styles['text']}>
            This Privacy Policy describes the policies of Confiction Labs,
            email:
            <a href="mailto:legal@confiction.com"> legal@confiction.com </a> on
            the collection, use and disclosure of your information that we
            collect when you use our content and product
            (https://one.confiction.com/portal). (the “Service”). By accessing
            or using the Service, you are consenting to the collection, use and
            disclosure of your information in accordance with this Privacy
            Policy. If you do not consent to the same, please do not access or
            use the Service.
          </div>
          <div className={styles['text']}>
            We may modify this Privacy Policy at any time without any prior
            notice to you and will post the revised Privacy Policy on the
            Service. The revised Policy will be effective 180 days from when the
            revised Policy is posted in the Service and your continued access or
            use of the Service after such time will constitute your acceptance
            of the revised Privacy Policy. We therefore recommend that you
            periodically review this page.
          </div>
          <div className={styles['text']}>
            Compliance With GDPR To the extent required by law, The Confiction
            portal complies with the EU General Data Protection Regulation
            (GDPR). GDPR gives users the following rights:
            <ul>
              <li>Right of erasure (right to be forgotten)</li>
              <li>Right of rectification </li>
              <li>Right to be informed</li>
              <li>Right of access</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object</li>
              <li>Right not to be subject to automated decision making</li>
              <li>Right to complain to a supervisory authority</li>
            </ul>
          </div>
          <div className={styles['text']}>
            Information We Collect: <br /> Name We will collect and process the
            following personal information about you:
            <ul>
              <li>Username</li>
              <li>Email</li>
              <li>Transaction and Payment</li>
              <li>Info Digital Wallet</li>
              <li>Confiction Labs Account ID (linked)</li>
            </ul>
          </div>
          <div className={styles['text']}>
            Information Collection and Use <br />
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Confiction Labs. The information that
            we request will be retained by us and used as described in this
            privacy policy.
          </div>
          <div className={styles['text']}>
            Log Data <br />
            We want to inform you that whenever you use our Service we may
            collect Log Data may include information such as your device
            Internet Protocol (“IP”) address, device name, operating system
            version, the configuration when utilizing our Service, the time and
            date of your use of the Service, and other statistics.
          </div>
          <div className={styles['text']}>
            How We Collect Your Information: <br />
            We collect/receive information about you in the following manner:
            <ul>
              <li>
                When a user fills up the registration form or otherwise submits
                personal information Interacts with the website.
              </li>
              <li>When a user submitted sufficient proofs of humanity.</li>
              <li>When a user fills up the Assignment Dashboard.</li>
            </ul>
          </div>
          <div className={styles['text']}>
            How We Use Your Information: <br />
            We use your personal information for a variety of business purposes,
            including to provide our Services, for administrative purposes, and
            to market our products and services, as described below:
            <ul>
              <li>
                Marketing/ Promotional
                <br />
                We may use personal information to tailor and provide you with
                content. We may provide you with these materials as permitted by
                applicable law. Some of the ways we may market to you include
                email campaigns.
              </li>
              <li>
                Creating user account <br />
                We may collect personal information when you create an account
                with us or our service providers, such as name, phone number,
                time zone, and email address.
              </li>
              <li>
                Customer feedback collection and Support
                <br />
                Answering requests for customer or technical support.
              </li>
              <li>
                Administration info
                <br />
                Allowing you to register for events, Auditing relating to
                interactions, transfers and other compliance activities, Sharing
                information with third parties as needed to provide the
                Services, Administer contests, surveys, and sweepstakes you
                enter, notify contest winners, and award prizes; and
              </li>
              <li>
                Site protection <br />
                Debugging to identify and repair errors with our Services,
                Detecting security incidents, protecting against malicious,
                deceptive, fraudulent or illegal activity, and prosecuting those
                responsible for that activity. Legal and Dispute resolution.
                Other uses as required to comply with our legal obligations,
                applicable laws, regulations, regulators and authorities.
              </li>
              <li>
                Legal and Dispute resolution.
                <br />
                Other uses as required to comply with our legal obligations,
                applicable laws, regulations, regulators and authorities.
              </li>
            </ul>
          </div>
          <div className={styles['text']}>
            Manage user account <br />
            If we want to use your information for any other purpose, we will
            ask you for consent and will use your information only on receiving
            your consent and then, only for the purpose(s) for which grant
            consent unless we are required to do otherwise by law.
          </div>
          <div className={styles['text']}>
            How We Share Your Information: <br />
            We will not transfer your personal information to any third party
            without seeking your consent, except in limited circumstances as
            described below:
            <ul>
              <li>Legal entities </li>
              <li>Analytics</li>
              <li>Payment recovery services</li>
              <li>Data collection & process</li>
            </ul>
          </div>
          <div className={styles['text']}>
            We require such third parties to use the personal information we
            transfer to them only for the purpose for which it was transferred
            and not to retain it for longer than is required for fulfilling the
            said purpose. We may also disclose your personal information for the
            following:
            <ol>
              <li>
                to comply with applicable law, regulation, court order or other
                legal process;
              </li>
              <li>
                to enforce your agreements with us, including this Privacy
                Policy; or
              </li>
              <li>
                to respond to claims that your use of the Service violates any
                third-party rights. If the Service or our company is merged or
                acquired with another company, your information will be one of
                the assets that is transferred to the new owner.
              </li>
            </ol>
          </div>
          <div className={styles['text']}>
            Retention Of Your Information: <br />
            We will retain your personal information with us for 90 days to 2
            years after users terminate their accounts or for as long as we need
            it to fulfill the purposes for which it was collected as detailed in
            this Privacy Policy. We may need to retain certain information for
            longer periods such as record-keeping / reporting in accordance with
            applicable law or for other legitimate reasons like enforcement of
            legal rights, fraud prevention, etc. Residual anonymous information
            and aggregate information, neither of which identifies you (directly
            or indirectly), may be stored indefinitely.
          </div>
          <div className={styles['text']}>
            Your Information May Be Maintained Within The United States <br />
            Please be aware that we may process and store information in the
            United States. By using the Services, you agree that the collection,
            use, transfer, and disclosure of your information and communications
            will be governed by the applicable laws in the United States.
            Accordingly, we may transfer and store data outside of your home
            country. Where required by law, we make use of appropriate legal
            mechanisms to safeguard data transfers.
          </div>
          <div className={styles['text']}>
            Your Rights: <br />
            Depending on the law that applies, you may have a right to access
            and rectify or erase your personal data or receive a copy of your
            personal data, restrict or object to the active processing of your
            data, ask us to share (port) your personal information to another
            entity, withdraw any consent you provided to us to process your
            data, a right to lodge a complaint with a statutory authority and
            such other rights as may be relevant under applicable laws. To
            exercise these rights, you can write to us at{' '}
            <a href="mailto:legal@confiction.com"> legal@confiction.com </a>. We
            will respond to your request in accordance with applicable law.
          </div>
          <div className={styles['text']}>
            You may opt-out of direct marketing communications or the profiling
            we carry out for marketing purposes by writing to us at
            <a href="mailto:contact@confiction.com"> contact@confiction.com </a>
            .
          </div>
          <div className={styles['text']}>
            Do note that if you do not allow us to collect or process the
            required personal information or withdraw the consent to process the
            same for the required purposes, you may not be able to access or use
            the services for which your information was sought.
          </div>
          <div className={styles['text']}>
            Security: <br />
            The security of your information is important to us and we will use
            reasonable security measures to prevent the loss, misuse or
            unauthorized alteration of your information under our control.
            However, given the inherent risks, we cannot guarantee absolute
            security and consequently, we cannot ensure or warrant the security
            of any information you transmit to us and you do so at your own
            risk.
          </div>
          <div className={styles['text']}>
            Grievance / Data Protection Officer: <br />
            If you have any queries or concerns about the processing of your
            information that is available with us, you may email our Grievance
            Officer email:{' '}
            <a href="mailto:legal@confiction.com"> legal@confiction.com </a>. We
            will address your concerns in accordance with applicable law.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';

import styles from './TagMedia.module.scss';

const TagMedia: React.FC = () => {
  return (
    <div className={styles['media__tag']} data-testid="media-tag">
      MEDIA
    </div>
  );
};

export default TagMedia;

export interface UserDataType {
  accountId: string;
  username: string;
  email: string;
  walletAddress: string;
  id: number;
  createDate: Date;
  updateDate: Date;
}

export enum careerNameType {
  Archivist = 'Archivist',
  Collector = 'Collector',
  FieldOperations = 'Field Operations',
  Merchantry = 'Merchantry',
  Seer = 'Seer'
}

export interface LevelProfileDataType {
  username: string;
  profilePicture: null;
  level: number;
  totalExp: number;
  rangeToNextLevel: number;
}

export interface ActionProfileDataType {
  actionName: string;
  limitation: number;
  limitationUnit: number;
  actionCount: number;
  expGiven: number;
}

export interface AdminAccountDataType {
  accountId: string;
  email: string;
  wallet_address: string;
  twitterId: null;
  nickname: string;
  name: string;
  birthDate: Date;
  country: string;
  gender: string;
  city: string;
  entitlement: string;
  profilePictureUrl: string;
}

export interface UserInfoType {
  sub: string;
  email: string;
  gender: string;
  entitlement: string;
  nickname: string;
  country: string;
  wallet_address: string;
  preferred_username: string[];
  name: string;
  birthdate: Date;
  picture: string;
  username: string;
}

export interface AchievementDataType {
  achievement_id: number;
  img_url: string;
  title: string;
  tags: string[];
  prompt: string;
  description: string;
  isOwned: boolean;
}

export interface ProfileDataPlayerType {
  name: string;
  username: string;
  profilePicture: string;
}

import Button from '@src/Components/Atoms/Buttons/buttons';
import { logEvent } from '@src/Domain/analytic';
import React from 'react';
import styles from './MainBanner.module.scss';

type IMainBanner = {
  scrollTo: () => void;
};
const MainBanner = (props: IMainBanner) => {
  const onHandleDownload = () => {
    props.scrollTo();
    logEvent('user', 'CTA-Download Main Banner');
  };

  return (
    <div
      className={styles['main-banner__container']}
      style={{
        backgroundImage: `url(./assets/img/png/Retainer/background/retainerbanner.png)`
      }}
      data-testid="main-banner"
    >
      <div className={styles['main-banner__backdrop']}></div>
      <div className={styles['main-banner__content']}>
        <p>Retainer Academy</p>
        <div
          className={styles['button-container']}
          onClick={() => onHandleDownload()}
          onKeyDown={() => {
            return;
          }}
        >
          <Button>
            <span className={styles['button-text']}>Download</span>
          </Button>
        </div>
      </div>
      <div className={styles.gradientShadow} />
    </div>
  );
};

export default MainBanner;

export const handleCheckCondition = (
  condition?: any,
  trueData?: any,
  falseData?: any
) => {
  if (condition) {
    return trueData;
  } else {
    return falseData;
  }
};

import React, { useState } from 'react';
import { ISelect } from './Select.type';
import styles from './Select.module.scss';
import ChevronIcon from './icons/ChevronIcon';
import CheckIcon from './icons/CheckIcon';

const Select: React.FC<ISelect> = ({
  variant = 'general-line',
  label,
  options,
  onChange,
  name,
  value = '',
  borderColor,
  disabled = false
}) => {
  const [isShow, setIsShow] = useState(false);

  const isLabelEmpty = value !== '' ? styles['not-empty'] : '';

  return (
    <div className={`${styles['container']} ${styles[variant]}`}>
      {isShow && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh'
          }}
          onClick={() => setIsShow(false)}
          onKeyDown={() => {
            return;
          }}
        ></div>
      )}
      <div
        className={`${styles['input-box']} ${isShow ? styles['active'] : ''}`}
        style={{ borderColor: borderColor }}
        onClick={() => (disabled ? undefined : setIsShow((prev) => !prev))}
        onKeyDown={() => {
          return;
        }}
      >
        <div className={styles['input']}>
          {value !== ''
            ? options.filter((item) => item.value === value)?.[0].text
            : undefined}
        </div>
        <div
          className={`${styles['btn-prefix']} ${
            isShow ? styles['active'] : ''
          }`}
        >
          <ChevronIcon />
        </div>
        <div
          className={`${styles['label']} ${
            isShow ? styles['active'] : isLabelEmpty
          }`}
        >
          {label}
        </div>
      </div>
      {isShow && (
        <div className={styles['options-wrapper']}>
          <div className={`${styles['options']} `}>
            {options.map((item) => (
              <div
                className={`${styles['option']} ${
                  value === item.value ? styles['active'] : ''
                }`}
                key={item.value}
                onKeyDown={() => {
                  return;
                }}
                onClick={() => {
                  onChange?.({
                    currentTarget: {
                      name,
                      value: item.value
                    }
                  });
                  setIsShow(false);
                }}
              >
                <div className={styles['icon']}>
                  {value === item.value && <CheckIcon />}
                </div>
                {item.text}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;

import ReactGA from 'react-ga4';

const GaId = `G-${process.env.GA_ID}`;

const initGA = () => {
  ReactGA.initialize(GaId);
};

const logPageView = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  });
};

const logEvent = (category = '', action = '', label = action) => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export { initGA, logPageView, logEvent };

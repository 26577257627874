import {
  ModalError,
  ModalFaq,
  ModalUserFeedback,
  ModalUserFeedbackSuccess,
  NavbarAccount
} from '@jupiter/react-common-component';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import { withStore } from '@src/Store/Store.context';

import ButtonNavbarItem from '@src/Components/Atoms/Button/ButtonNavbarItem/ButtonNavbarItem';
import ModalDailyChecking from '@src/Components/Organisms/ModalDailyCheckin';
import { modalDailyHidden } from '@src/Components/Organisms/ModalDailyCheckin/Utils/FormatDate';

import styles from './Navbar.module.scss';
import { useNavbar } from './useNavbar';
import useLoading from '@src/Common/Hooks/useLoading';

const siteKeyCaptchaUserFeedback =
  process.env.REACT_APP_USER_FEEDBACK_CAPTCHA_KEY ?? '';

const Navbar = observer(() => {
  const {
    width,
    isAuthenticated,
    UserStore,
    handleSignOut,
    handleSignIn,
    showContent,
    handleActionShow,
    showUserfeedback,
    handleActionShowUserfeedback,
    onUserFeedbackSubmit,
    statusUserfeedback,
    formUserfeedback,
    profileImgUrl,
    handleAccountBtn,
    recaptchaRef,
    showModalFaq,
    setShowModalFaq,
    isDailyCheckin,
    setIsDailyCheckin,
    HelperStore
  } = useNavbar();

  const handleFeedback = () => {
    if (width < 768) {
      handleActionShow();
      handleActionShowUserfeedback('form');
    } else {
      handleActionShowUserfeedback('form');
    }
  };

  const handleFaqButton = () => {
    if (width < 768) {
      handleActionShow();
      setShowModalFaq(true);
    } else {
      setShowModalFaq(true);
    }
  };

  const handleDailyCheckIn = () => {
    if (width < 1278) {
      handleActionShow();
      setIsDailyCheckin(!isDailyCheckin);
    } else {
      setIsDailyCheckin(!isDailyCheckin);
    }
  };

  const [listFaq, setListFaq] = useState<
    { question: string; answer: string }[]
  >([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_GCM_BUCKET_URL}/json/list-faq-product.json`)
      .then((res) => setListFaq(res.data.portal));
  }, []);

  return (
    <>
      <header className={styles['header_containers']}>
        <NavbarAccount
          isShowSidebar={showContent}
          handleShowSidebar={handleActionShow}
          isAuthenticated={isAuthenticated}
          loadingBtnLogin={HelperStore.isLoading('auth-loading')}
          labelBtnLogin="Login"
          headingTitle={'PORTAL'}
          handleHeadingClick={() => {
            return (window.location.href = `${process.env.HOST_URL}`);
          }}
          targetHref="disabled"
          onSignIn={() => {
            handleSignIn();
            HelperStore.handleLoading('auth-loading', 2000);
          }}
          onSignOut={handleSignOut}
          handleFeedback={handleFeedback}
          isFeedbackIcon={true}
          profile={{
            nickname: UserStore.adminAccountData.username,
            entitlement: UserStore.adminAccountData.entitlement,
            email: UserStore.adminAccountData.email,
            accountId: '',
            profilePictureUrl: profileImgUrl,
            walletAddress: UserStore.adminAccountData.wallet_address
          }}
          isFaqIcon
          handleFaqButton={handleFaqButton}
          handleAccountSetting={handleAccountBtn}
          isDailyCheckIn={!modalDailyHidden}
          handleDailyCheckIn={handleDailyCheckIn}
        />
      </header>

      {showUserfeedback ? (
        <>
          <ModalUserFeedback
            show={showUserfeedback}
            loading={statusUserfeedback.isLoading}
            onClose={() => handleActionShowUserfeedback('form')}
            onSubmit={onUserFeedbackSubmit}
          />
          <ReCAPTCHA
            className={styles['recaptcha']}
            sitekey={siteKeyCaptchaUserFeedback}
            ref={recaptchaRef}
            size="invisible"
          />
        </>
      ) : null}

      {statusUserfeedback.isSuccess ? (
        <ModalUserFeedbackSuccess
          show={statusUserfeedback.isSuccess}
          onClose={() => handleActionShowUserfeedback('status')}
        />
      ) : null}

      {statusUserfeedback.isError ? (
        <ModalError
          show={statusUserfeedback.isError}
          onRetry={() => onUserFeedbackSubmit(formUserfeedback)}
          onClose={() => handleActionShowUserfeedback('status')}
          title="Error"
        />
      ) : null}

      <ModalFaq
        show={showModalFaq}
        onClose={() => setShowModalFaq(false)}
        listFaq={listFaq}
      />

      {/* Modal Daily check-in */}
      <ModalDailyChecking
        isShow={isDailyCheckin}
        onClose={() => {
          setIsDailyCheckin(false);
        }}
      />
    </>
  );
});

export default withStore(Navbar);

import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useEffect, useState } from 'react';
import styles from './MedallionPromo.module.scss';
import {
  animationTransitions,
  listCollectionMedallion,
  medallionAnimationVariants,
  swipeConfidenceThreshold,
  swipePower
} from '@src/Pages/MedallionPromo/contents/collection.constant';
import GoldLine from '@src/Components/Atoms/goldLine';
import { initGA, logEvent, logPageView } from '@src/Domain/analytic';
import Footer from '@src/Components/Layouts/Footer/Footer';
import ButtonDefaultOld from '@src/Components/Atoms/ButtonDefaultOld/ButtonDefault';

const indexOnImageLeft = (idx: number) => {
  if (idx === 0) return 2;
  if (idx === 1) return 0;
  return 1;
};

const indexOnImageRight = (idx: number) => {
  if (idx === 0) return 1;
  if (idx === 1) return 2;
  return 0;
};

const MedallionPromoContent = () => {
  useEffect(() => {
    if (
      !(window as unknown as { [x: string]: string | boolean | number })
        .GA_INITIALIZED
    ) {
      initGA();
      (
        window as unknown as { [x: string]: string | boolean | number }
      ).GA_INITIALIZED = true;
    }

    logPageView();
  }, []);

  const handleCTA = () => {
    window.open(process.env.OPENSEA_MEDALLION_URL ?? '', '_blank');
    logEvent('user', 'CTA-CompassMedallion-MarketPlace');
  };

  const [[page, direction], setPage] = useState([1, 0]);
  const imageIndex = wrap(0, listCollectionMedallion.length, page);
  const paginate = (newDirection: number) => {
    setIsAnimated(true);
    setTimeout(() => setPage([page + newDirection, newDirection]), 50);

    setTimeout(() => {
      setIsAnimated(false);
    }, 300);
  };

  const [isAnimated, setIsAnimated] = useState<boolean>(false);

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['banner']}>
          <div className={styles['image-wrapper']}>
            <video loop muted autoPlay playsInline>
              <source
                src={
                  process.env.REACT_APP_GCM_BUCKET_URL +
                  '/landing-page/medallion/medallion.mp4'
                }
                type="video/mp4"
              />
            </video>
            <div className={styles['bottom-shadow']}>
              <div className={styles['backdrop']} />
              <div className={styles['shadow-content-wrapper']}>
                <img
                  src={
                    process.env.REACT_APP_GCM_BUCKET_URL +
                    '/landing-page/medallion/medallion/logo.png'
                  }
                  alt="mythic-repository"
                />
                <p className={styles['description']}>
                  The first NFT collection released by Mythic Protocol, to
                  reward early community members, and contributors. This is our
                  token of appreciation for your trust and support in us.
                </p>
                <div className={styles['button-wrapper']}>
                  <ButtonDefaultOld
                    onClick={handleCTA}
                    text="MARKETPLACE"
                    size="large"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles['bg-tiles']}
          style={{
            background: `url("${process.env.REACT_APP_GCM_BUCKET_URL}/landing-page/medallion/background-landing-page.png")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative'
          }}
        >
          <img
            src={
              process.env.REACT_APP_GCM_BUCKET_URL +
              '/landing-page/medallion/medallion/top-shadow.png'
            }
            alt="top-shadow"
            className={styles['top-shadow']}
          />
          <GoldLine />
          <div className={styles['content-container']}>
            <div className={styles['title-wrapper']}>
              <p className={styles['page-title']}>MEDALLION</p>
              <p className={styles['page-subtitle']}>
                [Operation Compass Medallion]
              </p>
            </div>
            <div className={styles['subtitle-wrapper']}>
              <p>
                This first release of Mythic Protocol Assets collection rewards
                our loyal community members and contributors. The main purpose
                of this collection is to serve as a token of appreciation for
                early believers of our project, with extensive future benefits
                (both tangible and intangible benefits) and with its expected
                value to increase over time.
              </p>
            </div>
            <div className={styles['collection']}>
              <p
                className={
                  styles['collection-caption'] +
                  ' ' +
                  styles[isAnimated ? 'hidden' : 'visible'] +
                  ' ' +
                  styles[listCollectionMedallion[imageIndex].key]
                }
              >
                {listCollectionMedallion[imageIndex].name}
              </p>
              <div className={styles['collection-list']}>
                {/* left image */}
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    className={styles['left-img']}
                    src={
                      listCollectionMedallion[indexOnImageLeft(imageIndex)]
                        .tinySrc
                    }
                    key={page}
                    custom={direction}
                    variants={medallionAnimationVariants.left}
                    initial="center"
                    animate="center"
                    exit="exit"
                    transition={animationTransitions.slow}
                  />
                </AnimatePresence>
                {/* center image */}
                <div className={styles['collection-center']}>
                  <div className={styles['next']} onClick={() => paginate(-1)}>
                    <motion.img
                      src={
                        process.env.REACT_APP_GCM_BUCKET_URL +
                        '/landing-page/medallion/icons/arrow-right.png'
                      }
                      whileHover={{ x: 20 }}
                      whileTap={{ x: 0 }}
                    />
                  </div>
                  <AnimatePresence initial={false} custom={direction}>
                    <img
                      key={'center-image'}
                      className={styles['center-img-bg']}
                      src={
                        process.env.REACT_APP_GCM_BUCKET_URL +
                        '/landing-page/medallion/collection/Group.png'
                      }
                    />
                    <motion.div
                      className={
                        styles['center-img'] +
                        ' ' +
                        styles['circle'] +
                        ' ' +
                        styles[listCollectionMedallion[imageIndex].key]
                      }
                      key={page}
                      custom={direction}
                      variants={medallionAnimationVariants.center}
                      initial="center"
                      animate="center"
                      exit="exit"
                      transition={animationTransitions.medium}
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                          paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                          paginate(-1);
                        }
                      }}
                    >
                      <div className={styles['shine-effect']}></div>
                      <img
                        src={listCollectionMedallion[imageIndex].src}
                        alt="circle-image"
                      />
                    </motion.div>
                  </AnimatePresence>
                  <div className={styles['prev']} onClick={() => paginate(1)}>
                    <motion.img
                      src={
                        process.env.REACT_APP_GCM_BUCKET_URL +
                        '/landing-page/medallion/icons/arrow-right.png'
                      }
                      whileHover={{ x: 20 }}
                      whileTap={{ x: 0 }}
                    />
                  </div>
                </div>

                {/* right image */}
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    className={styles['right-img']}
                    src={
                      listCollectionMedallion[indexOnImageRight(imageIndex)]
                        .tinySrc
                    }
                    key={page}
                    custom={direction}
                    variants={medallionAnimationVariants.right}
                    initial="center"
                    animate="center"
                    exit="exit"
                    transition={animationTransitions.medium}
                  />
                </AnimatePresence>
              </div>

              <div
                className={
                  styles['button-wrapper'] + ' ' + styles['btn-marketplace']
                }
              >
                <ButtonDefaultOld
                  onClick={imageIndex === 1 ? handleCTA : undefined}
                  text={imageIndex === 1 ? 'GO TO MARKETPLACE' : 'COMING SOON'}
                  size="large"
                  disabled={imageIndex !== 1}
                  noPadding
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <div className={styles['table-header']}>BENEFITS OF HOLDERS</div>
              <div className={styles['card-list-wrapper']}>
                <div className={styles['card-list__content-box']}>
                  <p className={styles['card-list__content-caption']}>
                    By acquiring The Medallions, you are entitled to obtain the
                    following benefits (*), in addition to the possibility of
                    earning additional perks in the future :
                  </p>

                  <div className={styles['card-list__description-box']}>
                    <p className={styles['card-list__description-title']}>
                      Benefits:
                    </p>

                    <ol className={styles['card-list__description-points']}>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Seasonal In-Game Airdrops</span>
                          <br />
                          Expect to receive regular airdrops in various aspects
                          of Mythic Protocol ecosystem, ranging from equipment,
                          skins, to special items.
                        </p>
                      </li>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Special Power-Ups</span>
                          <br />
                          Enhance your agents’ capabilities with in-game
                          power-ups! There are varying degrees of boosts on each
                          game’s runs.
                        </p>
                      </li>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Unique Visual Effects</span>
                          <br />
                          Equip special VFXs that will make your agents shine
                          and stand-out during the high- intensity dungeon
                          chases!
                        </p>
                      </li>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Exclusive Portal Accolades</span>
                          <br />
                          Claim exclusive titles and badges in Mythic Portal,
                          while unlocking varieties of additional experiences
                          during your portal journey.
                        </p>
                      </li>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Special Agent Minting Features</span>
                          <br />
                          Mint your agents with limited editions of skins,
                          costumes, and apparels provided exclusively for The
                          Medallion holders.
                        </p>
                      </li>
                      <li>
                        <p className={styles['card-list__description-caption']}>
                          <span>Dedicated Early Access</span>
                          <br />
                          Gain early access to the wide-range of Mythic Protocol
                          exhaustive product releases and future offers (**)
                        </p>
                      </li>
                      <div className={styles['card-list__notes-box']}>
                        <p>
                          (*) The benefits will differ for particular package
                          tiers (gold, silver, and bronze), with the higher tier
                          receiving higher number of perks.
                        </p>
                        <p>
                          (**) Currently granted to : Founder’s Masks (for Gold
                          Medallion holders only), Meta Mythic Objects, Ganymede
                          Charter’s Lands, and Agents.
                        </p>
                      </div>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* <div className={styles['bottom-gradient']} /> */}
    </>
  );
};

export default MedallionPromoContent;

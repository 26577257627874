export const theWardComicImages: string[] = [
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/cover.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic1.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic2.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic3.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic4.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic5.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic6.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic7.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic8.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic9.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic10.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic11.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic12.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic13.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/the-ward/comic/comic14.png`
];

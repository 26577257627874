import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './DoggyComicCarousel.module.scss';

interface DoggyComicCarouselProps {
  images: string[];
  current: number;
}

const DoggyComicCarousel: React.FC<DoggyComicCarouselProps> = memo(
  function DoggyComicCarousel({ images, current }) {
    useEffect(() => {
      // Preload all images
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    }, [images]);

    return (
      <div className={styles['Content']}>
        {images.map((image, key) => {
          return (
            <React.Fragment key={key + 1}>
              {current === key ? (
                <img
                  key={`image ${key + 1}`}
                  src={image}
                  alt={'image ' + key}
                />
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
);

DoggyComicCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired
};

export default DoggyComicCarousel;

import React from 'react';
import styles from './DoggyDaysCounter.module.scss';

const DoggyDaysCounter = () => {
  return (
    <div id="DaysCounter" className={styles['DaysCounterContainer']}>
      <div className={styles['Container']}>
        <div className={styles['DaysCounter']}>
          <div className={styles['Collective']}>
            Collectively, playtesters neutralized over 5,000,000 mythics in just
            under
          </div>
          <div className={styles['NumberOfUsers']}>
            <h1>10</h1>
            <h2>Days</h2>
          </div>
          <div className={styles['Ready']}>
            If you’re ready to see the consequences of your actions, read on
            below.
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoggyDaysCounter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qr4k6zfrKYWy0gENOKHK{width:100%;display:flex;justify-content:flex-start;align-items:center;margin:30px 0px 30px 53px;gap:60px}@media screen and (max-width: 370px){.Qr4k6zfrKYWy0gENOKHK{gap:25px}}@media screen and (max-width: 320px){.Qr4k6zfrKYWy0gENOKHK{gap:15px}}.Qr4k6zfrKYWy0gENOKHK .Ql7tWWyK03_nVnrERWEX{display:flex;flex-direction:column;gap:35px}.Qr4k6zfrKYWy0gENOKHK .Ql7tWWyK03_nVnrERWEX .YU_JR7Z0FnWCN0KDJJ1i{display:flex}.Qr4k6zfrKYWy0gENOKHK .Ql7tWWyK03_nVnrERWEX .YU_JR7Z0FnWCN0KDJJ1i .kGmHtWUW5zGJZoyRgvex{width:11px;height:11px;margin-right:5px;margin-top:1px;border:1px solid #36c692;box-shadow:0px 0px 8px rgba(54,198,146,.25)}.Qr4k6zfrKYWy0gENOKHK .Ql7tWWyK03_nVnrERWEX .YU_JR7Z0FnWCN0KDJJ1i .xg7AADLZndqYCV9PZLdI{font-weight:400}.Qr4k6zfrKYWy0gENOKHK .tuqrbxvMIejnDs9WZOfG{width:150px;height:150px;display:flex;align-items:center;justify-content:center}.MwGnpbVHLAxutv2N01eO{display:flex;flex-direction:column;row-gap:18px;justify-content:center;align-items:center;width:100%;margin:30px 0px 30px 0px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/ClassUsage/ClassUsage.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,yBAAA,CACA,QAAA,CAEA,qCARF,sBASI,QAAA,CAAA,CAGF,qCAZF,sBAaI,QAAA,CAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,kEACE,YAAA,CAEA,wFACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA,CACA,wBAAA,CACA,2CAAA,CAGF,wFACE,eAAA,CAKN,4CACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Qr4k6zfrKYWy0gENOKHK`,
	"class-usage-list": `Ql7tWWyK03_nVnrERWEX`,
	"container-legend-title": `YU_JR7Z0FnWCN0KDJJ1i`,
	"box-title": `kGmHtWUW5zGJZoyRgvex`,
	"class-usage-title": `xg7AADLZndqYCV9PZLdI`,
	"chart-box": `tuqrbxvMIejnDs9WZOfG`,
	"class-usage-empty-container": `MwGnpbVHLAxutv2N01eO`
};
export default ___CSS_LOADER_EXPORT___;

import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styles from '../Dashboard/Dashboard.module.scss';

interface NotFoundLayoutProps {
  children?: ReactNode;
}

const NotFoundLayout: React.FC<NotFoundLayoutProps> = ({ children }) => {
  return (
    <div className={styles['layout-container']}>
      <div
        style={{
          backgroundImage: `url(./assets/img/png/background-home.webp)`,
          backgroundSize: 'cover'
        }}
        className={styles['bg-box']}
      />
      <img
        className={styles['bg-mp-logo']}
        src="./assets/img/svg/mp_logo.svg"
        alt="mp-logo"
      />
      <div className={styles['content']}>{children ?? <Outlet />}</div>
    </div>
  );
};

export default NotFoundLayout;

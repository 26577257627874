import LytogramsIcon from '@src/Components/Atoms/Lytograms/LytogramsIcon';
import React from 'react';
import styles from './Lytograms.module.scss';

type ILytograms = {
  data: any;
};

const Lytograms: React.FC<ILytograms> = ({ data }) => {
  const lytoData = data ? data.data : data;

  if (!lytoData) {
    return (
      <div
        className={styles['lyto-empty-container']}
        data-testid="lytograms-container"
      >
        <p>No Data</p>
      </div>
    );
  }

  return (
    <div className={styles['wrapper']} data-testid="lytograms-container">
      <div className={styles['lyto-box']}>
        {lytoData?.length > 0 &&
          lytoData?.map((icon: any, i: number) => (
            <div className={styles['lyto-container']} key={Number(i)}>
              <LytogramsIcon counter={icon.value} src={`${i + 1}`} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Lytograms;

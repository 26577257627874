import React from 'react';

const ArrowRedirectIcon = () => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0852 12.5181L15.5532 2.32489L13.3975 4.41132L21.7737 12.5182L13.3975 20.625L15.5532 22.7114L26.0852 12.5181Z"
        fill="#C4964D"
      />
      <rect
        width="6"
        height="6"
        transform="matrix(0.718568 0.695456 -0.718568 0.695456 13.1509 8.34546)"
        fill="#C4964D"
      />
    </svg>
  );
};

export default ArrowRedirectIcon;

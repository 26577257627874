import { isTokenExpired } from '@src/Common/Utils/helper';
import { MailboxDataType } from '@src/Components/Molecules/Notification/NotficationMessage';
import { MessageEnum } from '@src/Domain/Message/Message.constant';
import { careerNameType } from '@src/Domain/Profile/Profile.type';
import { StoreContext } from '@src/Store/Store.context';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

export const useHome = () => {
  const [showProfileDropdown, setShowProfileDropdown] =
    useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showModalMessages, setShowModalMessages] = useState<boolean>(false);
  const [dataNotices, setDataNotices] = useState<any[]>([]);
  const [readLengthNotice, setReadLengthNotice] = useState<number>(0);
  const [unreadLengthNotice, setUnreadLengthNotice] = useState<number>(0);
  const [isLoadingNotice, setIsLoadingNotice] = useState<boolean>(true);
  const [mailboxData, setMailboxData] = useState<MailboxDataType[]>([]);
  const [isShowFriendList, setIsShowFriendList] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>('');
  const [showModalInductionAccess, setShowModalInductionAccess] =
    useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [totalKillQuard, setTotalKillQuard] = useState<number>(0);
  const [totalNotKillQuard, setTotalNotKillQuard] = useState<number>(0);
  const [totalKillQuardTimestamp, setTotalKillQuardTimestamp] =
    useState<string>('');
  const [totalNotKillQuardTimestamp, setTotalNotKillQuardTimestamp] =
    useState<string>('');
  const [stateUserToQuard, setStateUserToQuard] = useState<
    'netral' | 'kill-quard' | 'not-kill-quard'
  >('netral');

  const {
    isAuthenticated,
    signoutRedirect,
    removeUser,
    isLoading,
    signinRedirect,
    user
  } = useAuth();
  const { ProfileStore, MessageStore } = useContext(StoreContext);
  const [userToken, setUserToken] = useState<string>('');

  const onAccountClick = () => {
    window.location.assign(`${process.env.SSO_DOMAIN}`);
  };

  const unreadMailbox = mailboxData?.filter(
    (item: MailboxDataType) => item.readStatus === 0
  );

  const readMailbox = mailboxData?.filter(
    (item: MailboxDataType) => item.readStatus === 1
  );

  const markAsReadMessage = async (mailId: number) => {
    await axios
      .put(
        `${process.env.MYTHIC_COMMUNICATION_URL}/mailbox/read`,
        {
          accountId: ProfileStore?.adminAccountData?.sub,
          mailboxId: mailId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      )
      .then((res) => {
        MessageStore.isUpdatedMessage = res.data.data;
      });
  };

  const handleDeleteMessage = async (mailId: number) => {
    MessageStore.isLoading = true;

    await axios
      .delete(`${process.env.MYTHIC_COMMUNICATION_URL}/mailbox`, {
        data: {
          accountId: ProfileStore?.adminAccountData?.sub,
          mailboxId: mailId
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((res) => {
        MessageStore.isUpdatedMessage = res.data.data;

        if (res.data.statusCode === 'OK') {
          MessageStore.messageMethod = MessageEnum.DELETE;
        } else {
          MessageStore.messageMethod = MessageEnum.FAILED;
        }
      })
      .finally(() => {
        setTimeout(() => {
          MessageStore.isLoading = false;
          MessageStore.messageMethod = '';
        }, 1000);
      });
  };

  const markAsReadNotice = (id: number) => {
    const readStatus = localStorage.getItem('readStatusNotice');
    let notifData = [];

    if (readStatus) {
      notifData = JSON.parse(readStatus);

      if (!notifData.includes(id)) {
        notifData.push(id);
        localStorage.setItem('readStatusNotice', JSON.stringify(notifData));
      }
    } else {
      notifData = [id];
      localStorage.setItem('readStatusNotice', JSON.stringify(notifData));
    }
  };

  const updateArrayRemovedIdNotice = (id: number) => {
    MessageStore.isLoading = true;

    const removeStatus = localStorage.getItem('removedIdNotice');
    const listRemoveStatus =
      removeStatus != null ? JSON.parse(removeStatus) : [];

    if (!listRemoveStatus.includes(id)) {
      listRemoveStatus.push(id);
    }

    localStorage.setItem('removedIdNotice', JSON.stringify(listRemoveStatus));

    updateStatusNotice();

    setTimeout(() => {
      MessageStore.isLoading = false;
    }, 1000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const getLocalData = localStorage.getItem(
        `oidc.user:${process.env.SSO_DOMAIN}:${process.env.SSO_CLIENT_ID}`
      );
      if (getLocalData) {
        const profile = JSON.parse(getLocalData).profile;
        const token = JSON.parse(getLocalData).access_token;

        setUserToken(token);
        setAccountId(profile.sub);
        setUsername(profile.name);

        ProfileStore.sendUserData(token);
        ProfileStore.getAdminAccountData(profile?.sub, token);
        ProfileStore.getAchievementData(profile?.sub);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && userToken) {
      ProfileStore.getLevelCareer(careerNameType.Archivist, userToken);
    }
  }, [isLoading, userToken]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchDataNotices = async () => {
        try {
          const fetchData = await axios.get(
            `${process.env.MYTHIC_COMMUNICATION_URL}/notice`
          );
          const { data } = fetchData;
          setDataNotices(data.data);
          const result = data.data;
          if (result) {
            updateStatusNotice();
          }
          setIsLoadingNotice(false);
        } catch (err: any) {
          setIsLoadingNotice(false);
        }
      };

      fetchDataNotices();
    }
  }, [isAuthenticated]);

  const checkTokenExpired = isTokenExpired(userToken);

  useEffect(() => {
    // if (checkTokenExpired && userToken !== '') {
    //   signoutRedirect({
    //     extraQueryParams: {
    //       on_back_url: `https://${window.location.host}`
    //     }
    //   });
    //   removeUser();
    // }
  }, [checkTokenExpired, userToken]);

  useEffect(() => {
    if (isAuthenticated && userToken) {
      axios
        .get(
          `${process.env.MYTHIC_COMMUNICATION_URL}/mailbox?clientId=${process.env.SSO_CLIENT_ID}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        .then((res) => {
          setMailboxData(res.data.data.mailList);
          MessageStore.isLoading = false;
          MessageStore.isUpdatedMessage = false;
        });
    }
  }, [
    isAuthenticated,
    userToken,
    MessageStore.isUpdatedMessage,
    MessageStore.messageMethod
  ]);

  const getStatusNotice = (id: number, type: number) => {
    const removeStatus = localStorage.getItem('removedIdNotice');
    const readStatus = localStorage.getItem('readStatusNotice');
    const listIdRead = readStatus != null ? JSON.parse(readStatus) : [];
    const listIdRemoved = removeStatus != null ? JSON.parse(removeStatus) : [];
    const listId = listIdRead.concat(
      listIdRemoved.filter((item: any) => listIdRead.indexOf(item) < 0)
    );

    return type == 1
      ? !listId.includes(id)
      : listIdRead.includes(id) && !listIdRemoved.includes(id);
  };

  const updateStatusNotice = () => {
    if (!isLoadingNotice) {
      const removeStatus = localStorage.getItem('removedIdNotice');
      const readStatus = localStorage.getItem('readStatusNotice');
      const listRead = readStatus != null ? JSON.parse(readStatus) : [];
      const listRemoved = removeStatus != null ? JSON.parse(removeStatus) : [];
      const listReadAndRemoved = listRead.concat(
        listRemoved.filter((item: number) => listRead.indexOf(item) < 0)
      );

      const totalRead = listRead?.filter(
        (item: number) => !listRemoved.includes(item)
      ).length;
      const totalUnread = dataNotices.length - listReadAndRemoved.length;
      setReadLengthNotice(totalRead);
      setUnreadLengthNotice(totalUnread);
    }
  };

  const signInHandle = () => {
    if (!isAuthenticated) {
      signinRedirect();

      localStorage.setItem('sign', window.location.pathname);
    }
  };

  const handleFriendList = () => {
    setIsShowFriendList(!isShowFriendList);
  };

  const handleSignOut = async () => {
    await removeUser();
    localStorage.clear();
    signoutRedirect({ id_token_hint: user?.id_token });
  };

  const getKeystoneLastChoice = async () => {
    try {
      const fetch = await axios.get(
        `${process.env.REACT_APP_STATISTICS_URL}/player/collect?AccountId=${accountId}&PresetName=${process.env.KEYSTONE_PRESET_NAME}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      const { data } = fetch;
      const result = data.data[0].data as {
        createDate: string;
        name: string;
        updateDate: string;
        value: number;
      }[];

      const voteKillGuard = result.find(
        (item) => item.name === 'v0_4_vote_kill_guard'
      );
      const voteNotKillGuard = result.find(
        (item) => item.name === 'v0_4_vote_not_kill_guard'
      );

      // check user first play in game
      if (
        voteKillGuard &&
        voteKillGuard.value === 0 &&
        voteNotKillGuard &&
        voteNotKillGuard.value === 0
      ) {
        return;
      }

      result.forEach((item) => {
        if (item.name === 'v0_4_vote_kill_guard') {
          setTotalKillQuardTimestamp(item.updateDate);
          setStateUserToQuard('kill-quard');
        } else if (item.name === 'v0_4_vote_not_kill_guard') {
          setTotalNotKillQuardTimestamp(item.updateDate);
          setStateUserToQuard('not-kill-quard');
        }
      });
    } catch (err) {
      return;
    }
  };

  const fetchKeystoneBarValue = async () => {
    try {
      const fetch = await axios.get(
        `${process.env.REACT_APP_STATISTICS_URL}/players/aggr/collect?PresetName=${process.env.KEYSTONE_PRESET_NAME_BAR_VALUE}&Operator=SUM`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      const { data } = fetch;
      const result = data.data.data as { eventName: string; value: number }[];
      if (result) {
        result.forEach((item) => {
          if (item.eventName === 'v0_4_vote_kill_guard') {
            setTotalKillQuard(item.value);
          }
          if (item.eventName === 'v0_4_vote_not_kill_guard') {
            setTotalNotKillQuard(item.value);
          }
        });
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (isAuthenticated && userToken && accountId) {
      getKeystoneLastChoice();
      fetchKeystoneBarValue();
    }
  }, [isAuthenticated, userToken, accountId]);

  return {
    isAuthenticated,
    signInHandle,
    ProfileStore,
    setShowNotification,
    setShowModalMessages,
    setShowProfileDropdown,
    showProfileDropdown,
    userToken,
    showNotification,
    dataNotices,
    unreadLengthNotice,
    readLengthNotice,
    updateStatusNotice,
    getStatusNotice,
    markAsReadNotice,
    updateArrayRemovedIdNotice,
    showModalMessages,
    mailboxData,
    unreadMailbox,
    readMailbox,
    markAsReadMessage,
    handleDeleteMessage,
    isShowFriendList,
    handleFriendList,
    onAccountClick,
    handleSignOut,
    accountId,
    showModalInductionAccess,
    setShowModalInductionAccess,
    username,
    totalKillQuard,
    totalNotKillQuard,
    totalKillQuardTimestamp,
    totalNotKillQuardTimestamp,
    stateUserToQuard,
    isLoadingAuth: isLoading
  };
};

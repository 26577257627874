import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import React from 'react';

const FormSeparator = () => {
  const { width } = useWindowDimensions();

  const getLineWidth = () => {
    if (width <= 1366 && width >= 1280) {
      return 503;
    } else if (width <= 425) {
      return width - 25;
    } else {
      return 553;
    }
  };

  return (
    <div>
      <svg
        width={getLineWidth()}
        height="14"
        viewBox="0 0 553 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="form-separator"
      >
        <path d="M17.7603 6.67291H534.76" stroke="#50EAEA" strokeWidth="0.5" />
        <path
          d="M3.76025 6.67291L10.7603 6.67291"
          stroke="#50EAEA"
          strokeWidth="0.5"
        />
        <path
          d="M10.7605 6.67318L14.4985 10.4112L18.2365 6.67318L14.4985 2.93517L10.7605 6.67318Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M0.760037 6.6727L2.26025 8.17291L3.76047 6.6727L2.26025 5.17248L0.760037 6.6727Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M7.26025 3.67487L7.26025 9.67487"
          stroke="#50EAEA"
          strokeWidth="0.5"
        />
        <path
          d="M5.76004 2.1727L7.26025 3.67291L8.76047 2.1727L7.26025 0.672478L5.76004 2.1727Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M5.76004 11.1746L7.26025 12.6749L8.76047 11.1746L7.26025 9.67443L5.76004 11.1746Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M549.24 6.67291L542.24 6.67291"
          stroke="#50EAEA"
          strokeWidth="0.5"
        />
        <path
          d="M542.24 6.67318L538.501 10.4112L534.763 6.67318L538.501 2.93517L542.24 6.67318Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M552.24 6.6727L550.74 8.17291L549.24 6.6727L550.74 5.17248L552.24 6.6727Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path d="M545.74 3.67487V9.67487" stroke="#50EAEA" strokeWidth="0.5" />
        <path
          d="M547.24 2.1727L545.74 3.67291L544.24 2.1727L545.74 0.672478L547.24 2.1727Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M547.24 11.1746L545.74 12.6749L544.24 11.1746L545.74 9.67443L547.24 11.1746Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export default FormSeparator;

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { initGA, logPageView } from '@src/Domain/analytic';
import { withStore } from '@src/Store/Store.context';

import Navbar from '@src/Components/Molecules/Navbar/Navbar';

import MaintenancePage from '../MaintenancePage/MaintenancePage';
import useMaintenance from '../MaintenancePage/useMaintenance';

import { ModalFaq } from '@jupiter/react-common-component';
import styles from './Dashboard.module.scss';

const DashboardLayout = observer(() => {
  const { pathname } = useLocation();
  const { getMaintainStatus, isMaintenance } = useMaintenance();

  const isRetainer =
    pathname !== '/retaineracademy'
      ? 'transparent url(./assets/img/png/background-home.webp)'
      : 'linear-gradient(180deg, #03182c 70.51%, #023940 92.58%)';

  useEffect(() => {
    if (!(window as unknown as { [x: string]: string }).GA_INITIALIZED) {
      initGA();
      (window as unknown as { [x: string]: string | boolean }).GA_INITIALIZED =
        true;
    }
    logPageView();

    const element = document.getElementById('bg-dashboard');
    if (element) {
      element.style.backgroundSize = 'cover';
    }
  }, []);

  useEffect(() => {
    getMaintainStatus();
  }, []);

  return (
    <div
      className={styles['layout-container']}
      data-testid="layout-container-dashboard"
    >
      <div>
        <div
          style={{
            background: isRetainer
          }}
          className={styles['bg-box']}
          id="bg-dashboard"
        ></div>
        {isMaintenance && <MaintenancePage />}
        {!isMaintenance && (
          <>
            <Navbar />
            <div className={styles['content']}>
              <Outlet />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default withStore(DashboardLayout);

import React from 'react';

const ChecklistIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="checklist-icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.67487C5.925 1.67487 1 6.59987 1 12.6749C1 18.7499 5.925 23.6749 12 23.6749C18.075 23.6749 23 18.7499 23 12.6749C23 6.59987 18.075 1.67487 12 1.67487ZM16.768 10.8149C16.8558 10.7145 16.9226 10.5976 16.9646 10.471C17.0065 10.3445 17.0227 10.2108 17.0123 10.0779C17.0018 9.94492 16.9648 9.81543 16.9036 9.69699C16.8423 9.57856 16.758 9.47357 16.6555 9.38821C16.5531 9.30284 16.4346 9.23883 16.3071 9.19993C16.1796 9.16103 16.0455 9.14803 15.9129 9.16169C15.7802 9.17536 15.6517 9.21541 15.5347 9.2795C15.4178 9.34359 15.3149 9.43041 15.232 9.53487L10.932 14.6939L8.707 12.4679C8.5184 12.2857 8.2658 12.1849 8.0036 12.1872C7.7414 12.1895 7.49059 12.2946 7.30518 12.48C7.11977 12.6655 7.0146 12.9163 7.01233 13.1785C7.01005 13.4407 7.11084 13.6933 7.293 13.8819L10.293 16.8819C10.3913 16.9801 10.5089 17.0567 10.6384 17.1069C10.7679 17.1571 10.9065 17.1798 11.0453 17.1735C11.184 17.1672 11.32 17.1321 11.4444 17.0703C11.5688 17.0086 11.6791 16.9216 11.768 16.8149L16.768 10.8149Z"
          fill="#50EAEA"
        />
      </svg>
    </div>
  );
};

export default ChecklistIcon;

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { oidcConfig } from './Common/Constants/Auth.OidcConfig';
import Pages from './Pages';
import { StoreProvider } from './Store/Store.context';
import './index.scss';

import '@jupiter/react-common-component/dist/global-styles/index.scss';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <StoreProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/portal">
            <Suspense fallback={<></>}>
              <Pages />
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </StoreProvider>
    </AuthProvider>
  );
};
const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

export default root.render(<App />);

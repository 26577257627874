import React from 'react';

interface IArrowDown {
  opacity: number;
}

const ArrowDown: React.FC<IArrowDown> = ({ opacity = 1 }) => {
  return (
    <div
      style={{ marginTop: 4, marginBottom: 8, opacity }}
      data-testid="arrow-div"
    >
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="arrow-svg"
      >
        <path
          d="M7 7.67481L0.504809 0.174806L13.4952 0.174805L7 7.67481Z"
          fill="#50EAEA"
        />
      </svg>
    </div>
  );
};

export default ArrowDown;

import axios from 'axios';
import { makeAutoObservable } from 'mobx';

type Tdata = {
  value: number;
  max: number;
};

type TStatus = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  message: string;
};

export interface IThreatIndexStore {
  dataThreatIndex: Tdata;
  status: TStatus;
  getThreatIndex: () => Promise<void>;
}

export class ThreatIndexStore implements IThreatIndexStore {
  dataThreatIndex: Tdata = {
    value: 0,
    max: 0
  };
  status: TStatus = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  async getThreatIndex() {
    this.status.isLoading = true;
    await axios
      .get(`${process.env.MYTHIC_COMMUNICATION_URL}/arg/threat-index`)
      .then((result) => {
        const { maxValue, value } = result.data.data;
        this.dataThreatIndex = {
          value: value,
          max: maxValue
        };
        this.status = {
          isLoading: false,
          isError: false,
          isSuccess: true,
          message: ''
        };
      })
      .catch((e) => {
        this.status = {
          isLoading: false,
          isError: true,
          isSuccess: false,
          message: JSON.stringify(e)
        };
      });
  }
}

export const ThreatIndexStoreDefault: IThreatIndexStore = {
  dataThreatIndex: {
    value: 0,
    max: 0
  } as Tdata,
  status: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: ''
  } as TStatus,
  getThreatIndex: function (): Promise<void> {
    throw new Error('Function not implemented.');
  }
};

import { StoreContext } from '@src/Store/Store.context';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useState } from 'react';

const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch'
};

interface Props {
  url: string;
  method?: keyof typeof methods;
  credentials?: string;
  idempotencyId: string;
}

const useLazyFetch = <ResultType>({
  url,
  method = 'GET',
  credentials,
  idempotencyId
}: Props) => {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResultType>();
  const [arrData] = useState<{ key: string; value: string }[]>();
  const [error, setError] = useState<AxiosResponse>();

  const onAction = useCallback(
    async (
      { params, ...iparam }: { path?: string; params?: unknown } = {},
      cb?: (success?: AxiosResponse, error?: AxiosResponse) => void
    ) => {
      setLoading(true);
      try {
        const bodyData = prepareBodyData(params);
        const headers = await prepareHeaders(idempotencyId);

        const newUrl = iparam.path !== undefined ? `${url}${iparam.path}` : url;
        const response = await makeRequest({
          url: newUrl,
          method,
          headers,
          bodyData,
          credentials
        });

        setLoading(false);
        setData(response.data as ResultType);
        if (cb) {
          cb(response, undefined);
        }
      } catch (err: any) {
        handleError(err, cb);
      }
    },
    [credentials, method, url]
  );

  const prepareBodyData = (params: unknown) => {
    if (!params || !(params instanceof Object)) {
      return params;
    }

    const isFormData = Object.values(params).some(
      (value) => value instanceof File
    );

    if (isFormData) {
      const formd = new FormData();
      for (const [key, value] of Object.entries(params)) {
        if (value instanceof File) {
          formd.append(key, value, value.name);
        } else {
          formd.append(key, value as string);
        }
      }
      return formd;
    } else {
      return params;
    }
  };

  const prepareHeaders = async (idempotencyId: string) => {
    let headers = {};

    if (store.UserStore.token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${store.UserStore.token}`
      };
    }

    if (idempotencyId) {
      const result = await store.IdempotencyKeyStore.getIdempotencyKeyById(
        idempotencyId
      );
      headers = { ...headers, 'x-idem-key': result };
    }

    return headers;
  };

  const makeRequest = async ({
    url,
    method,
    headers,
    bodyData,
    credentials
  }: {
    url: string;
    method: keyof typeof methods;
    headers: any;
    bodyData: any;
    credentials?: string;
  }) => {
    const config = {
      url,
      method,
      headers,
      ...(method === 'GET' ? { params: bodyData } : { data: bodyData }),
      ...(credentials ? { credentials } : {})
    };
    return await axios(config);
  };

  const handleError = (
    err: AxiosError,
    cb?: (success?: AxiosResponse, error?: AxiosResponse) => void
  ) => {
    setError(err.response);
    setLoading(false);
    if (cb) {
      cb(undefined, err.response);
    }
  };

  return [onAction, { loading, data, arrData, error }] as const;
};

export default useLazyFetch;

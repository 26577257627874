import { ProgressBar } from '@jupiter/react-common-component';
import AvatarCard from '@src/Components/Atoms/AvatarItem/AvatarCard/AvatarCard';
import LevelBadge from '@src/Components/Atoms/LevelBadge/LevelBadge';
import { LevelProfileDataType } from '@src/Domain/Profile/Profile.type';
import numeral from 'numeral';
import React from 'react';
import styles from './ProfileProgress.module.scss';

interface IProfileProgress {
  levelProfileData: LevelProfileDataType;
  username: string;
  imgProfile: string;
  isLocked: boolean;
  name: string;
}

const ProfileProgress: React.FC<IProfileProgress> = ({
  levelProfileData,
  username,
  imgProfile,
  isLocked,
  name
}) => {
  return (
    <div
      className={styles['header-profile-progress']}
      data-testid="profile-progress"
    >
      <div className={styles['profile-card']}>
        <AvatarCard
          name={name}
          status={'online'}
          hideStatusText
          imgProfile={imgProfile}
          username={username}
        />
      </div>
      <div className={styles['level-number']}>
        <div className={styles['level-number-badge']}>
          <LevelBadge level={levelProfileData?.level || 0} isLock={isLocked} />
        </div>
        {!isLocked && (
          <div className={styles['container-progress-bar-exp']}>
            <div style={{ color: '#C4964D' }} className={styles['exp-text']}>
              XP {numeral(levelProfileData?.totalExp).format('0,0')}
            </div>
            <ProgressBar
              colorBar="#C4964D"
              progress={
                (levelProfileData?.totalExp /
                  (levelProfileData?.totalExp +
                    levelProfileData?.rangeToNextLevel)) *
                100
              }
            />
            <div>
              {numeral(levelProfileData?.rangeToNextLevel).format('0,0')} XP to
              reach level {levelProfileData?.level + 1}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileProgress;

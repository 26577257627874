// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LOB3DnQ9m_ioUtjGWTgA{width:100%;display:flex;justify-content:center;align-items:center;box-sizing:border-box;flex-direction:column;gap:5px}@media screen and (max-width: 1279px){.LOB3DnQ9m_ioUtjGWTgA{padding:20px 0px}}.LOB3DnQ9m_ioUtjGWTgA .NCqOtdJH2ce3QhZOCgOl{font-family:var(--fonts-barlow);font-style:normal;font-weight:400;font-size:10px;line-height:11px;text-align:center;color:#e4e4e4}.LOB3DnQ9m_ioUtjGWTgA .kIKsQJozpr1DCTHc1lsf{font-family:"Barlow",sans-serif;font-style:normal;font-weight:600;font-size:12px;line-height:14px;display:flex;gap:18px;text-decoration:underline;cursor:pointer;margin-top:5px;padding-bottom:10px}.LOB3DnQ9m_ioUtjGWTgA .kIKsQJozpr1DCTHc1lsf a{color:var(--accent-gold-300)}`, "",{"version":3,"sources":["webpack://./src/Components/Layouts/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,qBAAA,CACA,OAAA,CAEA,sCATF,sBAUI,gBAAA,CAAA,CAGF,4CACE,+BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA,CAKF,4CACE,+BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CACA,QAAA,CACA,yBAAA,CACA,cAAA,CACA,cAAA,CACA,mBAAA,CAEA,8CACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-wrapper": `LOB3DnQ9m_ioUtjGWTgA`,
	"footer-text": `NCqOtdJH2ce3QhZOCgOl`,
	"additional-info": `kIKsQJozpr1DCTHc1lsf`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RSPBeLp6YTBq877CCTZw{display:flex;align-items:center;justify-content:center;margin:31px 15px 62px;column-gap:48px}@media screen and (max-width: 1200px){.RSPBeLp6YTBq877CCTZw{flex-direction:column;row-gap:60px;margin:31px 15px}}.SIF0INRh6Wj0yhFTWHga{padding-left:23px;padding-bottom:30px}.SIF0INRh6Wj0yhFTWHga div svg{width:100%;height:100%}.IysppgNkqRS0kVI3Q9Z_,.RVCYk7tY9TavHpvh9a8G{position:relative}.Lp41s0A40y1nwMVsfi6G{left:-50%;position:absolute;top:0;bottom:0;margin:auto 0}@media screen and (max-width: 1600px){.Lp41s0A40y1nwMVsfi6G{left:-30%}}@media screen and (max-width: 1280px){.Lp41s0A40y1nwMVsfi6G{display:none}}.oC0gFAzYyWCAAZaZTZOa{right:-50%;position:absolute;top:0;bottom:0;margin:auto 0}@media screen and (max-width: 1600px){.oC0gFAzYyWCAAZaZTZOa{right:-30%}}@media screen and (max-width: 1280px){.oC0gFAzYyWCAAZaZTZOa{display:none}}.XLwoR_iFtyzPQg4b3dr3{position:relative}._0MnafGLqUdEr9eCfOE0{position:absolute;top:0;left:0;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/Pages/Retainer/Retainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,eAAA,CAEA,sCAPF,sBAQI,qBAAA,CACA,YAAA,CACA,gBAAA,CAAA,CAIJ,sBACE,iBAAA,CACA,mBAAA,CACA,8BACE,UAAA,CACA,WAAA,CAIJ,4CAEE,iBAAA,CAGF,sBACE,SAAA,CACA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,aAAA,CAEA,sCAPF,sBAQI,SAAA,CAAA,CAGF,sCAXF,sBAYI,YAAA,CAAA,CAGJ,sBACE,UAAA,CACA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,aAAA,CAEA,sCAPF,sBAQI,UAAA,CAAA,CAGF,sCAXF,sBAYI,YAAA,CAAA,CAGJ,sBACE,iBAAA,CAEF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-content__container": `RSPBeLp6YTBq877CCTZw`,
	"home-separator": `SIF0INRh6Wj0yhFTWHga`,
	"home-background__left": `IysppgNkqRS0kVI3Q9Z_`,
	"home-background__right": `RVCYk7tY9TavHpvh9a8G`,
	"bgdot__left": `Lp41s0A40y1nwMVsfi6G`,
	"bgdot__right": `oC0gFAzYyWCAAZaZTZOa`,
	"worldBg": `XLwoR_iFtyzPQg4b3dr3`,
	"bgnode": `_0MnafGLqUdEr9eCfOE0`
};
export default ___CSS_LOADER_EXPORT___;

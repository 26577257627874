import React from 'react';
import { LockIcon } from '../Button/SubmitButton/SubmitButton.constant';
import styles from './LevelBadge.module.scss';

const LevelBadge: React.FC<{ level?: string | number; isLock?: boolean }> = ({
  level = 0,
  isLock
}) => {
  return (
    <div className={styles['container-level-badge']}>
      <div className={styles['level-number']}>
        {isLock ? (
          <img id="lock-icon" src={LockIcon} style={{ height: 20 }} />
        ) : (
          <>{level}</>
        )}
      </div>
      <img
        src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/level_badge.svg`}
      />
    </div>
  );
};

export default LevelBadge;

import React, { useEffect, useRef, useState } from 'react';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import styles from './AvatarCard.module.scss';
import { AvatarCardProps } from './AvatarCard.type';

const AvatarCard: React.FC<AvatarCardProps> = ({
  name,
  status,
  imgProfile,
  isNotUser = false,
  isAddFriend,
  hideStatusText = false,
  username
}) => {
  const [showCardSetting, setShowCardSetting] = useState<boolean>(false);
  const dropdownCardButtonRef = useRef<HTMLDivElement>(null);

  const handleShowCardSetting = () => setShowCardSetting(!showCardSetting);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownCardButtonRef.current &&
        !dropdownCardButtonRef.current.contains(event.target as Node)
      ) {
        setShowCardSetting(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownCardButtonRef]);

  return (
    <div
      className={
        styles['container-friendlist-card'] +
        ' ' +
        (showCardSetting ? styles['card-background-active'] : '')
      }
    >
      <div className={styles['wrapper-profile']}>
        <div className={styles['wrapper-img-user']}>
          <img
            className={styles[!isNotUser ? 'img-user' : 'img-friend']}
            src={imgProfile ?? `./assets/img/png/image_default_new.png`}
          />
          <ActivityIndicator isNotUser={isNotUser} status={status} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className={styles[!isNotUser ? 'name-text' : 'friend-name-text']}
          >
            {name ?? '-'}
          </div>
          <div className={styles['username-text']}>{username}</div>
          {!hideStatusText && (
            <div
              className={
                styles[!isNotUser ? 'user-status-text' : 'friend-status-text']
              }
            >
              {status}
            </div>
          )}
        </div>
      </div>
      {isAddFriend && (
        <div
          style={{
            color: 'white',
            fontSize: 24,
            fontWeight: 600,
            cursor: 'pointer'
          }}
        >
          +
        </div>
      )}
      {isNotUser && (
        <div
          ref={dropdownCardButtonRef}
          data-testid="dropdown-card-list"
          id="dropdown-card-list"
          className={
            styles['dropdown-card-list'] +
            ' ' +
            (showCardSetting ? styles['show-card-setting'] : '')
          }
          onClick={handleShowCardSetting}
          onKeyDown={() => {
            return;
          }}
        >
          <img
            alt="arrow-button"
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/keyboard_arrow.svg`}
          />
        </div>
      )}
      {isNotUser && showCardSetting && (
        <div className={styles['card-setting']}>
          <div>View Account</div>
          <div>Remove</div>
        </div>
      )}
    </div>
  );
};

export default AvatarCard;

import React, { useState } from 'react';
import styles from './CardSectionWrapperSmall.module.scss';
import ArrowRedirectIcon from '../../ProfileLiveTiles/ArrowRedirectIcon';
import { OpenInNewIcon } from '@src/Components/Atoms/Icons/OpenInNewIcon/OpenInNewIcon';

type ICardSectionWrapperSmall = {
  title?: string;
  description?: string;
  type?:
    | 'riftstorm'
    | 'xpsr'
    | 'repository'
    | 'assignment'
    | 'inventory'
    | 'inventory-box';
  isLocked?: boolean;
  withAlert?: boolean;
  onClick?: () => void;
};

const CardSectionWrapperSmall: React.FC<ICardSectionWrapperSmall> = ({
  title = 'RIFTSTORM',
  type = 'assignment',
  isLocked,
  withAlert = false,
  onClick
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <div
      className={`${styles['container']} ${
        !isLocked && isHover && styles['hovered']
      } ${type === 'assignment' && styles['assignment']} ${
        isLocked && styles['locked']
      }`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        !isLocked && onClick && onClick();
      }}
    >
      <div
        className={`${styles['image-wrapper']} ${isLocked && styles['locked']}`}
      >
        <img src={`./assets/img/svg/card-section/${type}.svg`} alt="icon" />
        <div
          className={`${styles['text-wrapper']} ${
            isLocked && styles['locked']
          }`}
        >
          <div
            className={`${styles['title']} ${
              withAlert && styles['with-alert']
            }`}
          >
            {title}
          </div>
        </div>
      </div>

      <div className={styles['arrow-wrapper']}>
        {isLocked ? (
          <img src="./assets/img/svg/lock-icon.svg" alt="lock" />
        ) : isHover ? (
          <ArrowRedirectIcon />
        ) : (
          <OpenInNewIcon />
        )}
      </div>
    </div>
  );
};

export default CardSectionWrapperSmall;

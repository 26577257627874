// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fpERW1igQbxST7b7butX{position:fixed;top:66px;left:50%;transform:translateX(-50%);z-index:9999}.fpERW1igQbxST7b7butX.UyGo0Ml2LqGCZayRiPd2{visibility:visible;opacity:1;transition:visibility 0s,opacity .5s linear}.fpERW1igQbxST7b7butX.DEzGFq0pEGNLjbVCJ6Bw{visibility:hidden;opacity:0;transition:visibility 0s,opacity .5s linear}.fpERW1igQbxST7b7butX>div{min-width:356px;width:auto;height:auto;padding:10px 14px 10px 24px;box-sizing:border-box;border-left:3px solid var(--secondary-red-300);background:var(--neutral-black-300);margin-top:8px}.fpERW1igQbxST7b7butX>div>p{color:var(--text-white);font-family:var(--fonts-normal);font-size:16px;font-style:normal;font-weight:400;line-height:24px}.fpERW1igQbxST7b7butX>div>p span{color:var(--text-gold)}`, "",{"version":3,"sources":["webpack://./src/Components/Organisms/ModalDailyCheckin/Contents/PopUpClaimed/PopUpClaimed.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,QAAA,CACA,QAAA,CACA,0BAAA,CACA,YAAA,CAGA,2CACI,kBAAA,CACA,SAAA,CACA,2CAAA,CAGJ,2CACI,iBAAA,CACA,SAAA,CACA,2CAAA,CAIJ,0BACI,eAAA,CACA,UAAA,CACA,WAAA,CACA,2BAAA,CACA,qBAAA,CACA,8CAAA,CAEA,mCAAA,CACA,cAAA,CAEA,4BACI,uBAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,iCACI,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `fpERW1igQbxST7b7butX`,
	"show": `UyGo0Ml2LqGCZayRiPd2`,
	"hide": `DEzGFq0pEGNLjbVCJ6Bw`
};
export default ___CSS_LOADER_EXPORT___;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const startDateEnv = process.env.DAILY_CHECK_IN_START_PERIOD;
const endDateEnv = process.env.DAILY_CHECK_IN_END_PERIOD;

const startDate = dayjs(Number(startDateEnv) * 1000);
const endDate = dayjs(Number(endDateEnv) * 1000);

const shouldHideDailyModal = () => {
  const currentDate = dayjs();
  return currentDate.isBefore(startDate) || currentDate.isAfter(endDate);
};

export const PERIOD = `${startDate.format('DD')} - ${endDate.format(
  'DD MMMM YYYY'
)}`;
export const UTC = 'UTC-8';
export const TIME = `19:00`;
export const modalDailyHidden = shouldHideDailyModal();

import { ListCountryType } from '@src/Pages/FullScreenGlobe/Globe/Globe.type';
import { makeAutoObservable } from 'mobx';

export interface IGlobeStore {
  selectedCountry: ListCountryType;
  handleSelectCountry(value: any): void;
  isGlobeVisible: boolean;
  isLoading: boolean;
  errorResponse: any;
}

export class GlobeStore implements IGlobeStore {
  selectedCountry = {} as ListCountryType;
  isGlobeVisible = false;
  isLoading = true;
  errorResponse: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  handleSelectCountry(value: any) {
    this.selectedCountry = value;
  }
}

export const GlobeStoreDefault: IGlobeStore = {
  selectedCountry: {} as ListCountryType,
  handleSelectCountry: function (): void {
    throw new Error('Function not implemented.');
  },
  isGlobeVisible: false,
  isLoading: false,
  errorResponse: undefined
};

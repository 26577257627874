import React, { useState } from 'react';
import styles from './button.module.scss';
interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  colorBtn?: string;
  colorFont?: string;
  onClick?: () => void;
}

const Button = ({
  children = 'Click me!',
  disabled = false,
  colorBtn = '#F0A737',
  colorFont = '#000',
  onClick
}: ButtonProps) => {
  const [hover, setHover] = useState(false);
  const styleDisabled = '1px solid #1E2D4E';
  const hoveredBackground = hover ? '#FFA737' : colorBtn;
  const hoveredColor = hover ? colorBtn : colorFont;

  return (
    <div className={styles['_btn']}>
      <div>
        <div
          className={`${styles['_btn-borders']} ${
            disabled ? styles['disabled'] : ''
          }`}
          style={{
            border: disabled ? styleDisabled : `1px solid ${colorBtn}`
          }}
        >
          <div
            className={styles['_btn-elbrows']}
            style={{
              borderRight: disabled ? styleDisabled : `1px solid ${colorBtn}`
            }}
          />
          <div
            className={`${styles['_btn-contents']} ${
              disabled ? styles['disabled'] : styles['_btn-hover']
            }`}
            style={{
              border: disabled ? styleDisabled : `1px solid ${colorBtn}`,
              background: disabled ? '#1E2D4E' : hoveredBackground,
              color: disabled ? '#FFF' : hoveredColor
            }}
            onMouseEnter={() => !disabled && setHover(true)}
            onMouseLeave={() => !disabled && setHover(false)}
            onClick={onClick}
            onKeyDown={() => {
              return;
            }}
          >
            {children}
            <div
              className={styles['_btn-elbrows']}
              style={{
                borderRight: disabled ? styleDisabled : `1px solid ${colorBtn}`,
                top: '-3px',
                left: '-3px'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Button;

import { makeAutoObservable } from 'mobx';

export interface IHelperStore {
  handleLoading: (key: string, timeThreshold?: number) => void;
  isLoading: (key: string) => boolean;
}

export class HelperStore implements IHelperStore {
  private readonly loadingStates = new Map<string, boolean>();

  constructor() {
    makeAutoObservable(this);
  }

  // Toggle loading for a specific context by key
  handleLoading(key: string, timeThreshold = 2000) {
    const currentState = this.loadingStates.get(key) || false;
    this.loadingStates.set(key, !currentState);

    const timeoutId = setTimeout(() => {
      this.loadingStates.set(key, currentState);
    }, timeThreshold);

    return () => clearTimeout(timeoutId);
  }

  // Check if a specific key is loading
  isLoading(key: string): boolean {
    return this.loadingStates.get(key) || false;
  }
}

export const HelperStoreDefault: IHelperStore = {
  handleLoading: function (): void {
    throw new Error('Function not implemented.');
  },
  isLoading: function (key: string): boolean {
    throw new Error(key + 'Function not implemented.');
  }
};

import React from 'react';

const SmallLineSeparator = () => {
  return (
    <div style={{ transform: 'rotate(90deg)' }}>
      <svg
        width="9"
        height="69"
        viewBox="0 0 9 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="small-line-separator"
      >
        <path
          d="M4.63058 60.4368L4.63059 7.43677"
          stroke="#50EAEA"
          strokeWidth="0.5"
        />
        <path
          d="M4.63086 67.6748L8.36887 63.9368L4.63085 60.1988L0.892845 63.9368L4.63086 67.6748Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.36914 7.91306L8.10715 4.17505L4.36914 0.437038L0.631127 4.17505L4.36914 7.91306Z"
          stroke="#50EAEA"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export default SmallLineSeparator;

import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { TypeUserPointData } from './Leveltype';

const default_user_Point = {
  pointId: 0,
  pointName: '',
  totalPoints: 0,
  level: 0,
  currentLevelPoint: 0,
  requiredPointToNextLevel: 0
};

export interface ILevelStore {
  userPointData: TypeUserPointData;
  accountLevel: number;
  errorInfo: any;
  getLevelData: (token: string) => Promise<void>;
}

export class LevelStore implements ILevelStore {
  userPointData: TypeUserPointData = default_user_Point;
  accountLevel = 0;
  errorInfo = {} as any;

  constructor() {
    makeAutoObservable(this);
  }

  async getLevelData(token: string) {
    await axios
      .get(`${process.env.URL_VALIDATION_SERVICES}/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const { data } = response as any;
        const results = data.data as TypeUserPointData[];

        const filterResults = results.filter(
          (result) => result.pointName.toLowerCase() === 'clearance score'
        );
        const accountsLevel = results.filter(
          (result) => result.pointName.toLowerCase() === 'account'
        );

        this.userPointData = {
          pointId: filterResults[0].pointId,
          pointName: filterResults[0].pointName,
          totalPoints: filterResults[0].totalPoints,
          level: filterResults[0].level,
          currentLevelPoint: filterResults[0].currentLevelPoint,
          requiredPointToNextLevel: filterResults[0].requiredPointToNextLevel
        };
        this.accountLevel = accountsLevel[0].level;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }
}

export const LevelStoreDefault: ILevelStore = {
  userPointData: default_user_Point as TypeUserPointData,
  accountLevel: 0,
  errorInfo: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLevelData: function (token: string): Promise<void> {
    throw new Error('Function not implemented.');
  }
};

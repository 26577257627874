import React from 'react';
import styles from './SectionLevelClr.module.scss';
import { GeneralFunctionIcon } from '../Icons/GeneralFunctionIcon/GeneralFunctionIcon';

type ISectionLevelClr = {
  level?: number;
  requiredPointToNextLevel?: number;
};

const SectionLevelClr: React.FC<ISectionLevelClr> = ({
  level = 0,
  requiredPointToNextLevel = 0
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['left-content']}>
        CLR Level {level}
        <div className={styles['icon']} data-tooltip-id="my-tooltip-clr-level">
          <GeneralFunctionIcon />
        </div>
      </div>
      {requiredPointToNextLevel !== 0 && (
        <div className={styles['right-content']}>
          {requiredPointToNextLevel} CLR to <span>Level {level + 1}</span>
        </div>
      )}
    </div>
  );
};

export default SectionLevelClr;

import React from 'react';

export const handleIconNotification = (typeIcon: string) => {
  return (
    <>
      {typeIcon === 'notification' ? (
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/notification-18px.svg`}
          alt="icon"
        />
      ) : (
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/mail.svg`}
          alt="icon"
        />
      )}
    </>
  );
};

export const handleIconOpenNotification = (typeIcon: string) => {
  return (
    <>
      {typeIcon === 'notification' ? (
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/notification-18px.svg`}
          alt="icon"
        />
      ) : (
        <img src={`./assets/img/svg/message_open_icon.svg`} alt="icon" />
      )}
    </>
  );
};

import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStore, defaultStore } from './Store';
import { IStore } from './Store.type';

export const StoreContext = createContext<RootStore>(defaultStore);

export const StoreProvider = (props: IStore) => {
  const newRootStore = useMemo(() => new RootStore(), []);
  return (
    <StoreContext.Provider value={newRootStore}>
      {props.children}
    </StoreContext.Provider>
  );
};

const StoreSubriberComponentFunc: React.FC<IStore> = ({ children }) => {
  const store = useContext(StoreContext);
  const childrenWithProps = React.Children.map<ReactNode, ReactNode>(
    children,
    (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(
          child,
          { ...children.props, store: store },
          null
        );
      }
      return child;
    }
  );
  return <>{childrenWithProps}</>;
};

export const StoreSubsriber = observer(StoreSubriberComponentFunc);

export const withStore = <P extends object>(
  Component: React.JSXElementConstructor<P>
) =>
  observer((props: P) => {
    const store = useContext(StoreContext);
    return <Component {...props} store={store} />;
  });

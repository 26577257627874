export type TStatusUserfeedback = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  message: string;
};

export type TFormUserfeedback = {
  platform: string;
  topic: string;
  message: string;
  captchaToken?: any;
};

export const DefaultStatusUserfeedback = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  message: ''
};

export const DefaultFormUserfeedback = {
  platform: '',
  topic: '',
  message: '',
  captchaToken: null
};

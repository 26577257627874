import React from 'react';
import styles from './ProfileProgressbar.module.scss';

type IProfileProgressBar = {
  currentLevelPoint?: number;
  maxLevelPoint?: number;
};

const ProfileProgressBar: React.FC<IProfileProgressBar> = ({
  currentLevelPoint = 0,
  maxLevelPoint = 0
}) => {
  return (
    <div className={styles['container']}>
      <div
        className={styles['blue-bar']}
        style={{ width: `${(currentLevelPoint / maxLevelPoint) * 100}%` }}
      />
      <p className={styles['text']}>{currentLevelPoint} Clearance Points</p>
    </div>
  );
};

export default ProfileProgressBar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oikRL8PUdG61DV2AKywy{display:flex;justify-content:center;align-items:center}.oikRL8PUdG61DV2AKywy .fLWOjsK6axyVkJiTSZSw{width:32px;height:12px;background:linear-gradient(90deg, rgba(196, 150, 77, 0) 0%, rgba(196, 150, 77, 0.45) 19.27%, #c4964d 51.04%, rgba(196, 150, 77, 0.45) 82.29%, rgba(196, 150, 77, 0) 100%);font-family:"Barlow",sans-serif;font-style:normal;font-weight:400;font-size:10px;line-height:11px;color:#f2f2f2;display:flex;align-items:center;justify-content:center}.F7odNJuhwhbAhFEExT3g{opacity:.5}.KZOwFAB26_1rNSqQhbR_ img{width:40px;height:auto}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Lytograms/LytogramsIcon.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAEA,yKAAA,CAQA,+BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAIJ,sBACE,UAAA,CAIA,0BACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-lyto-counter": `oikRL8PUdG61DV2AKywy`,
	"lyto-counter": `fLWOjsK6axyVkJiTSZSw`,
	"half-opacity": `F7odNJuhwhbAhFEExT3g`,
	"lyto-image": `KZOwFAB26_1rNSqQhbR_`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';

interface IUseHomeScroll {
  tileRef: any;
  homepageRef: any;
  profileRef: any;
}
export const useHomeScroll = ({
  tileRef,
  homepageRef,
  profileRef
}: IUseHomeScroll) => {
  const [isTileContentAtBot, setIsTileContentAtBot] = useState<boolean>(false);
  const [isTileContentAtTop, setIsTileContentAtTop] = useState<boolean>(false);
  const [isAtBottomProfileTiles, setIsAtBottomProfileTiles] =
    useState<boolean>(false);

  const scrollToHomepageTop = () => {
    const homepageElement: any = homepageRef.current;

    if (homepageElement) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleScrollGradientTiles = () => {
    if (!tileRef.current.classList.contains('on-scrollbar')) {
      tileRef.current.classList.add('on-scrollbar');
    }

    if (tileRef.current) {
      const isAtBot =
        tileRef.current.scrollHeight - tileRef.current.scrollTop ===
        tileRef.current.clientHeight;
      setIsTileContentAtBot(isAtBot);

      const isAtTop = tileRef.current.scrollTop !== 0;
      setIsTileContentAtTop(isAtTop);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollGradientTiles, true);
    return () => {
      window.removeEventListener('scroll', handleScrollGradientTiles, true);
    };
  }, []);

  const handleScrollProfileTilesRef = () => {
    if (profileRef.current) {
      const { bottom } = profileRef.current.getBoundingClientRect();
      setIsAtBottomProfileTiles(bottom <= window.innerHeight - 200);
    }
  };

  // Detect Scroll when ProfileTileRef visible
  useEffect(() => {
    window.addEventListener('scroll', handleScrollProfileTilesRef);
    return () => {
      window.removeEventListener('scroll', handleScrollProfileTilesRef);
    };
  }, []);

  // Handle Scroll Snap to Globe Section in Tablet and Mobile Device
  useEffect(() => {
    if (isAtBottomProfileTiles) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50);
    }
  }, [isAtBottomProfileTiles]);

  return {
    scrollToHomepageTop,
    isTileContentAtBot,
    isTileContentAtTop,
    isAtBottomProfileTiles
  };
};

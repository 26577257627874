import { AccordionItem } from '@jupiter/react-common-component';
import { formatNumber } from '@src/Common/Utils/helper';
import { contentHasScrollbar } from '@src/Pages/Profile/Profile.utils';
import numeral from 'numeral';
import React from 'react';
import { riftStormElement } from './RiftstormStatistics';
import styles from './RiftstormStatistics.module.scss';

export const RiftstormLocked = (width: number) => {
  return (
    <div
      className={
        styles['riftstorm-content__container'] +
        ' ' +
        (contentHasScrollbar(riftStormElement!)
          ? styles['customScrollbar']
          : '')
      }
    >
      <div className={styles['riftstorm-locked_content']}>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Total Lythograms'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_lytograms.svg`}
            color="light"
          />
        </div>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Total Kills'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_kills.svg`}
            color={width > 1279 ? 'light' : 'dark'}
          />
        </div>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Total Playtimes'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_playtimes.svg`}
            color="light"
          />
        </div>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Completion Rate'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_completion.svg`}
            color={width > 1279 ? 'light' : 'dark'}
          />
        </div>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Highest Damage'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_highest_damage.svg`}
            color="light"
          />
        </div>
        <div className={styles['riftstorm-content__section']}>
          <AccordionItem
            item={'Class Usage Rate'}
            itemCount={''}
            itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_class_usage.svg`}
            color={width > 1279 ? 'light' : 'dark'}
          />
        </div>
      </div>
      {/* <img
        className={styles['banner-steam']}
        onClick={() => (window.location.href = 'steam://store/2282790')}
        style={{ marginTop: 24, cursor: 'pointer' }}
        src="./assets/img/png/steam_button_mp.png"
      /> */}
    </div>
  );
};

export const handleColorDesktopDevice = (width: number) =>
  width > 1279 ? 'light' : 'dark';

export const handleTotalLytogramStats = (totalLytogramStats: any) =>
  totalLytogramStats
    ? `${totalLytogramStats.value}/${totalLytogramStats.data.length}`
    : '-';

export const handleTotalKillStats = (totalKillStats: any) =>
  totalKillStats ? formatNumber(totalKillStats.value) : '-';

export const handleTotalPlaytimeStats = (totalPlaytimeStats: any) =>
  totalPlaytimeStats
    ? new Date(totalPlaytimeStats.value * 1000).toISOString().substring(11, 19)
    : '-';

export const handleCompletionRateStats = (completionRateStats: any) =>
  completionRateStats ? `${completionRateStats.value.toFixed(1)}%` : '-';

export const handleHighestDamageStats = (highestDamageStats: any) =>
  highestDamageStats
    ? `${numeral(highestDamageStats.value).format('0,0').replace(/,/g, '.')}`
    : '-';

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xto2yJDAvdg6868rsW9O{width:100%;box-sizing:border-box;display:flex;justify-content:space-between;align-items:center}.xto2yJDAvdg6868rsW9O .JSXvw51MkhzAY8Rzde2z{color:#e4e4e4;font-family:"Barlow",sans-serif;font-size:12px;font-style:normal;font-weight:700;line-height:110%;display:flex;justify-content:center;align-items:center;column-gap:2px;position:relative}.xto2yJDAvdg6868rsW9O .JSXvw51MkhzAY8Rzde2z .D2coJNClUsQiPETObic6{display:flex;justify-content:center;align-items:center;position:absolute;top:50%;right:-13px;transform:translateY(-50%);cursor:pointer}.xto2yJDAvdg6868rsW9O .IeoGnNW_YPuUcLt6umlm{color:#fff;text-align:right;font-family:"Barlow",sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:110%}.xto2yJDAvdg6868rsW9O .IeoGnNW_YPuUcLt6umlm span{color:#50eaea;font-weight:700}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/SectionLevelClr/SectionLevelClr.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,qBAAA,CAEA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,4CACE,aAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,iBAAA,CAEA,kEACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,iBAAA,CACA,OAAA,CACA,WAAA,CACA,0BAAA,CAEA,cAAA,CAIJ,4CACE,UAAA,CACA,gBAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,iDACE,aAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xto2yJDAvdg6868rsW9O`,
	"left-content": `JSXvw51MkhzAY8Rzde2z`,
	"icon": `D2coJNClUsQiPETObic6`,
	"right-content": `IeoGnNW_YPuUcLt6umlm`
};
export default ___CSS_LOADER_EXPORT___;

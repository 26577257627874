import AvatarCard from '@src/Components/Atoms/AvatarItem/AvatarCard/AvatarCard';
import { ActionProfileDataType } from '@src/Domain/Profile/Profile.type';
import React from 'react';
import ListMissionArea from '../../ProfileStatistic/ListMissionArea/ListMissionArea';
import styles from './ProfileResponsive.module.scss';

interface IProfileResponsive {
  actionProfileData: ActionProfileDataType[];
  imgProfile: string;
  isLocked?: boolean;
  playerData: any;
}

const ProfileResponsive: React.FC<IProfileResponsive> = ({
  actionProfileData,
  imgProfile,
  isLocked = true,
  playerData
}) => {
  return (
    <>
      <div
        className={styles['content-profile-card']}
        data-testid="profile-responsive"
      >
        <AvatarCard
          username={playerData.username || '-'}
          name={playerData.name || '-'}
          status={'online'}
          hideStatusText
          imgProfile={imgProfile}
        />
      </div>
      <ListMissionArea
        actionProfileData={actionProfileData}
        isLocked={isLocked}
      />
    </>
  );
};

export default ProfileResponsive;

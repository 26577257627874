// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MsQ2znWskvf9aEuvAstz{width:100%;box-sizing:border-box;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;padding-top:8px}.MsQ2znWskvf9aEuvAstz .L7xSqpOCSxCZOn1o6A8Q{color:var(--Primary-Cream-100, var(--Primary-Cream-100, #fefaee));text-align:center;text-shadow:0px 1px 4px rgba(0,0,0,.25);font-family:"Barlow",sans-serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;margin:0;padding:0}.MsQ2znWskvf9aEuvAstz .H8o7OJVvMaSMbckmQN36{color:var(--Accent-Gold-300, #c4964d);text-align:center;font-family:"Barlow",sans-serif;font-size:14px;font-style:normal;font-weight:600;line-height:11px;cursor:pointer;margin:0;padding:0;margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/FooterDesktop/FooterDesktop.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,kBAAA,CACA,eAAA,CAEA,4CACE,iEAAA,CACA,iBAAA,CACA,uCAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,QAAA,CACA,SAAA,CAGF,4CACE,qCAAA,CACA,iBAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,cAAA,CAEA,QAAA,CACA,SAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MsQ2znWskvf9aEuvAstz`,
	"first-text": `L7xSqpOCSxCZOn1o6A8Q`,
	"second-text": `H8o7OJVvMaSMbckmQN36`
};
export default ___CSS_LOADER_EXPORT___;

import { Accordion, AccordionItem } from '@jupiter/react-common-component';
import useLazyFetch from '@src/Common/Hooks/useLazyFetch';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { formatNumber } from '@src/Common/Utils/helper';
import Select from '@src/Components/Atoms/Select/Select';
import ClassUsage from '@src/Components/Molecules/ClassUsage/ClassUsage';
import CompletionRate from '@src/Components/Molecules/CompletionRate/CompletionRate';
import Lytograms from '@src/Components/Molecules/Lytograms/Lytograms';
import { contentHasScrollbar } from '@src/Pages/Profile/Profile.utils';
import { StoreContext } from '@src/Store/Store.context';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  RiftstormLocked,
  handleColorDesktopDevice,
  handleCompletionRateStats,
  handleHighestDamageStats,
  handleTotalKillStats,
  handleTotalLytogramStats,
  handleTotalPlaytimeStats
} from './RiftstormStatistics.constant';
import styles from './RiftstormStatistics.module.scss';
import { IGetStatistics, StatisticsData } from './RiftstormStatistics.type';

const options = [
  {
    text: 'Personal',
    value: 'Personal'
  }
];

export const riftStormElement = document.getElementById(
  'riftstorm-content__container'
);

const RiftstormStatistics: React.FC<{ isLocked: boolean }> = ({ isLocked }) => {
  const [statisticType, setStatisticType] = useState('Personal');
  const [statisticsData, setStatisticsData] = useState<any>();
  const { width } = useWindowDimensions();

  const store = useContext(StoreContext);

  const [getStatistics, { data: statistic }] = useLazyFetch<IGetStatistics>({
    url: `${process.env.API_PLAYER_STATISTICS ?? ''}/`,
    idempotencyId: ''
  });

  useEffect(() => {
    getStatistics({
      params: {
        AccountId: store.UserStore.user?.profile.sub,
        PresetName:
          'lythogram_stats,total_kill_stats,total_play_time_stats,class_usage,highest_damage,completion_rate'
      }
    });
  }, []);

  useEffect(() => {
    if (statistic) {
      setStatisticsData(statistic.data);
    }
  }, [statistic]);

  const findStatistics = (param: string) => {
    return statisticsData
      ? statisticsData.find((statisticItem: StatisticsData) =>
          statisticItem.name.includes(param)
        )
      : undefined;
  };

  const totalLytogramStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('lythogram_stats')
  );

  const totalPlaytimeStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('total_play_time_stats')
  );

  const totalKillStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('total_kill_stats')
  );

  const classUsageStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('class_usage')
  );

  const highestDamageStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('highest_damage')
  );

  const completionRateStats = statisticsData?.find((data: StatisticsData) =>
    data.name.includes('completion_rate')
  );

  const checkContentHasScrollbar = contentHasScrollbar(riftStormElement!)
    ? styles['customScrollbar']
    : '';

  const getAccordionItemComponents = () => {
    let content;
    if (totalKillStats?.data?.length > 0) {
      content = (
        <div className={styles['container-total-kills']}>
          {totalKillStats?.data?.map((stats: any) => (
            <Fragment key={stats?.name}>
              <AccordionItem
                color="light"
                item={stats.name}
                colorCount="#C4964D"
                itemCount={`${formatNumber(stats.value)}`}
                itemIcon={`${
                  process.env.REACT_APP_GCM_BUCKET_URL
                }/mythic-portal-v2/img/svg/${stats.name.toLowerCase()}.svg`}
              />
            </Fragment>
          ))}
        </div>
      );
    } else {
      content = (
        <div className={styles['container-empty-data']}>
          <p>No Data</p>
        </div>
      );
    }

    return content;
  };

  return (
    <div data-testid="riftstorm-statistic-container">
      <div className={styles['riftstorm-header__container']}>
        <div className={styles['riftstorm-header__title']}>
          Riftstorm Statistics
        </div>
        <div className={styles['riftstorm-header__selector']}>
          <Select
            name="StatisticType"
            label="Filter"
            variant="gray-border"
            options={options}
            value={statisticType}
            onChange={(e) => setStatisticType(e.currentTarget.value)}
            disabled={isLocked}
          />
        </div>
      </div>
      <div className={styles['wrapper-riftstorm']}>
        {!isLocked ? (
          <div
            id="riftstorm-content__container"
            className={
              styles['riftstorm-content__container'] +
              ' ' +
              checkContentHasScrollbar
            }
          >
            <div className={styles['riftstorm-content__section']}>
              <Accordion
                color={handleColorDesktopDevice(width)}
                title="Total Lytograms"
                countColor="#C4964D"
                count={handleTotalLytogramStats(totalLytogramStats)}
                icon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_lytograms.svg`}
              >
                <Lytograms data={findStatistics('lythogram_stats')} />
              </Accordion>
            </div>
            <div className={styles['riftstorm-content__section']}>
              <Accordion
                color="light"
                title="Total Kills"
                count={handleTotalKillStats(totalKillStats)}
                icon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_kills.svg`}
                countColor="#C4964D"
              >
                {getAccordionItemComponents()}
              </Accordion>
            </div>
            <div className={styles['riftstorm-content__section']}>
              <AccordionItem
                color={handleColorDesktopDevice(width)}
                item="Total Playtime"
                colorCount="#C4964D"
                itemCount={handleTotalPlaytimeStats(totalPlaytimeStats)}
                itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_playtimes.svg`}
              />
            </div>
            <div className={styles['riftstorm-content__section']}>
              <Accordion
                color="light"
                title="Completion Rate"
                icon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_completion.svg`}
                count={handleCompletionRateStats(completionRateStats)}
                countColor="#C4964D"
              >
                <CompletionRate data={completionRateStats?.data ?? []} />
              </Accordion>
            </div>
            <div className={styles['riftstorm-content__section']}>
              <AccordionItem
                color={handleColorDesktopDevice(width)}
                item="Highest Damage"
                colorCount="#C4964D"
                itemCount={handleHighestDamageStats(highestDamageStats)}
                itemIcon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_highest_damage.svg`}
              />
            </div>
            <div className={styles['riftstorm-content__section']}>
              <Accordion
                color="light"
                countColor="#C4964D"
                title="Class Usage Rate"
                count=""
                icon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/total_class_usage.svg`}
              >
                <ClassUsage data={classUsageStats} />
              </Accordion>
            </div>
          </div>
        ) : (
          <>{RiftstormLocked(width)}</>
        )}
      </div>
    </div>
  );
};

export default RiftstormStatistics;

import React from 'react';
import styles from './StepItem.module.scss';

type IStepItem = {
  isActive?: boolean;
  isPassed?: boolean;
};

const StepItem: React.FC<IStepItem> = ({
  isActive = false,
  isPassed = false
}) => {
  return (
    <div
      className={`${styles['container']} ${
        (isActive || isPassed) && styles['blue']
      }`}
    >
      {isActive ? (
        <img src={`assets/img/svg/step-icon/step-icon-active.svg`} alt="icon" />
      ) : isPassed ? (
        <img src={`assets/img/svg/step-icon/step-icon-passed.svg`} alt="icon" />
      ) : (
        ''
      )}
    </div>
  );
};

export default StepItem;

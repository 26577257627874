// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QNsdMGjGRrWHUjlAD12f{width:100%;height:24px;box-sizing:border-box;padding:2px;border-radius:3px;border:.5px solid #0c2342;background:rgba(12,35,66,.9);display:flex;justify-content:flex-start;align-items:center;position:relative}.QNsdMGjGRrWHUjlAD12f .v3h2gI2nROViui2VHsTn{height:100%;box-sizing:border-box;border-radius:1px;background:#18b5bd}.QNsdMGjGRrWHUjlAD12f .xO2SG5bCRYNxMjVAPe4N{color:#e4e4e4;text-shadow:0px 4px 4px rgba(0,0,0,.25);font-family:"Barlow",sans-serif;font-size:10px;font-style:normal;font-weight:400;line-height:110%;margin:0;padding:0;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:100%;box-sizing:border-box;display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/ProfileProgressBar/ProfileProgressbar.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CACA,yBAAA,CACA,4BAAA,CAEA,YAAA,CACA,0BAAA,CACA,kBAAA,CAEA,iBAAA,CAEA,4CACE,WAAA,CACA,qBAAA,CACA,iBAAA,CAEA,kBAAA,CAGF,4CACE,aAAA,CACA,uCAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,QAAA,CACA,SAAA,CAEA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAEA,UAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QNsdMGjGRrWHUjlAD12f`,
	"blue-bar": `v3h2gI2nROViui2VHsTn`,
	"text": `xO2SG5bCRYNxMjVAPe4N`
};
export default ___CSS_LOADER_EXPORT___;

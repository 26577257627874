import React from 'react';
import styles from './DataDeletetionInstruction.module.scss';

const DataDeletetionInstruction = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['title-wrapper']}>
        <p className={styles['title']}>Data Deletion Instruction</p>
      </div>
      <div className={styles['paragraph-wrapper']}>
        <p className={styles['paragraph']}>
          According to the Facebook Platform rules, we have to provide User Data
          Deletion Callback URL or Data Deletion Instructions URL. If you want
          to delete your activities for Confiction Dev, follow these
          instructions:
        </p>
        <ol>
          <li>
            Go to Your Facebook Account’s Setting & Privacy. Click “Setting”.
          </li>
          <li>
            Then, go to “Apps and Websites” and you will see all of your Apps
            activities.
          </li>
          <li>Select the option box for Confiction Dev</li>
          <li>Click “Remove” button.</li>
        </ol>
        <p className={styles['paragraph']}>
          If you wish to delete your user account data, you can do that
          throughout the application. Simply navigate to the “Profile” and then
          press the “More” button. You’ll find the option to “Delete account”
          there. Once you confirm this action, your personal data will be
          permanently deleted, and no information will be retained. If you are
          unable to login, you can send us a request to{' '}
          <span>support@confiction.com</span>. In the email body, provide the
          email address used for registration and we will take care of the rest.
        </p>
      </div>
    </div>
  );
};

export default DataDeletetionInstruction;

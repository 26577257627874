import React from 'react';

const DoggyComicArrowLeft = () => {
  return (
    <svg
      width="60"
      height="58"
      viewBox="0 0 60 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Square">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.808702 29.0104L24.4004 6.17752L29.2293 10.8511L10.4664 29.0105L29.2292 47.1698L24.4004 51.8433L0.808702 29.0104Z"
          fill="#C4964D"
        />
        <rect
          id="Rectangle 8076"
          width="13.44"
          height="13.44"
          transform="matrix(-0.718568 0.695456 0.718568 0.695456 29.7817 19.6636)"
          fill="#C4964D"
        />
      </g>
    </svg>
  );
};

export default DoggyComicArrowLeft;

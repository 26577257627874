// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xMLLMFdSl6BhMxlqhAiq{display:flex;align-items:center;margin:5px 0px 22px 11px}.xMLLMFdSl6BhMxlqhAiq .XHJjB31QRzJA5eyFwpBK{display:flex;flex-direction:column;row-gap:18px;justify-content:center;align-items:center;width:100%}@media screen and (max-width: 1279px){.xMLLMFdSl6BhMxlqhAiq .XHJjB31QRzJA5eyFwpBK{padding:0px 41px}}.xMLLMFdSl6BhMxlqhAiq ._YU4zzVrM_FU4ZLroX63{display:flex;align-items:center;justify-content:center;column-gap:12px}@media screen and (max-width: 1279px){.xMLLMFdSl6BhMxlqhAiq ._YU4zzVrM_FU4ZLroX63{width:100%}}@media screen and (max-width: 1279px){.xMLLMFdSl6BhMxlqhAiq ._YU4zzVrM_FU4ZLroX63 .oJfdOE6nCIxDyCZMgk_J{width:50px}}.xMLLMFdSl6BhMxlqhAiq ._YU4zzVrM_FU4ZLroX63 .XD_567aCjk4PPiIHRS4M{color:var(--accent-gold-300)}.V7h55E8UlXOjN3yoKdn9{display:flex;flex-direction:column;row-gap:18px;justify-content:center;align-items:center;width:100%;margin:30px 0px 30px 0px}.IQnzOs5b1uCNAYvPJkwr{position:relative;overflow:hidden;width:201px;height:8px;background:var(--primary-navy-500)}@media screen and (max-width: 1279px){.IQnzOs5b1uCNAYvPJkwr{width:100%}}.IQnzOs5b1uCNAYvPJkwr .c4kNKHQ9xa5D6Ikl3Plz{position:absolute;height:8px;left:-3px;background:var(--accent-gold-300);transform:skewX(35deg)}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/CompletionRate/CompletionRate.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CAEA,4CACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CAEA,sCARF,4CASI,gBAAA,CAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAEA,sCANF,4CAOI,UAAA,CAAA,CAIA,sCADF,kEAEI,UAAA,CAAA,CAIJ,kEACE,4BAAA,CAKN,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA,CAGF,sBACE,iBAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CACA,kCAAA,CAEA,sCAPF,sBAQI,UAAA,CAAA,CAGF,4CACE,iBAAA,CACA,UAAA,CACA,SAAA,CACA,iCAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `xMLLMFdSl6BhMxlqhAiq`,
	"completion-container": `XHJjB31QRzJA5eyFwpBK`,
	"completion-point": `_YU4zzVrM_FU4ZLroX63`,
	"completion-title": `oJfdOE6nCIxDyCZMgk_J`,
	"completion-counter": `XD_567aCjk4PPiIHRS4M`,
	"completion-empty-container": `V7h55E8UlXOjN3yoKdn9`,
	"progress-bar-exp": `IQnzOs5b1uCNAYvPJkwr`,
	"progress-active": `c4kNKHQ9xa5D6Ikl3Plz`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaProps {
  title?: string;
  urlSite?: string;
  desc?: string;
  ogType?: string;
  ogTitle?: string;
  ogDesc?: string;
  ogImg?: string;
  ogUrl?: string;
  ogSiteName?: string;
  keywords?: string;
}

const MetaHelmet = ({
  title = 'Fict One | Portal',
  urlSite = window.location.href,
  desc = 'The central hub for FICT ONE ecosystem products. Coordinate, strategize, and stay informed within the Occultical universe.',
  ogType = 'website',
  ogTitle = 'Portal - Central Hub for FICT ONE Ecosystem',
  ogDesc = 'Access all FICT ONE ecosystem products through the Portal. Strategize, coordinate, and stay updated with the latest in the Occultical universe.',
  ogImg = 'https://staticassets.mythicprotocol.com/web/mythic-assets-fe/assets/meta-tag/portal/portal-og.png',
  ogUrl = 'https://one.confiction.com/portal',
  ogSiteName = 'Portal',
  keywords = 'Occultical Portal, FICT ONE ecosystem, strategic planning, resource management, Portal, coordination, strategic planner, lore keeper, Operative'
}: MetaProps) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={urlSite} />
      <meta name="description" content={desc} />
      <meta name="title" content={title} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDesc} />
      <meta property="og:image" content={ogImg} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:site_name" content={ogSiteName} />
    </Helmet>
  );
};

export default MetaHelmet;

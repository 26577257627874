import ProfileProgress from '@src/Components/Molecules/ProfileProgress/ProfileProgress';
import RadarChart from '@src/Components/Molecules/RadarChart/RadarChart';
import {
  ActionProfileDataType,
  LevelProfileDataType
} from '@src/Domain/Profile/Profile.type';
import { contentHasScrollbar } from '@src/Pages/Profile/Profile.utils';
import React from 'react';
import ListMissionArea from './ListMissionArea/ListMissionArea';
import styles from './ProfileStatistic.module.scss';

interface IProfileStatistic {
  levelProfileData: LevelProfileDataType;
  actionProfileData: ActionProfileDataType[];
  name: string;
  radarChartData: {
    fieldOperations: number;
    collector: number;
    archivist: number;
    merchantry: number;
    seer: number;
  };
  imgProfile: string;
  isLocked: boolean;
  username: string;
}

const ProfileStatistic: React.FC<IProfileStatistic> = ({
  levelProfileData,
  radarChartData,
  actionProfileData,
  name,
  imgProfile,
  isLocked,
  username
}) => {
  const profileStatisticElement = document.getElementById(
    'content-profile-statistic'
  );

  return (
    <div className={styles['container-profile-statistic']}>
      <div
        id="content-profile-statistic"
        className={
          styles['content-profile-statistic'] +
          ' ' +
          (contentHasScrollbar(profileStatisticElement!)
            ? styles['customScrollbar']
            : '')
        }
      >
        <ProfileProgress
          username={username}
          name={name}
          levelProfileData={levelProfileData}
          imgProfile={imgProfile}
          isLocked={isLocked}
        />
        <div className={styles['container-radar-chart']}>
          <RadarChart
            isLocked={isLocked}
            radarChartData={radarChartData}
            height={300}
            width={300}
          />
        </div>
        <ListMissionArea
          actionProfileData={actionProfileData}
          isLocked={isLocked}
        />
      </div>
    </div>
  );
};

export default ProfileStatistic;

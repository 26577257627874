import React from 'react';
import styles from './DoggyStarling.module.scss';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

const DoggyStarling = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      className={styles['Starling']}
      style={{
        background: `url(${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/Starling-image.png)`
      }}
    >
      <div className={styles['StarlingShadow']} />
      <img
        className={styles['StarlingMascot']}
        src={
          process.env.REACT_APP_GCM_BUCKET_URL +
          '/mythic-portal-v2/img/png/doggy/Starling.png'
        }
        alt="starling"
      />
      <div className={styles['StarlingContainer']}>
        <div className={styles['TextContainer']}>
          <div className={styles['Consequences']}>consequences</div>
          <div className={styles['Bottom']}>
            {/* read */}
            <div className={styles['ReadContainer']}>
              <div className={styles['Content']}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 77"
                  fill="none"
                >
                  <path
                    d="M10.3125 77C7.5 77 5.07812 76.0769 3.04688 74.2305C1.01562 72.3842 0 70.0991 0 67.375V16.7075C0 14.4072 0.734374 12.3491 2.20312 10.533C3.67187 8.71698 5.59375 7.56682 7.96875 7.08255L45 0V58.1132L9.46875 65.0141C8.90625 65.1352 8.4375 65.4228 8.0625 65.8768C7.6875 66.3308 7.5 66.8302 7.5 67.375C7.5 68.0409 7.78125 68.6008 8.34375 69.0548C8.90625 69.5089 9.5625 69.7359 10.3125 69.7359H52.5V11.6226H60V77H10.3125ZM11.25 57.296L18.75 55.8432V12.4399L11.25 13.8927V57.296Z"
                    fill="#C4964D"
                  />
                </svg>
                <div className={styles['Line']}></div>
              </div>
              <div className={styles['ContentReadText']}>
                <p>
                  You can read Casefile: <br /> C-1787 and explore our growing
                  universe at the Repository.
                </p>

                <ButtonDefault
                  text="Read The Entry"
                  onClick={() => {
                    window.open(process.env.URL_MYTHIC_REPOSITORY + '/C-1787');
                  }}
                  icon={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/open_in_new.png`}
                />
              </div>
            </div>

            {/* cleanup */}
            <div className={styles['Cleanup']}>
              <span className={styles['casefile-code']}>C-1787</span>
              <h1 className={styles['Title']}>
                OPERATION <br /> DOGGY
                {(width >= 1280 || width < 768) && <br />} DAYCARE
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoggyStarling;

import React from 'react';
import styles from './LytogramsIcon.module.scss';

type ILytogramsIcon = {
  src: string;
  counter: string | number | null;
};

const LytogramsIcon: React.FC<ILytogramsIcon> = ({ src, counter = '-' }) => {
  return (
    <div data-testid="lytograms-icon">
      <div
        className={`${styles['lyto-image']} ${
          !counter && styles['half-opacity']
        }`}
      >
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/lytograph/${src}.png`}
          alt="lyto-icon"
        />
      </div>

      <div className={styles['container-lyto-counter']}>
        <div
          className={`${styles['lyto-counter']} ${
            !counter && styles['half-opacity']
          }`}
        >
          {counter ?? '-'}
        </div>
      </div>
    </div>
  );
};

export default LytogramsIcon;

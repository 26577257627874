import React from 'react';
import styles from './CompletionRate.module.scss';

export const maxExp = 5000; //tentative
export const dummyExp = 3000;

type ICompletionRate = {
  data: {
    name: string;
    value: number;
  }[];
};
const CompletionRate: React.FC<ICompletionRate> = ({ data }) => {
  return data && data?.length > 0 ? (
    <div className={styles['wrapper']} data-testid="completion-rate">
      <div className={styles['completion-container']}>
        {data.map((completion: any, i: number) => (
          <div className={styles['completion-point']} key={Number(i)}>
            <div className={styles['completion-title']}>{completion.name}</div>
            <div className={styles['progress-bar-exp']}>
              <span
                style={{
                  width: `${(completion.value / 100) * 100 + 3}%`
                }}
                className={styles['progress-active']}
              ></span>
            </div>
            <div className={styles['completion-counter']}>
              {completion.value.toFixed(1)}%
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div
      data-testid="completion-rate"
      className={styles['completion-empty-container']}
    >
      <p>No Data</p>
    </div>
  );
};

export default CompletionRate;

import jwtDecode from 'jwt-decode';

export function setVersionTag(version: string | number) {
  const checkVersionTag = document.getElementsByName('meta-version');
  if (checkVersionTag.length == 0) {
    const versiontag = document.createElement('meta');
    versiontag.setAttribute('name', 'meta-version');
    versiontag.setAttribute('content', `${version}`);
    document.getElementsByTagName('head')[0].prepend(versiontag);
  }
}

export function formatNumber(value: any) {
  // Ensure that value is a number
  const numberValue = parseFloat(value);

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
    return '';
  }

  // Format the number with thousands separators and remove trailing zeros
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
}

export const isTokenExpired = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const isPortalLocked = {
  isLocked: true
};

import React, { useEffect, useState } from 'react';
import styles from './CarouselEventList.module.scss';
import CardEventList from '../CardEventList/CardEventList';
import { EventDumType } from '@src/Components/Atoms/Card/CardEventList/CardEventList.type';
import moment from 'moment';

type ICarouselEventList = {
  eventDatas: EventDumType[];
  CountryName: string;
  onClose: () => void;
};

const CarouselEventList: React.FC<ICarouselEventList> = ({
  eventDatas,
  CountryName,
  onClose
}) => {
  const [dataEvents, setDataEvents] = useState<EventDumType[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentIndex(0);
    if (eventDatas) {
      setDataEvents(eventDatas);
    }
  }, [eventDatas]);

  const handleNextEvent = () => {
    if (currentIndex < dataEvents.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    } else if (currentIndex === dataEvents.length - 1) {
      setCurrentIndex(0);
    }
  };

  const handlePrevEvent = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    } else if (currentIndex === 0) {
      setCurrentIndex(dataEvents.length - 1);
    }
  };

  const handleSumEvent = () => {
    if (dataEvents.length > 1) return `(${dataEvents.length} EVENTS DETECTED.)`;
    return `(${dataEvents.length} EVENT DETECTED.)`;
  };

  return (
    <div className={styles['container']} data-testid="carousel-event-list">
      <div className={styles['country-name-wrapper']}>
        {CountryName} <span onClick={onClose}>&#x2715;</span>
      </div>
      <div className={styles['content-navigation-wrapper']}>
        <div className={styles['total-events']}>{handleSumEvent()}</div>
        <div className={styles['navigation-arrow-wrapper']}>
          <div className={styles['btn-arrow-left']} onClick={handlePrevEvent}>
            <img src="assets/icons/arrow-left.png" alt="button" />
          </div>
          <div className={styles['date']}>
            {moment(dataEvents[currentIndex]?.dum_date).format('DD-MM-YYYY')}
            <span>
              {' '}
              ({currentIndex + 1}/{dataEvents.length})
            </span>
          </div>
          <div className={styles['btn-arrow-right']} onClick={handleNextEvent}>
            <img src="assets/icons/arrow-right.png" alt="button" />
          </div>
        </div>
      </div>
      <div className={styles['carousel-wrapper']}>
        {dataEvents && <CardEventList data={dataEvents[currentIndex]} />}
      </div>
    </div>
  );
};

export default CarouselEventList;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate('/', { replace: true });
  };

  return (
    <section
      className={styles['notfound__container']}
      data-testid="not-found-page"
    >
      <h3>404</h3>
      <p>Page Not Found</p>
      <div className={styles['desc-not-found']}>
        Perhaps this page is currently vanishing. We&apos;ll guide you back to
        safety.
      </div>
      <div className={styles['button-wrapper']}>
        <button className={styles['button-back']} onClick={handleBackButton}>
          <p className={styles['button-text']}>Back to Portal</p>
        </button>
      </div>
    </section>
  );
};

export default NotFound;

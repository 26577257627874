import React from 'react';
import { Tooltip, PlacesType } from 'react-tooltip';

interface ITooltipCustome {
  id: string;
  place?: PlacesType;
  children: React.ReactNode;
}

const TooltipCustome: React.FC<ITooltipCustome> = ({
  id,
  place = 'bottom',
  children
}) => {
  return (
    <Tooltip
      id={id}
      place={place}
      role="tooltip"
      style={{
        color: '#E4E4E4',
        background: '#0C0F12',
        fontFamily: `var(--fonts-normal)`,
        fontSize: '14px',
        fontWeight: '400'
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipCustome;

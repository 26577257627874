import { makeAutoObservable } from 'mobx';

export interface IMessageStore {
  isUpdatedMessage: boolean;
  messageMethod: string;
  isLoading: boolean;
}

export class MessageStore implements IMessageStore {
  isUpdatedMessage = false;
  messageMethod = '';
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }
}

export const MessageStoreDefault: IMessageStore = {
  isUpdatedMessage: false,
  messageMethod: '',
  isLoading: true
};

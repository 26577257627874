import { oidcConfig } from '@src/Common/Constants/Auth.OidcConfig';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { User, UserManager } from 'oidc-client-ts';
import { UserInfoType } from './User.type';
export interface IUserStore {
  user: User | null;
  token?: string;
  loadUserManager: () => void;
  adminAccountData: UserInfoType;
  errorInfo: string;
  getAdminAccountData: (_token: string) => void;
}

export class UserStore implements IUserStore {
  _user: User | null = null;
  _token?: string = undefined;
  _role = '';
  _userManager = new UserManager(oidcConfig);
  adminAccountData: UserInfoType = {} as UserInfoType;
  errorInfo = '';

  constructor() {
    makeAutoObservable(this);
    this.loadUserManager();
  }

  get user() {
    return this._user;
  }

  get token() {
    return this._token;
  }

  async loadUserManager() {
    try {
      const user = await this._userManager.getUser();
      this._user = user;
      this._token = user?.access_token;
    } catch (err) {
      this._user = null;
      this._token = undefined;
    }
  }

  async getAdminAccountData(_token: string) {
    await axios
      .get(`${process.env.SSO_DOMAIN}/Connect/UserInfo`, {
        headers: {
          Authorization: `Bearer ${_token}`
        }
      })
      .then((result) => {
        this.adminAccountData = result.data;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }
}

export const UserStoreDefault: IUserStore = {
  user: null,
  token: undefined,
  loadUserManager: () => null,
  errorInfo: '',
  getAdminAccountData: function (_token: string): void {
    throw new Error('Function not implemented.');
  },
  adminAccountData: {} as UserInfoType
};

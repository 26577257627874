import { injectStores } from '@mobx-devtools/tools';
import {
  IdempotencyKeyStore,
  IdempotencyKeyStoreDefault
} from '@src/Common/Services/Idempotency/IdempotencyKey.store';
import { GlobeStore, GlobeStoreDefault } from '@src/Domain/Globe/Globe.store';
import {
  HelperStore,
  HelperStoreDefault,
  IHelperStore
} from '@src/Domain/Helper/Helper.store';
import {
  MessageStore,
  MessageStoreDefault
} from '@src/Domain/Message/Message.store';
import {
  ProfileStore,
  ProfileStoreDefault
} from '@src/Domain/Profile/Profile.store';
import {
  IUserStore,
  UserStore,
  UserStoreDefault
} from '@src/Domain/User/User.store';
import {
  IThreatIndexStore,
  ThreatIndexStore,
  ThreatIndexStoreDefault
} from '@src/Domain/ThreatIndex/ThreatIndex.store';
import { configure } from 'mobx';
import {
  ILevelStore,
  LevelStore,
  LevelStoreDefault
} from '@src/Domain/Level/Level.store';
configure({ useProxies: 'never', enforceActions: 'never' });

export class RootStore {
  UserStore: IUserStore;
  IdempotencyKeyStore: IdempotencyKeyStore;
  GlobeStore: GlobeStore;
  ProfileStore: ProfileStore;
  MessageStore: MessageStore;
  ThreatIndexStore: IThreatIndexStore;
  LevelStore: ILevelStore;
  HelperStore: IHelperStore;

  constructor() {
    this.HelperStore = new HelperStore();
    this.UserStore = new UserStore();
    this.IdempotencyKeyStore = new IdempotencyKeyStore();
    this.GlobeStore = new GlobeStore();
    this.ProfileStore = new ProfileStore();
    this.MessageStore = new MessageStore();
    this.ThreatIndexStore = new ThreatIndexStore();
    this.LevelStore = new LevelStore();
  }
}

export const defaultStore: RootStore = {
  HelperStore: HelperStoreDefault,
  UserStore: UserStoreDefault,
  IdempotencyKeyStore: IdempotencyKeyStoreDefault,
  GlobeStore: GlobeStoreDefault,
  ProfileStore: ProfileStoreDefault,
  MessageStore: MessageStoreDefault,
  ThreatIndexStore: ThreatIndexStoreDefault,
  LevelStore: LevelStoreDefault
};

injectStores({ rootStore: new RootStore() });

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QWVdCovm2fLlve3f5vev{width:100%;height:100%;display:flex;justify-content:center;align-items:center;z-index:1;position:absolute;left:0}@media screen and (max-width: 1279px){.QWVdCovm2fLlve3f5vev{height:80%;display:flex;justify-content:center;align-items:flex-end}}.ckuqPLcGEhUrn3zIOOxr{position:absolute;left:calc(50% + 500px);top:50%;transform:translate(-50%, -50%);z-index:9999}@media screen and (max-width: 1659px){.ckuqPLcGEhUrn3zIOOxr{left:calc(50% + 500px)}}@media screen and (max-width: 1358px){.ckuqPLcGEhUrn3zIOOxr{left:calc(50% + 300px)}}@media screen and (max-width: 1279px){.ckuqPLcGEhUrn3zIOOxr{position:absolute;top:50%;z-index:300;left:calc(50% + 200px)}}@media screen and (max-width: 767px){.ckuqPLcGEhUrn3zIOOxr{top:15%;left:50%;z-index:9999}}`, "",{"version":3,"sources":["webpack://./src/Pages/FullScreenGlobe/Globe/Globe.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,iBAAA,CACA,MAAA,CAEA,sCAVF,sBAWI,UAAA,CACA,YAAA,CACA,sBAAA,CACA,oBAAA,CAAA,CAIJ,sBACE,iBAAA,CAEA,sBAAA,CACA,OAAA,CACA,+BAAA,CACA,YAAA,CAEA,sCARF,sBASI,sBAAA,CAAA,CAEF,sCAXF,sBAYI,sBAAA,CAAA,CAIJ,sCACE,sBACE,iBAAA,CACA,OAAA,CACA,WAAA,CACA,sBAAA,CAAA,CAIJ,qCACE,sBACE,OAAA,CACA,QAAA,CACA,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-loading-globe": `QWVdCovm2fLlve3f5vev`,
	"floating-marker-detail-wrapper": `ckuqPLcGEhUrn3zIOOxr`
};
export default ___CSS_LOADER_EXPORT___;

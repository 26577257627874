import React from 'react';

const ChevronIcon: React.FC<{ color?: string }> = ({ color = '#F2F2F2' }) => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26665 8L0.333313 7.06667L3.39998 4L0.333313 0.933333L1.26665 0L5.26665 4L1.26665 8Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronIcon;

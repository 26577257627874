import React from 'react';
import {
  getSystemBackground,
  getSystemBackgroundSize
} from './SystemReq.constant';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import styles from './SystemReq.module.scss';

const SystemRequirement = () => {
  const { width } = useWindowDimensions();

  return (
    <div className={styles['system-box']} data-testid="system-requirement">
      <div
        className={styles['system-container']}
        style={{
          background: `url(${getSystemBackground(width)})`,
          backgroundSize: getSystemBackgroundSize(width),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
      >
        <div>
          <p className={styles['system-container__title']}>
            System Requirements
          </p>
          <div className={styles['system-spec__container']}>
            <div className={styles['system-spec__detail-container']}>
              <p className={styles['system-spec__title']}>Minimum:</p>
              <div className={styles['system-spec__content']}>
                <p>
                  <span>Requires a 64-bit processor and operating system</span>
                </p>
                <p>
                  OS: <span>64-bit Windows 7 and up</span>{' '}
                </p>
                <p>
                  Processor: <span>Intel Core i3 Gen 6</span>
                </p>
                <p>
                  Memory: <span>4 GB RAM</span>
                </p>
                <p>
                  Graphics: <span>Intel UHD 630 (Lower graphic settings)</span>
                </p>
                <p>
                  Resolution: <span>1280x720(720p)</span>
                </p>
                <p>
                  DirectX: <span>11</span>
                </p>
                <p>
                  Storage: <span>5 GB</span>
                </p>
              </div>
            </div>
            <div className={styles['system-spec__detail-container']}>
              <p className={styles['system-spec__title']}>Recommended:</p>
              <div className={styles['system-spec__content']}>
                <p>
                  <span>Requires a 64-bit processor and operating system</span>
                </p>
                <p>
                  OS: <span>64-bit Windows 7 and up</span>
                </p>
                <p>
                  Processor: <span>Intel Core i5 Gen 7 (or 4 Core CPU’s)</span>
                </p>
                <p>
                  Memory: <span>8 GB RAM</span>
                </p>
                <p>
                  Graphics:{' '}
                  <span>Nvidia GTX 1050 2GB (Intended graphic settings)</span>
                </p>
                <p>
                  Resolution: <span>1920x1080(1080p)</span>
                </p>
                <p>
                  DirectX: <span>11</span>
                </p>
                <p>
                  Storage: <span>5 GB</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemRequirement;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gjfLVBOE5o1BPpKplIRc{min-height:750px;display:flex;flex-direction:column;align-items:center;justify-content:center;margin:0 15px}@media screen and (max-width: 425px){.gjfLVBOE5o1BPpKplIRc{margin-bottom:60px}}.wkA88gR6MFKan5esDdpr{text-align:center;font-family:"Barlow";font-style:normal;font-weight:700;font-size:24px;letter-spacing:.03em;text-transform:uppercase;padding-bottom:8px;color:var(--primary-white-500)}@media screen and (max-width: 425px){.wkA88gR6MFKan5esDdpr{margin-top:60px}}.YIfCSusTYsKMNmJdLmZi{text-align:center;font-family:"Barlow";font-style:normal;font-weight:700;font-size:18px;letter-spacing:.03em;color:var(--primary-white-500);margin:15px auto;padding-bottom:8px}.p7xyW8H8q6xejTB_fJsq{display:flex;align-items:center;justify-content:flex-start;column-gap:16px}@media screen and (max-width: 320px){.p7xyW8H8q6xejTB_fJsq{flex-direction:column;row-gap:15px}}`, "",{"version":3,"sources":["webpack://./src/Pages/Retainer/contents/FAQ/Faq.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CACA,qCAPF,sBAQI,kBAAA,CAAA,CAOJ,sBACE,iBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,oBAAA,CACA,wBAAA,CACA,kBAAA,CAEA,8BAAA,CAEA,qCAZF,sBAaI,eAAA,CAAA,CAIJ,sBACE,iBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,oBAAA,CAEA,8BAAA,CACA,gBAAA,CAEA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,eAAA,CAEA,qCANF,sBAOI,qBAAA,CACA,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq-box": `gjfLVBOE5o1BPpKplIRc`,
	"faq-title": `wkA88gR6MFKan5esDdpr`,
	"faq-separator": `YIfCSusTYsKMNmJdLmZi`,
	"social-container": `p7xyW8H8q6xejTB_fJsq`
};
export default ___CSS_LOADER_EXPORT___;

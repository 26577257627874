export const keystoneData = [
  {
    state: 'netral',
    name: 'Keystone Decision',
    image: 'URL_ADDRESS',
    condition: [
      {
        most_user_condition: 'Kill Grey 6',
        title: 'The End of Tolerance',
        description: 'Players are choosing not to save him. Will you?'
      },
      {
        most_user_condition: 'Save Grey 6',
        title: 'The Path of Mercy',
        description: 'Players are deciding to save him. Will you?'
      }
    ]
  },
  {
    state: 'kill-quard',
    name: 'Keystone Decision',
    image: 'URL_ADDRESS',
    condition: [
      {
        most_user_condition: 'Kill Grey 6',
        title: 'The End of Tolerance',
        description:
          'You have chosen to save Grey 6, but others have not. You can still make a difference.'
      },
      {
        most_user_condition: 'Save Grey 6',
        title: 'The Path of Mercy',
        description:
          'You and others have chosen to save Grey 6. You can still change your mind.',
        image: './assets/img/png/keystone.png'
      }
    ]
  },
  {
    state: 'not-kill-quard',
    name: 'Keystone Decision',
    image: 'URL_ADDRESS',
    condition: [
      {
        most_user_condition: 'Kill Grey 6',
        title: 'The End of Tolerance',
        description:
          'You and others have chosen not to save Grey 6. You can still change your mind.'
      },
      {
        most_user_condition: 'Save Grey 6',
        title: 'The Path of Mercy',
        description:
          'You have chosen not to save Grey 6, but others have. You can still make a difference.'
      }
    ]
  }
];

export const keystoneDataAfterPlaytes = {
  name: 'Keystone Outcome',
  title: 'The Path of Mercy',
  description:
    '<p>The players have decided. Their actions have spared Grey 6 from an untimely end. <br/> Consequences will follow. </p>'
};

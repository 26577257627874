import React from 'react';

export const OpenInNewIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 12.5C0.966667 12.5 0.652778 12.3694 0.391667 12.1083C0.130556 11.8472 0 11.5333 0 11.1667V1.83333C0 1.46667 0.130556 1.15278 0.391667 0.891667C0.652778 0.630556 0.966667 0.5 1.33333 0.5H6V1.83333H1.33333V11.1667H10.6667V6.5H12V11.1667C12 11.5333 11.8694 11.8472 11.6083 12.1083C11.3472 12.3694 11.0333 12.5 10.6667 12.5H1.33333ZM4.46667 8.96667L3.53333 8.03333L9.73333 1.83333H7.33333V0.5H12V5.16667H10.6667V2.76667L4.46667 8.96667Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

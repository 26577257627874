import TrashIcon from '@src/Components/Atoms/Shape/TrashIcon';
import { RawHTMLToString } from '@src/Components/Molecules/Notification/Notification.utils';
import React from 'react';
import styles from './SidebarItemNotif.module.scss';
import { ArrowIcon } from './SidebarItemNotifIcon';

type ISidebarItemNotif = {
  text?: string;
  alert?: boolean;
  type?: 'item' | 'subsidebar';
  typeIcon?: 'notification' | 'mail' | 'mail-opened';
  numberOfItem?: number | string;
  onClick?: () => void;
  isActive?: boolean;
  style?: React.CSSProperties;
  title?: string;
  description?: string;
  withBtnDelete?: boolean;
  onDelete?: () => void;
};

const SidebarItemNotif: React.FC<ISidebarItemNotif> = ({
  text = 'Welcome to Mythic Protocol',
  alert = false,
  type = 'item',
  typeIcon = 'notification',
  numberOfItem = 3,
  onClick,
  isActive,
  style,
  title,
  description,
  withBtnDelete,
  onDelete
}) => {
  const isItemAlert = alert && type === 'item';
  const alertElement = isItemAlert ? (
    <div className={styles['alert']} data-testid="alert" />
  ) : null;

  const handleIcon = (type: string) => {
    if (type === 'item')
      return (
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/notification-18px.svg`}
          alt="icon"
        />
      );

    if (typeIcon === 'mail')
      return (
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/mail.svg`}
          alt="icon"
        />
      );

    return (
      <img
        src={`./assets/img/svg/message_open_icon.svg`}
        alt="icon-open-message"
      />
    );
  };

  return (
    <div className={styles['big-wrapper']}>
      <div
        className={`${styles['wrapper']} ${
          type === 'subsidebar' && styles['subsidebar']
        } ${isActive && styles['active']}`}
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        onKeyDown={() => {
          return;
        }}
        style={style}
      >
        <div className={styles['icon-wrapper']}>
          {handleIcon(type)}
          {alertElement}
        </div>
        <div
          className={`${
            styles[
              typeIcon === 'notification'
                ? 'container-notification'
                : 'container-mail'
            ]
          } ${type === 'subsidebar' && styles['subsidebar']}`}
        >
          {typeIcon === 'mail' || typeIcon === 'mail-opened' ? (
            <>
              {type === 'subsidebar' && (
                <>
                  {text} {type === 'subsidebar' && `(${numberOfItem})`}
                </>
              )}
              <div className={styles['title-bar']}>
                <span className={styles['title-message']}>{title}</span>
                <span className={styles['description-message']}>
                  {RawHTMLToString(description ?? '')}
                </span>
              </div>
            </>
          ) : (
            <>
              {typeIcon === 'notification' && (
                <div className={styles['title-bar']}>
                  {text} {type === 'subsidebar' && `(${numberOfItem})`}
                </div>
              )}
            </>
          )}
        </div>
        {type === 'item' && (
          <div
            className={styles['arrow-wrapper']}
            style={{
              top: typeIcon === 'notification' ? 24 : 20,
              transform:
                typeIcon === 'mail' || typeIcon === 'mail-opened'
                  ? ''
                  : 'translate(0px, -50%)'
            }}
          >
            <ArrowIcon />
          </div>
        )}
      </div>
      {withBtnDelete && (
        <div
          className={styles['trash-icon-wrapper']}
          onClick={onDelete}
          onKeyDown={() => {
            return;
          }}
        >
          <TrashIcon />
        </div>
      )}
    </div>
  );
};

export default SidebarItemNotif;

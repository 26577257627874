import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { StoreContext } from '@src/Store/Store.context';
import { careerNameType } from '@src/Domain/Profile/Profile.type';
import { handleCheckCondition } from '@src/Common/Constants/checkCondition';

const defaultCart = {
  fieldOperations: 0,
  collector: 0,
  archivist: 0,
  merchantry: 0,
  seer: 0
};

export const useProfile = () => {
  const { width } = useWindowDimensions();
  const { UserStore, ProfileStore } = useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get('accountId');

  const [isProfileFetched, setIsProfileFetched] = useState<boolean>(false);
  const [radarChartData, setRadarChartData] = useState<any>(defaultCart);

  useEffect(() => {
    setRadarChartData(defaultCart);

    if (!accountId) {
      if (!UserStore.token) {
        navigate('/', { replace: true });
        return;
      }
      const { user } = UserStore;
      const userSub = user?.profile?.sub ?? '';

      ProfileStore.sendUserData(UserStore.token || '');
      ProfileStore.getAdminAccountData(userSub, UserStore.token || '');
      ProfileStore.getPortalLockedStatus();
    } else {
      ProfileStore.getProfileDataPlayer(accountId);
    }
  }, []);

  useEffect(() => {
    if (ProfileStore?.errorInfo?.code === 'ERR_BAD_REQUEST') {
      navigate('/error');
      ProfileStore.errorInfo = {} as any;
    }
  }, [ProfileStore?.errorInfo?.code]);

  useEffect(() => {
    if (ProfileStore.userData) {
      setIsProfileFetched(true);
    }
  }, [ProfileStore.userData]);

  // LEVEL SYSTEM
  useEffect(() => {
    if (isProfileFetched && UserStore.token) {
      ProfileStore.getLevelCareer(careerNameType.Archivist, UserStore.token);
      ProfileStore.getActionCareer(careerNameType.Archivist, UserStore.token);
    }
  }, [isProfileFetched]);

  // LEVEL SYSTEM
  // useEffect(() => {
  //   const levelProfileData = ProfileStore?.levelProfileData;
  //   if (levelProfileData?.level !== undefined) {
  //     setRadarChartData((prevData: any) => ({
  //       ...prevData,
  //       archivist: levelProfileData.level
  //     }));
  //   }
  // }, [ProfileStore?.levelProfileData]);

  useEffect(() => {
    const bgImage = document.getElementById('bg-dashboard');

    if (bgImage) {
      bgImage.style.opacity = '0.9';
    }

    return () => {
      if (bgImage) {
        bgImage.style.backgroundImage = ``;
        bgImage.style.opacity = '1';
      }
    };
  }, []);

  useEffect(() => {
    if (ProfileStore?.errorInfo?.code === 'ERR_BAD_REQUEST') {
      navigate('/error');
      ProfileStore.errorInfo = {} as any;
    }
    if (accountId) {
      ProfileStore.getProfileDataPlayer(accountId);
    }
  }, [accountId, ProfileStore?.errorInfo?.code]);

  const profileName = handleCheckCondition(
    ProfileStore?.profileDataPlayer.name,
    ProfileStore?.profileDataPlayer?.name,
    ProfileStore.adminAccountData.name
  );
  const profileUsername = handleCheckCondition(
    ProfileStore?.profileDataPlayer.username,
    ProfileStore?.profileDataPlayer?.username,
    ProfileStore.adminAccountData.username
  );
  const profileImg = handleCheckCondition(
    ProfileStore?.profileDataPlayer.name,
    ProfileStore?.profileDataPlayer?.profilePicture,
    ProfileStore?.adminAccountData?.picture
  );
  const profileIsLocked = process.env.PROFILE_STATISTIC_LOCKED === 'true';
  const profileData = handleCheckCondition(
    ProfileStore?.profileDataPlayer.name,
    ProfileStore?.profileDataPlayer,
    ProfileStore?.adminAccountData
  );

  return {
    width,
    accountId,
    UserStore,
    ProfileStore,
    isProfileFetched,
    setIsProfileFetched,
    radarChartData,
    setRadarChartData,
    profileName,
    profileUsername,
    profileImg,
    profileIsLocked,
    profileData
  };
};

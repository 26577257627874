import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import {
  AchievementDataType,
  ActionProfileDataType,
  LevelProfileDataType,
  ProfileDataPlayerType,
  UserDataType,
  UserInfoType,
  careerNameType
} from './Profile.type';

export interface IProfileStore {
  userData: UserDataType;
  errorInfo: any;
  sendUserData: (token: string) => Promise<void>;
  getLevelCareer: (careerName: careerNameType, token: string) => void;
  levelProfileData: LevelProfileDataType;
  getActionCareer: (careerName: careerNameType, token: string) => void;
  actionProfileData: ActionProfileDataType[];
  adminAccountData: UserInfoType;
  getAdminAccountData: (accountId: string, token: string) => void;
  achievementData: AchievementDataType[];
  getAchievementData: (accountId: string) => void;
  getPortalLockedStatus: () => void;
  isPortalLocked: boolean;
  totalAchievements: number;
  getProfileDataPlayer: (accountId: string) => Promise<void>;
  profileDataPlayer: ProfileDataPlayerType;
}

export class ProfileStore implements IProfileStore {
  userData: UserDataType = {} as UserDataType;
  errorInfo = {} as any;
  levelProfileData: LevelProfileDataType = {} as LevelProfileDataType;
  actionProfileData: ActionProfileDataType[] = [];
  adminAccountData: UserInfoType = {} as UserInfoType;
  achievementData: AchievementDataType[] = [];
  isPortalLocked = false;
  totalAchievements = 0;
  profileDataPlayer = {} as ProfileDataPlayerType;

  constructor() {
    makeAutoObservable(this);
  }

  async sendUserData(token: string) {
    await axios
      .post(
        `${process.env.PROFILE_LEVEL_UP_URL}/user`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((result) => {
        this.userData = result.data.data;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }

  getLevelCareer(careerName: careerNameType, token: string) {
    axios
      .get(`${process.env.PROFILE_LEVEL_UP_URL}/level/career/${careerName}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((result) => {
        this.levelProfileData = result.data.data;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }

  getActionCareer(careerName: careerNameType, token: string) {
    axios
      .get(`${process.env.PROFILE_LEVEL_UP_URL}/action/career/${careerName}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((result) => {
        this.actionProfileData = result.data.data;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }

  getAdminAccountData(accountId: string, token: string) {
    axios
      .get(`${process.env.USER_INFO_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((result) => {
        this.adminAccountData = result.data;
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }

  getAchievementData(accountId: string) {
    axios
      .get(`${process.env.API_ACHIEVEMENT ?? ''}/achievement/${accountId}`)
      .then((result) => {
        const data = result.data.data;
        this.achievementData = data;
        if (Array.isArray(data)) {
          this.totalAchievements = data.reduce((count, item) => {
            return item.isOwned ? count + 1 : count;
          }, 0);
        }
      })
      .catch((e) => {
        this.errorInfo = JSON.stringify(e);
      });
  }

  getPortalLockedStatus() {
    this.isPortalLocked = true;
  }

  async getProfileDataPlayer(accountId: string) {
    axios
      .get(
        `${process.env.API_ACCOUNT_URL}/account/profile?accountId=${accountId}`
      )
      .then((result) => {
        this.profileDataPlayer = result.data;
      })
      .catch((e) => {
        this.errorInfo = e;
      });
  }
}

export const ProfileStoreDefault: IProfileStore = {
  errorInfo: '',
  sendUserData: function (token: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  getLevelCareer: function (careerName: careerNameType, token: string): void {
    throw new Error('Function not implemented.');
  },

  getActionCareer: function (careerName: careerNameType, token: string): void {
    throw new Error('Function not implemented.');
  },
  userData: {} as UserDataType,
  levelProfileData: {} as LevelProfileDataType,
  actionProfileData: [],
  adminAccountData: {} as UserInfoType,
  getAdminAccountData: function (accountId: string, token: string): void {
    throw new Error('Function not implemented.');
  },
  achievementData: [],
  getAchievementData: function (accountId: string): void {
    throw new Error('Function not implemented.');
  },
  getPortalLockedStatus: function (): void {
    throw new Error('Function not implemented.');
  },
  isPortalLocked: false,
  totalAchievements: 0,
  getProfileDataPlayer: function (accountId: string): Promise<void> {
    throw new Error('Function not implemented.');
  },
  profileDataPlayer: {} as ProfileDataPlayerType
};

import { ListTitle } from '@jupiter/react-common-component';
import { ActionProfileDataType } from '@src/Domain/Profile/Profile.type';
import { changeBgColorOnMobile } from '@src/Pages/Profile/Profile.utils';
import React from 'react';
import styles from '../ProfileStatistic.module.scss';

interface IListMissionArea {
  actionProfileData: ActionProfileDataType[];
  isLocked: boolean;
}

const ListMissionArea: React.FC<IListMissionArea> = ({
  actionProfileData,
  isLocked = true
}) => {
  const styleLocked = isLocked ? '-locked' : '';

  return (
    <div
      className={styles[`container-mission-area${styleLocked}`]}
      data-testid="list-mission-area"
    >
      <div className={styles['header-mission-area']}>
        <div>How to increase level</div>
        <div>Max Freq per Day</div>
      </div>
      <div className={styles['list-mission-area']}>
        {actionProfileData?.map((item, index) => (
          <ListTitle
            data-testid="list-title"
            color={changeBgColorOnMobile(index)}
            key={item.actionName}
            title={item.actionName}
            desc={
              <>
                EXP <span style={{ color: '#18B5BD' }}>+{item.expGiven}</span>
              </>
            }
            suffix={
              item.limitationUnit !== 0 ? (
                <span
                  style={{
                    color:
                      item.actionCount === item.limitation
                        ? '#36C692'
                        : '#F2F2F2'
                  }}
                >
                  {item.actionCount}/{item.limitation}
                </span>
              ) : (
                <span style={{ color: '#F0A737', fontSize: 14 }}>&#8734;</span>
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

export default ListMissionArea;

import React, { Fragment } from 'react';

import { metadata } from './MedallionPromo.constant';
import MedallionPromoContent from './contents';
import MetaHelmet from '@src/Components/Atoms/Helmet/MetaHelmet';

const MedallionPromo = () => {
  return (
    <Fragment>
      <MetaHelmet
        title={metadata.title}
        urlSite={metadata.urlSite}
        desc={metadata.desc}
        ogType={metadata.ogType}
        ogDesc={metadata.ogDesc}
        ogImg={metadata.ogImg}
        ogSiteName={metadata.ogSiteName}
        ogTitle={metadata.ogTitle}
        ogUrl={metadata.ogUrl}
        keywords={metadata.keywords}
      />
      <MedallionPromoContent />
    </Fragment>
  );
};

MedallionPromo.template = 'dashboard';

export default MedallionPromo;

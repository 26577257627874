// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TV3UTjflaVPRLyI5eWrv{width:100%;height:100%;box-sizing:border-box;display:flex;justify-content:center;align-items:center;background:var(--primary-blue-400-v23);border-radius:4px;border:.5px solid var(--primary-blue-300-v23);cursor:pointer;transition:.2s all ease-in-out}.TV3UTjflaVPRLyI5eWrv .FXtFwXNJSbzARCkkB8ft{display:flex;width:100%;padding:0 12px;justify-content:flex-start;align-items:center;gap:10px}.TV3UTjflaVPRLyI5eWrv .FXtFwXNJSbzARCkkB8ft img{flex-shrink:0;width:18px;height:18px}.TV3UTjflaVPRLyI5eWrv .FXtFwXNJSbzARCkkB8ft p{color:var(--Neutral-White-100, #fff);font-family:"Barlow Condensed";font-size:20px;font-style:normal;font-weight:600;line-height:26px}.TV3UTjflaVPRLyI5eWrv.Hgm9yTVtByHwArXNC5uc:hover{background:var(--primary-blue-400-v23)}.TV3UTjflaVPRLyI5eWrv:hover{border:1px solid var(--secondary-magenta-300);background:var(--secondary-magenta-400)}.TV3UTjflaVPRLyI5eWrv img{width:18px;height:18px;box-sizing:border-box}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Button/ButtonNavigation/ButtonNavigation.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,sCAAA,CACA,iBAAA,CACA,6CAAA,CACA,cAAA,CACA,8BAAA,CAEA,4CACE,YAAA,CACA,UAAA,CACA,cAAA,CACA,0BAAA,CACA,kBAAA,CACA,QAAA,CACA,gDACE,aAAA,CACA,UAAA,CACA,WAAA,CAEF,8CACE,oCAAA,CACA,8BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAKF,iDACE,sCAAA,CAIJ,4BACE,6CAAA,CACA,uCAAA,CAGF,0BACE,UAAA,CACA,WAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TV3UTjflaVPRLyI5eWrv`,
	"btn-dekstop-text": `FXtFwXNJSbzARCkkB8ft`,
	"locked": `Hgm9yTVtByHwArXNC5uc`
};
export default ___CSS_LOADER_EXPORT___;

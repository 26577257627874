import { useState } from 'react';

const useMaintenance = () => {
  const [estimatedDateActive, setEstimatedDateActive] = useState<string>('');
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

  const getMaintainStatus = async () => {
    if (process.env.REACT_APP_ENV != 'release') return;

    const response = await fetch(`${process.env.MAINTENANCE_URL}`).then(
      (result) => result.json()
    );

    const { data } = response as HealthConfigType;
    if (!response || !data) return;

    // temporary
    const prodHostURL = process.env.PROD_HOST_URL;
    const isOnProd =
      prodHostURL != undefined &&
      window.location.origin.toLowerCase() === prodHostURL.toLowerCase();
    const isMT = data.health.isActive === false;

    if (isMT && isOnProd) {
      setIsMaintenance(true);
      if (data.health.estimatedDateActive) {
        setEstimatedDateActive(data.health.estimatedDateActive);
      }
    }
  };

  return {
    getMaintainStatus,
    estimatedDateActive,
    isMaintenance
  };
};

export default useMaintenance;

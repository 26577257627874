import React from 'react';
import styles from './ButtonDefault.module.scss';

interface ButtonDefaultProps {
  text: string;
  onClick: () => void;
  icon?: string;
  disabled?: boolean;
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  text,
  onClick,
  icon,
  disabled = false
}) => {
  return (
    <button
      onClick={() => !disabled && onClick()}
      type="button"
      className={styles['container__button']}
      style={{ padding: text ? '8px 24px' : '8px 10px' }}
      disabled={disabled}
    >
      {!!text && <span>{text}</span>}
      {!!icon && <img src={icon} alt="btn-icon" />}
    </button>
  );
};

export default ButtonDefault;

import { ProgressBar } from '@jupiter/react-common-component';
import LevelBadge from '@src/Components/Atoms/LevelBadge/LevelBadge';
import RadarChart from '@src/Components/Molecules/RadarChart/RadarChart';
import { LevelProfileDataType } from '@src/Domain/Profile/Profile.type';
import numeral from 'numeral';
import React from 'react';
import styles from './LevelResponsive.module.scss';

interface ILevelResponsive {
  levelProfileData: LevelProfileDataType;
  radarChartData: {
    fieldOperations: number;
    collector: number;
    archivist: number;
    merchantry: number;
    seer: number;
  };
  isLocked?: boolean;
}

const LevelResponsive: React.FC<ILevelResponsive> = ({
  levelProfileData,
  radarChartData,
  isLocked
}) => {
  return (
    <div
      className={styles['container-level-number-responsive']}
      data-testid="level-responsive"
    >
      <div className={styles['level-number-responsive']}>
        <div
          className={styles['level-number-badge-responsive']}
          style={{ marginLeft: !isLocked ? 0 : -22 }}
        >
          <LevelBadge level={levelProfileData?.level || 21} isLock={isLocked} />
        </div>
        {!isLocked && (
          <div className={styles['container-progress-bar-exp-responsive']}>
            <div style={{ color: '#c4964d' }}>
              XP {numeral(levelProfileData?.totalExp).format('0,0')}
            </div>
            <ProgressBar
              colorBar="#C4964D"
              progress={
                (levelProfileData?.totalExp /
                  (levelProfileData?.totalExp +
                    levelProfileData?.rangeToNextLevel)) *
                100
              }
            />
            <div>
              {numeral(levelProfileData?.rangeToNextLevel).format('0,0')} XP to
              reach level {levelProfileData?.level + 1}
            </div>
          </div>
        )}
      </div>
      <div className={styles['container-radar-chart-responsive']}>
        <RadarChart
          isLocked={isLocked}
          radarChartData={radarChartData}
          height={300}
          width={300}
        />
      </div>
    </div>
  );
};

export default LevelResponsive;

import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import Button from '@src/Components/Atoms/Buttons/buttons';
import LauncherContainer from '@src/Components/Atoms/LauncherContainer/launcherContainer';

import { logEvent } from '@src/Domain/analytic';
import axios from 'axios';

import {
  ArrowDown,
  FolderIcon,
  FormSeparator,
  SmallLineSeparator
} from '@src/Components/Atoms/SVGComponents';
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from 'react-parallax-mouse';
import { useSearchParams } from 'react-router-dom';

import { imageAsset } from './Enroll.constant';
import styles from './Enroll.module.scss';

const showImg = (data: any) => {
  if (data && data.length > 1) {
    return (
      <MouseParallaxContainer className={styles['mythic-truth__content-bg']}>
        {data.map((asset: any) => (
          <MouseParallaxChild
            key={asset.alt}
            className={styles[`${asset.class}`]}
            factorX={asset.x}
            factorY={asset.y}
          >
            <img
              alt={asset.alt}
              className={styles['mythic-truth__img']}
              src={asset.src}
            />
          </MouseParallaxChild>
        ))}
      </MouseParallaxContainer>
    );
  } else {
    return (
      <div className={styles['mythic-truth__monsters-image']}>
        <img alt={data.assets[0].alt} src={data.assets[0].src} />
      </div>
    );
  }
};

const isAuthenticatedAndHaveEntitlement = (
  isAuthenticated: boolean,
  isHaveEntitlement: boolean
) => {
  return isAuthenticated && isHaveEntitlement;
};

type IMainBanner = {
  scrollTo: () => void;
};

const Enroll: React.FC<IMainBanner> = () => {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const { isAuthenticated, isLoading, user } = auth;
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const [userToken, setUserToken] = useState<string>('');

  const [isHaveEntitlement, setIsHaveEntitlement] = useState<boolean>(false);

  const getDownloadLauncherUrl = async () => {
    const response = await axios.get(`${process.env.DOWNLOAD_URL}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const { latest } = response.data;

    setDownloadUrl(latest);
  };

  const onHandleDowload = () => {
    logEvent('user', 'CTA-Download Launcher');
  };

  const handleSignIn = () => {
    void auth.signinRedirect({
      extraQueryParams: {
        back_url: `https://${window.location.host}${process.env.HOST_URL}`
      }
    });
    logEvent('user', 'CTA-Sign In');
    localStorage.setItem('sign', window.location.pathname);
    if (searchParams.get('code')) {
      localStorage.setItem('code', searchParams.get('code') ?? '');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const getLocalData = localStorage.getItem(
        `oidc.user:${process.env.SSO_DOMAIN}:${process.env.SSO_CLIENT_ID}`
      );
      if (getLocalData) {
        const accessToken = JSON.parse(getLocalData).access_token;

        setUserToken(accessToken);
      }
    }
  }, [isAuthenticated]);

  const checkUserEntitlement = async () => {
    const entitlement = user?.profile.entitlement as string;
    if (
      entitlement &&
      (entitlement.includes('constituent') ||
        entitlement.includes('early_access') ||
        entitlement.includes('ra_referral'))
    ) {
      setIsHaveEntitlement(true);
    } else {
      try {
        const response = await axios.get(
          `${process.env.MYTHIC_REFERRAL_URL}/admin-account/user-profile?AccountIds=${user?.profile.sub}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }
        );

        if (response.data.data) {
          setIsHaveEntitlement(true);
        }
      } catch (error) {
        setIsHaveEntitlement(false);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated && userToken) {
      checkUserEntitlement();
    }
  }, [isAuthenticated, user, userToken]);

  useEffect(() => {
    if (isAuthenticatedAndHaveEntitlement(isAuthenticated, true)) {
      getDownloadLauncherUrl();
    }
  }, [isAuthenticated, isHaveEntitlement, user]);

  return (
    <div className={styles['enroll-box']} data-testid="enroll-section">
      <div className={styles['enroll-container__bgtop']}></div>
      <div
        className={styles['enroll-container']}
        style={{
          background: 'url(./assets/img/png/Retainer/bgcontent.png) #03182C',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={styles['enroll-content__container']}>
          <div className={styles['enroll-content__slider-box']}>
            <div className={styles['image-container']}>
              <div className={styles['mythic-truth__monsters-container']}>
                {showImg(imageAsset)}
              </div>
            </div>
            <div className={styles['enroll-content__caption']}>
              <p className={styles['enroll-content__caption-title']}>
                {!isAuthenticated && (
                  <Fragment>
                    It would help to verify yourself by registering an account
                    or signing in.
                  </Fragment>
                )}
                {isAuthenticated && (
                  <Fragment>
                    You have been allowed to download the Retainer Academy
                    launcher.
                  </Fragment>
                )}
              </p>
            </div>
          </div>
          <div className={styles['enroll-form__container']}>
            <p className={styles['enroll-form__title']}>Ready to Play?</p>
            <p className={styles['enroll-form__subtitle']}>
              Enroll Academy Now
            </p>
            <FormSeparator />
            <div className={styles['enroll-form__box']}>
              <LauncherContainer
                status={isAuthenticated ? 'completed' : 'active'}
              >
                <Fragment>
                  <div className={styles['launcher-title__container']}>
                    <p className={styles['launcher-title']}>Sign In</p>
                  </div>
                  {!isAuthenticated && (
                    <div
                      className={styles['launcher-button__general-container']}
                    >
                      <Button onClick={() => handleSignIn()}>
                        <span
                          className={styles['launcher-button__general-text']}
                        >
                          {isLoading ? 'Signing in...' : 'Sign In'}
                        </span>
                      </Button>
                    </div>
                  )}
                </Fragment>
              </LauncherContainer>
              <ArrowDown opacity={isAuthenticated ? 1 : 0.7} />
              <LauncherContainer
                status={
                  isAuthenticatedAndHaveEntitlement(isAuthenticated, true)
                    ? 'active'
                    : 'locked'
                }
              >
                <div className={styles['launcher-download__box']}>
                  <div className={styles['launcher-download__container']}>
                    <p className={styles['launcher-download__title']}>
                      Download Launcher
                    </p>
                    {isAuthenticatedAndHaveEntitlement(
                      isAuthenticated,
                      true
                    ) && (
                      <div>
                        <div
                          className={
                            styles['launcher-download__folder-container']
                          }
                        >
                          <FolderIcon />
                        </div>
                        <div
                          className={
                            styles['launcher-download__button-container']
                          }
                        >
                          <a
                            href={downloadUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button onClick={onHandleDowload}>
                              <span
                                className={
                                  styles['launcher-download__button-text']
                                }
                              >
                                Download
                              </span>
                            </Button>
                          </a>
                        </div>
                        {/* <p className={styles["button-container__caption"]}>
                          available on 23 august 2022
                        </p> */}
                      </div>
                    )}
                  </div>
                </div>
              </LauncherContainer>
            </div>
            <div className={styles['launcher-caption']}>
              <div className={`${styles['launcher-caption__container']}`}>
                <div>
                  <SmallLineSeparator />
                </div>
                <div>
                  <span>Stages to enroll retainer academy</span>
                </div>
                <div>
                  <SmallLineSeparator />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['enroll-container__bgbottom']}></div>
    </div>
  );
};

export default Enroll;

const getSystemBackground = (width: number) => {
  if (width <= 1024 && width > 768) {
    return './assets/img/png/Retainer/background/systembgtab.png';
  } else if (width <= 768 && width > 601) {
    return './assets/img/png/Retainer/background/systembgsmalltab.png';
  } else if (width <= 601) {
    return './assets/img/png/Retainer/background/systembgsmalldevice.png';
  } else {
    return './assets/img/png/Retainer/background/systembg.png';
  }
};

const getSystemBackgroundSize = (width: number) => {
  if (width > 1024) {
    return 'cover';
  } else if (width <= 425 && width > 375) {
    return `${width}px 760px`;
  } else if (width <= 375 && width > 290) {
    return `${width}px 840px`;
  } else {
    return `${width}px 855px`;
  }
};

export { getSystemBackground, getSystemBackgroundSize };

import { setVersionTag } from '@src/Common/Utils/helper';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './routes';
interface RouteInterface {
  name: string;
  index?: boolean;
  path: string;
  component: React.FC;
  key?: string;
}

export interface RouteRootInterface extends RouteInterface {
  routes?: RouteRootInterface[];
}

const renderRoute = (route: RouteRootInterface) => {
  const renderRoutes = (routes: RouteRootInterface[]) => {
    if (routes) {
      return routes.map((routeChild: RouteRootInterface) =>
        renderRoute(routeChild)
      );
    }
    return null;
  };
  const Component = route.component as unknown as () => JSX.Element;
  return (
    <Route path={route.path} key={route.name} element={<Component />}>
      {route.routes && renderRoutes(route.routes)}
    </Route>
  );
};

const Pages: FC = () => {
  const version = process.env.REACT_APP_VERSION || '';

  React.useEffect(() => {
    setVersionTag(version);
  }, [version]);

  return <Routes>{routes.map(renderRoute)}</Routes>;
};

export default Pages;

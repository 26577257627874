import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig: UserManagerSettings = {
  authority: process.env.SSO_DOMAIN || '',
  client_id: process.env.SSO_CLIENT_ID || '',
  redirect_uri: `https://${window.location.host}${process.env.HOST_URL}/callback`,
  scope: process.env.SSO_SCOPE,
  automaticSilentRenew: true,
  post_logout_redirect_uri:
    `https://${window.location.host}${process.env.HOST_URL}` || '',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: false
};

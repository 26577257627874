import DoggyComicPagination from '@src/Components/Molecules/Doggy/DoggyComicPagination/DoggyComicPagination';
import React, { useState } from 'react';
import styles from './ComicReader.module.scss';
import DoggyComicCarousel from '@src/Components/Molecules/Doggy/DoggyComicCarousel/DoggyComicCarousel';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

interface IComicReader {
  images: string[];
  placeholder?: string;
}

const ComicReader: React.FC<IComicReader> = ({
  images,
  placeholder = 'url(assets/img/png/reveal-placeholder.png)'
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { width } = useWindowDimensions();

  return (
    <div
      id="comic"
      style={{
        scrollMarginTop: `${width >= 768 ? '90px' : '60px'}`
      }}
      className={styles['Container']}
    >
      {images?.length > 0 ? (
        <>
          <DoggyComicPagination
            totalPage={images.length}
            activeIndex={currentIndex}
            onChangePage={(page: number) => {
              //
              setCurrentIndex(page);
            }}
          />
          <DoggyComicCarousel images={images} current={currentIndex} />
          <DoggyComicPagination
            isBottom
            totalPage={images.length}
            activeIndex={currentIndex}
            onChangePage={(page: number) => {
              //
              setCurrentIndex(page);
            }}
          />
        </>
      ) : (
        <div
          style={{
            backgroundImage: placeholder ?? ''
          }}
          className={styles['placeholder-container']}
        >
          <img
            src={`assets/img/png/coming-soon.png`}
            alt="diagonal-button-blue"
          />
        </div>
      )}
    </div>
  );
};

export default ComicReader;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styles from './CommunityAgreement.module.scss';
import {
  CommunityAgreementType,
  SecondDatum,
  SecondDesc
} from './CommunityAgreement.type';

const CommunityAgreement = () => {
  const [communityAgreementData, setCommunityAgreementData] =
    useState<CommunityAgreementType>();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/json/communityAgreementText.json`
      )
      .then((res) => setCommunityAgreementData(res.data));
  }, []);

  return (
    <div
      className={styles['container-community-agreement']}
      data-testid="community-agreement"
    >
      <div className={styles['box-community-agreement']}>
        <div className={styles['header-community-agreement']}>
          <div />
          <div className={styles['title-community-agreement']}>
            Community Agreement
          </div>
          <div />
        </div>
        <div
          id="content-community-agreement"
          className={styles['content-community-agreement']}
        >
          {communityAgreementData?.data?.map((item: any) => (
            <section key={item.intro}>
              <h4>{item.heading}</h4>
              <div dangerouslySetInnerHTML={{ __html: item.intro }}></div>
              <ol type="a">
                {item.data.map((list: any) => (
                  <li key={list}>{list}</li>
                ))}
              </ol>
            </section>
          ))}
          <div className={styles['agreement-list']}>
            <ol type="1">
              {communityAgreementData?.secondData
                .slice(0, communityAgreementData.secondData?.length - 1)
                .map((item: SecondDatum) => (
                  <li key={item.header}>
                    <h4>{item.header}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.desc.title ?? ''
                      }}
                    ></div>
                    <div className={styles['agreement-list-item']}>
                      <ol className={styles['first-desc-header']}>
                        {item?.desc?.data?.map((list: any) => (
                          <li key={list}>{list}</li>
                        ))}
                      </ol>
                      <ol className={styles['second-desc-header']}>
                        {item?.secondDesc.map((list: SecondDesc) => (
                          <li key={list.headerSecondDesc}>
                            {list.headerSecondDesc}

                            <ol className={styles['second-desc-item']}>
                              {list.data.map((content: string) => (
                                <li key={content}>{content}</li>
                              ))}
                            </ol>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </li>
                ))}
            </ol>
            <div>
              <h4>
                {' '}
                {
                  communityAgreementData?.secondData[
                    communityAgreementData?.secondData?.length - 1
                  ]?.header
                }
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    communityAgreementData?.secondData[
                      communityAgreementData?.secondData.length - 1
                    ]?.desc?.title ?? ''
                }}
              ></div>
              <div className={styles['agreement-list-item']}>
                <ol type="a">
                  {communityAgreementData?.secondData[
                    communityAgreementData?.secondData.length - 1
                  ]?.desc?.data?.map((list: any) => (
                    <li key={list}>{list}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityAgreement;
